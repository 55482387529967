<button class="px-5 py-2 rounded w-0 md:w-auto invisible md:visible"
        [ngClass]="{ 'bg-green-200 text-green-800':setupProgressStatus>=4, 'bg-yellow-200 text-yellow-800':setupProgressStatus>1 && setupProgressStatus<4,'bg-orange-200 text-orange-800':setupProgressStatus==1, 'bg-red-200 text-red-800':setupProgressStatus==0}"
        (click)="openPanel()" mat-button *transloco="let t" #shortcutsOrigin>
    <div class="flex items-center hover:cursor-pointer">
        <div class="font-bold">{{t('Setup Progress')}} ({{ setupProgressStatus }}/3)</div>
        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:chevron-down'"></mat-icon>
    </div>
</button>

<!-- Shortcuts panel -->
<ng-template #shortcutsPanel *transloco="let t">
    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="flex items-center text-lg font-medium leading-10">
                {{t('Setup Progress')}}
            </div>
        </div>
        <div class="relative flex flex-col flex-auto sm:max-h-120 -mb-px overflow-y-auto bg-card">

            <!-- View mode -->
            <ng-container>

                <!-- Shortcuts -->
                <div class="grid grid-cols-2 grid-flow-row">
                    <!-- Shortcut -->
                    <ng-container *ngFor="let shortcut of shortcuts; trackBy: trackByFn;let i=$index;">
                        <div class="relative group flex flex-col overflow-hidden bg-card border-r border-b even:border-r-0 hover:bg-gray-50 hover:cursor-pointer">
                            <a class="flex flex-col items-center justify-center w-full h-full py-6 no-underline"
                               (click)="navigateToStep(shortcut.id, shortcut.link)">
                                <ng-container *ngTemplateOutlet="linkContent"></ng-container>
                            </a>
                            <!-- Link content template -->
                            <ng-template #linkContent>
                                <div class="relative flex shrink-0 items-center justify-center w-12 h-12 mb-3 rounded-full text-xl font-bold"
                                     [ngClass]="shortcut.id<=setupProgressStatus ? 'bg-green-200 text-green-800':'bg-orange-200 text-orange-800'">
                                    {{ shortcut.id }}
                                </div>
                                <div class="font-medium text-center">{{t(shortcut.label)}}</div>
                                <div class="text-md text-center text-secondary">{{shortcut.id <= setupProgressStatus ? t('Completed') : t('Pending')}}</div>
                            </ng-template>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

        </div>
    </div>
</ng-template>
