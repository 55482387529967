import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {QuickSetupComponent} from "./components/quick-setup/quick-setup.component";
import {HomeComponent} from "./components/home/home.component";
import {LocationComponent} from "./components/location/location.component";
import {EditLocationComponent} from "./components/edit-location/edit-location.component";


const routes: Routes = [
    {path: '', component: HomeComponent, pathMatch: 'full'},
    {path: 'quick-setup', component: QuickSetupComponent, pathMatch: 'full', data: {level: 1}},
    {path: 'select-location', component: LocationComponent, pathMatch: 'full'},
    {path: 'modify-location', component: EditLocationComponent, pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule {
}
