<div *transloco="let t" class="flex flex-col flex-auto min-w-0">
    <div class="flex-auto mb-5">
        <div class="flex flex-col md:flex-row items-center sm:justify-center md:justify-between py-5 px-10">
            <h1 class="text-3xl font-extrabold tracking-tight leading-none">
                {{ t('Welcome') }} {{ currentUser.fullName | titlecase }}</h1>
            <div class="flex items-center space-x-0 md:space-x-3 mt-2 md:mt-0 flex-col md:flex-row space-y-3 md:space-y-0">
                <button mat-stroked-button [matTooltipPosition]="'above'" (click)="editLocation()"
                        [matTooltip]="t('Modify Location Detail')">
                    <mat-icon class="icon-size-5 rotate-90" [svgIcon]="'heroicons_outline:adjustments'"></mat-icon>
                    <span class="ml-2">{{ t('Location Settings') }}</span>
                </button>
                <button [disabled]="!orgSubscription || (orgSubscription && !orgSubscription.active)"
                        [color]="'primary'" class="fuse-mat-button-rounded text-white" mat-flat-button
                        [routerLink]="setupProgressStatus > 1? '/collect-review/invite-customer':'/collect-review/sequence'">
                    <mat-icon [svgIcon]="'mat_outline:send'" class="icon-size-5"></mat-icon>
                    <span class="ml-2">{{ setupProgressStatus > 1 ? t('Send Review Invites') : t('Setup Sequence') }}</span>
                </button>
            </div>
        </div>
        <div [ngClass]="{'sm:hidden': !isMobileDevice && windowWidth>600}"
             class="flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden p-6 mx-5 md:mx-10">
            <div class="flex items-start">
                <div class="text-2xl font-bold">{{ t('Reviews') }}</div>
            </div>
            <div class="mt-3 divide-y space-y-2">
                <div class="flex justify-between">
                    <div class="ml-3 font-bold auto-cols-max">{{ t('Total') }}</div>
                    <div class="text-right font-bold text-green-800 text-2xl">{{ consolidateReviews?.totalReviews }}</div>
                </div>
                <div class="flex justify-between pt-4">
                    <div class="ml-3 font-bold">{{ t('This Month') }}</div>
                    <div class="text-right font-bold text-green-800 text-2xl">{{ consolidateReviews?.reviewsThisMonth }}</div>
                </div>
            </div>
            <div class="flex items-start pt-4">
                <div class="text-2xl font-bold">{{ t('Customers Enrolled') }}</div>
            </div>
            <div class="mt-3 divide-y space-y-2">
                <div class="flex justify-between">
                    <div class="ml-3 font-bold auto-cols-max">{{ t('Total') }}</div>
                    <div class="text-right font-bold text-blue-800 text-2xl">{{ consolidateReviews?.totalInvites }}</div>
                </div>
                <div class="flex justify-between pt-4">
                    <div class="ml-3 font-bold">{{ t('This Month') }}</div>
                    <div class="text-right font-bold text-blue-800 text-2xl">{{ consolidateReviews?.invitesThisMonth }}</div>
                </div>
            </div>
        </div>
        <div class="flex-col sm:flex-row space-x-0 sm:space-x-8 space-y-5 sm:space-y-0 px-5 hidden"
             [ngClass]="{'sm:flex': !isMobileDevice && windowWidth>600, 'px-10':windowWidth>600}">
            <div class="relative flex flex-col flex-auto p-6 pr-3 pb-3 bg-card rounded-2xl shadow overflow-hidden">
                <div class="absolute bottom-0 right-0 w-24 h-24 -m-6">
                    <mat-icon class="icon-size-24 opacity-25 text-blue-500 dark:text-green-400"
                              [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
                </div>
                <div class="text-2xl text-blue-800 font-medium tracking-tight leading-6 truncate">{{ t('Customer Enrolled') }}</div>
                <div class="flex flex-row flex-wrap mt-4 -mx-6">
                    <div class="flex flex-col mx-6 my-3 hover:bg-blue-50 cursor-pointer p-1 rounded-xl"
                         [matTooltip]="t('Click to View Detail')" [matTooltipPosition]="'above'"
                         [routerLink]="'/collect-review/customer'">
                        <div class="text-sm font-medium leading-none text-secondary flex space-x-2 items-center">
                            <div>{{ t('All Time') }}</div>
                            <mat-icon [svgIcon]="'feather:arrow-right-circle'" class="icon-size-4"></mat-icon>
                        </div>
                        <div class="mt-2 font-medium text-3xl leading-none text-blue-800">{{ consolidateReviews?.totalInvites ?? 0 }}</div>
                    </div>
                    <div class="flex flex-col mx-6 my-3">
                    </div>
                    <div class="flex flex-col mx-6 my-3 hover:bg-blue-50 cursor-pointer p-1 rounded-xl"
                         [matTooltip]="t('Click to View Detail')" [matTooltipPosition]="'above'"
                         [routerLink]="'/collect-review/customer'">
                        <div class="text-sm font-medium leading-none text-secondary flex space-x-2 items-center">
                            <div>{{ t('This Month') }}</div>
                            <mat-icon [svgIcon]="'feather:arrow-right-circle'" class="icon-size-4"></mat-icon>
                        </div>
                        <div class="mt-2 font-medium text-3xl leading-none text-blue-800">{{ consolidateReviews?.invitesThisMonth ?? 0 }}</div>
                    </div>
                </div>
            </div>
            <div class="relative flex flex-col flex-auto p-6 pr-3 pb-3 bg-card rounded-2xl shadow overflow-hidden">
                <div class="absolute bottom-0 right-0 w-24 h-24 -m-6">
                    <mat-icon class="icon-size-24 opacity-25 text-green-500"
                              [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
                </div>
                <div class="text-2xl text-green-800 font-medium tracking-tight leading-6 truncate">{{ t('Reviews') }}</div>
                <div class="flex flex-row flex-wrap mt-4 -mx-6">
                    <div class="flex flex-col mx-6 my-3 hover:bg-green-50 cursor-pointer p-1 rounded-xl"
                         [matTooltip]="t('Click to View Detail')" [matTooltipPosition]="'above'"
                         (click)="goToReviewAnalytics()">
                        <div class="text-sm font-medium leading-none text-secondary flex space-x-2 items-center">
                            <div>{{ t('All Time') }}</div>
                            <mat-icon [svgIcon]="'feather:arrow-right-circle'" class="icon-size-4"></mat-icon>
                        </div>
                        <div class="mt-2 font-medium text-3xl leading-none text-green-800">{{ consolidateReviews?.totalReviews ?? 0 }}</div>
                    </div>
                    <div class="flex flex-col mx-6 my-3">

                    </div>
                    <div class="flex flex-col mx-6 my-3 hover:bg-green-50 cursor-pointer p-1 rounded-xl"
                         [matTooltip]="t('Click to View Detail')" [matTooltipPosition]="'above'"
                         (click)="goToReviewAnalytics('this_month')">
                        <div class="text-sm font-medium leading-none text-secondary flex space-x-2 items-center">
                            <div>{{ t('This Month') }}</div>
                            <mat-icon [svgIcon]="'feather:arrow-right-circle'" class="icon-size-4"></mat-icon>
                        </div>
                        <div class="mt-2 font-medium text-3xl leading-none text-green-800">{{ consolidateReviews?.reviewsThisMonth ?? 0 }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid gap-2 bg-card rounded-2xl shadow p-5 m-5 md:mx-10"
             [ngClass]="windowWidth>1024 ? 'grid-cols-2': 'grid-cols-1'">
            <div *ngIf="!isMobileDevice" class="flex flex-col border rounded-2xl p-6">
                <div class="flex items-start justify-between">
                    <div [matTooltipPosition]="'above'" class="text-2xl font-bold" matTooltipHideDelay="100"
                         [matTooltip]="t('This graph shows how many reviews you have got against the invites sent')"
                         matTooltipShowDelay="100">{{ t('Review Analytics') }}
                    </div>
                </div>
                <div class="mt-4">
                    <apx-chart #chart [chart]="chartOptions?.chart" [colors]="chartOptions?.colors"
                               [series]="chartOptions?.series" [title]="chartOptions?.title"
                               [tooltip]="chartOptions?.tooltip" [xaxis]="chartOptions?.xaxis" class="w-full">
                    </apx-chart>
                </div>
            </div>
            <div class="flex flex-col space-y-2">
                <div class="grid gap-2" *ngIf="setupDelayInMinutes>7"
                     [ngClass]="windowWidth>1024 ? 'grid-cols-2': 'grid-cols-1'">
                    <div class="flex flex-col flex-auto bg-card border rounded-2xl overflow-hidden p-6">
                        <div class="flex items-start justify-between">
                            <div class="text-2xl font-bold">{{ t('My Reviews And Rating') }}</div>
                        </div>
                        <div class="mt-3 mx-auto overflow-hidden w-full">
                            <div class="flex flex-col p-1" *ngFor="let review of reviewInfo?.reviewInfoDetailList;">
                                <div class="truncate text-xl font-bold min-w-46">{{ review.siteName }}</div>
                                <div class="flex items-center space-x-2">
                                    <span class="text-secondary text-xl font-bold mt-1 w-8">{{ review.rating | number:'0.0-1' }}</span>
                                    <rating [rating]="review.rating" [size]="6"></rating>
                                    <span class="text-secondary font-bold text-xl mt-1">({{ review.reviews }})</span>
                                </div>
                            </div>
                            <div class="flex items-center space-x-2 p-1"
                                 *ngIf="npsSurveyCategory && npsSurveyCategory.surveyFeedback">
                                <div class="truncate text-xl font-bold w-22 min-w-22">{{ npsSurveyCategory.surveyFeedback }}</div>
                                <span class="text-xl font-bold" [ngClass]="npsSurveyCategory.average > 0 ? 'text-green-500' :'text-red-400' ">
                                    {{ npsSurveyCategory.average | abs | number:'0.0-1' }}
                                </span>
                            </div>
                            <div class="flex items-center space-x-2 p-1"
                                 *ngIf="csatSurveyCategory && csatSurveyCategory.surveyFeedback">
                                <div class="truncate text-xl font-bold w-22 min-w-22">{{ csatSurveyCategory.surveyFeedback }}</div>
                                <span class="text-green-400 text-xl font-bold">{{ csatSurveyCategory.average | number:'0.0-1' }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col flex-auto border rounded-2xl overflow-hidden p-6">
                        <div class="flex items-start">
                            <div class="text-2xl font-bold">Google {{ t('Review') }}</div>
                        </div>
                        <div class="flex items-start mt-2">
                            <div class="font-bold">{{ t('Now') }}</div>
                        </div>
                        <div class="font-bold text-3xl text-center justify-center flex text-green-700">{{ googleRatingInfoAfter?.reviews || 0 }}</div>
                        <div class="flex items-start">
                            <div class="font-bold">{{ t('Before') }}</div>
                        </div>
                        <div class="font-bold text-3xl text-center justify-center flex text-blue-700">{{ googleRatingInfoBefore?.reviews || 0 }}</div>
                    </div>
                </div>
                <div class="flex flex-col flex-auto border rounded-2xl overflow-hidden p-6"
                     *ngIf="currentUser && currentUser.roleType == 'SUPER_ADMIN'">
                    <div class="flex items-start justify-between">
                        <div class="text-2xl font-bold">{{ t('My Subscription') }}</div>
                    </div>
                    <div class="flex items-center px-2 font-bold text-xl space-x-10">
                        <div>{{ t('Plan Name') }}</div>
                        <div class="text-green-700">{{ orgSubscription?.packageName }}</div>
                    </div>
                    <div class="flex flex-col flex-auto overflow-hidden" *ngIf="!isAppleDevice">
                        <div *ngIf="mySubscriptionInfo && mySubscriptionInfo?.active" class="flex items-start my-2">
                            <div class="text-2xl font-bold">{{ t('Credits Remaining') }}
                                For {{ currentLocation.name }}
                            </div>
                        </div>
                        <div *ngIf="mySubscriptionInfo && mySubscriptionInfo?.active"
                             class="grid grid-cols-1 md:grid-cols-3 gap-y-3">
                            <div class="ml-3 font-bold flex items-center space-x-1">
                                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:users'"></mat-icon>
                                <div>{{ t('Customer') }} :</div>
                                <span class="text-right text-secondary font-bold"> {{ mySubscriptionInfo?.totalCustomerCredit }}</span>
                            </div>
                            <div class="ml-3 font-bold flex flex-col space-y-1"
                                 [ngClass]="{'text-red-600': ((mySubscriptionInfo?.totalSmsCredit + mySubscriptionInfo?.extraSmsCredit) / (mySubscriptionInfo?.smsFUP + mySubscriptionInfo?.extraSmsCredit)) <=0.15 }"
                                 *ngIf="currentLocation.smsEnabled && orgSubscription.smsEnabled && !smsConfigured ">
                                <div class="flex items-center space-x-1">
                                    <mat-icon class="icon-size-5" [svgIcon]="'message'"
                                              [ngClass]="{'text-red-600': ((mySubscriptionInfo?.totalSmsCredit + mySubscriptionInfo?.extraSmsCredit) / (mySubscriptionInfo?.smsFUP + mySubscriptionInfo?.extraSmsCredit)) <=0.15 }"></mat-icon>
                                    <div>{{ t('SMS') }} :</div>
                                    <span class="text-right text-secondary font-bold"
                                          [ngClass]="{'text-red-600': ((mySubscriptionInfo?.totalSmsCredit + mySubscriptionInfo?.extraSmsCredit) / (mySubscriptionInfo?.smsFUP + mySubscriptionInfo?.extraSmsCredit)) <=0.15 }">
                                    {{ mySubscriptionInfo?.totalSmsCredit + mySubscriptionInfo?.extraSmsCredit }}</span>
                                </div>
                                <a class="text-blue-600 hover:underline cursor-pointer text-sm"
                                   [routerLink]="'/agency-mode/detail'" [matTooltip]="t('Buy SMS Credits')"
                                   [matTooltipPosition]="'above'"
                                   *ngIf="((mySubscriptionInfo?.totalSmsCredit + mySubscriptionInfo?.extraSmsCredit) / (mySubscriptionInfo?.smsFUP + mySubscriptionInfo?.extraSmsCredit)) <=0.15"
                                >{{ t('Buy Now') }}</a>
                            </div>
                            <div class="ml-3 font-bold flex flex-col space-y-1"
                                 [ngClass]="{'text-red-600': ((mySubscriptionInfo?.totalEmailCredit + mySubscriptionInfo?.extraEmailCredit) / (mySubscriptionInfo?.emailFUP + mySubscriptionInfo?.extraEmailCredit)) <=0.15 }"
                                 *ngIf="!emailConfigured">
                                <div class="flex items-center space-x-1">
                                    <mat-icon class="icon-size-5" [svgIcon]="'message'"
                                              [ngClass]="{'text-red-600': ((mySubscriptionInfo?.totalEmailCredit + mySubscriptionInfo?.extraEmailCredit) / (mySubscriptionInfo?.emailFUP + mySubscriptionInfo?.extraEmailCredit)) <=0.15 }"></mat-icon>
                                    <div>{{ t('Email') }} :</div>
                                    <span class="text-right text-secondary font-bold"
                                          [ngClass]="{'text-red-600': ((mySubscriptionInfo?.totalEmailCredit + mySubscriptionInfo?.extraEmailCredit) / (mySubscriptionInfo?.emailFUP + mySubscriptionInfo?.extraEmailCredit)) <=0.15 }">
                                    {{ mySubscriptionInfo?.totalEmailCredit + mySubscriptionInfo?.extraEmailCredit }}</span>
                                </div>
                                <a class="text-blue-600 hover:underline cursor-pointer text-sm"
                                   [routerLink]="'/agency-mode/detail'" [matTooltip]="t('Buy Email Credits')"
                                   [matTooltipPosition]="'above'"
                                   *ngIf="((mySubscriptionInfo?.totalEmailCredit + mySubscriptionInfo?.extraEmailCredit) / (mySubscriptionInfo?.emailFUP + mySubscriptionInfo?.extraEmailCredit)) <=0.15"
                                >{{ t('Buy Now') }}</a>
                            </div>

                            <div class="ml-3 font-bold flex items-center space-x-1"
                                 *ngIf="orgSubscription.packageType != 'LTD'">
                                <mat-icon class="icon-size-5" [svgIcon]="'grid_on'"></mat-icon>
                                <div>{{ t('Widget') }} :</div>
                                <span class="text-right text-secondary font-bold"> {{ mySubscriptionInfo?.totalWidgetCredit }}</span>
                            </div>
                            <div class="ml-3 font-bold flex flex-col space-y-1"
                                 [ngClass]="{'text-red-600': ((mySubscriptionInfo?.totalWaCredit + mySubscriptionInfo?.extraWhatsappCredit ) / (mySubscriptionInfo?.whatsAppFUP + mySubscriptionInfo?.extraWhatsappCredit)) <= 0.15 }"
                                 *ngIf="currentLocation.whatsappEnabled && orgSubscription.whatsappEnabled && orgSubscription.packageType != 'LTD'">
                                <div class="flex items-center space-x-1">
                                    <img src="assets/icons/whatsapp6.svg" [alt]="'WhatsApp'" class="w-5"
                                         *ngIf="((mySubscriptionInfo?.totalWaCredit + mySubscriptionInfo?.extraWhatsappCredit ) / (mySubscriptionInfo?.whatsAppFUP + mySubscriptionInfo?.extraWhatsappCredit)) <= 0.15 else whatAppCredits"
                                    >
                                    <div>{{ t('WhatsApp') }} :</div>
                                    <span class="text-right text-secondary font-bold"
                                          [ngClass]="{'text-red-600': ((mySubscriptionInfo?.totalWaCredit + mySubscriptionInfo?.extraWhatsappCredit ) / (mySubscriptionInfo?.whatsAppFUP + mySubscriptionInfo?.extraWhatsappCredit)) <= 0.15 }">
                                    {{ mySubscriptionInfo?.totalWaCredit + mySubscriptionInfo?.extraWhatsappCredit }}</span>
                                </div>
                                <a class="text-blue-600 hover:underline cursor-pointer text-sm"
                                   [routerLink]="'/agency-mode/detail'" [matTooltip]="t('Buy WhatsApp Credits')"
                                   [matTooltipPosition]="'above'"
                                   *ngIf="((mySubscriptionInfo?.totalWaCredit + mySubscriptionInfo?.extraWhatsappCredit ) / (mySubscriptionInfo?.whatsAppFUP + mySubscriptionInfo?.extraWhatsappCredit)) <=0.15"
                                >{{ t('Buy Now') }}</a>
                            </div>
                            <div class="ml-3 font-bold flex flex-col space-y-1"
                                 [ngClass]="{'text-red-600': ((mySubscriptionInfo?.totalMmsCredit + mySubscriptionInfo?.extraMmsCredit) / (mySubscriptionInfo?.mmsFup + mySubscriptionInfo?.extraMmsCredit)) <=0.15 }"
                                 *ngIf="currentLocation.mmsEnabled && orgSubscription.mmsEnabled && orgSubscription.packageType != 'LTD'">
                                <div class="flex items-center space-x-1">
                                    <mat-icon class="icon-size-5" [svgIcon]="'mms'"
                                              [ngClass]="{'text-red-600': ((mySubscriptionInfo?.totalMmsCredit + mySubscriptionInfo?.extraMmsCredit) / (mySubscriptionInfo?.mmsFup + mySubscriptionInfo?.extraMmsCredit)) <=0.15 }"></mat-icon>
                                    <div>MMS :</div>
                                    <span class="text-right text-secondary font-bold"
                                          [ngClass]="{'text-red-600': ((mySubscriptionInfo?.totalMmsCredit + mySubscriptionInfo?.extraMmsCredit) / (mySubscriptionInfo?.mmsFup + mySubscriptionInfo?.extraMmsCredit)) <=0.15 }">
                                    {{ mySubscriptionInfo?.totalMmsCredit + mySubscriptionInfo?.extraMmsCredit }}</span>
                                </div>
                                <a class="text-blue-600 hover:underline cursor-pointer text-sm"
                                   [routerLink]="'/agency-mode/detail'" [matTooltip]="t('Buy MMS Credits')"
                                   [matTooltipPosition]="'above'"
                                   *ngIf="((mySubscriptionInfo?.totalMmsCredit + mySubscriptionInfo?.extraMmsCredit) / (mySubscriptionInfo?.mmsFup + mySubscriptionInfo?.extraMmsCredit)) <=0.15"
                                >{{ t('Buy Now') }}</a>
                            </div>
                            <div class="ml-3 font-bold flex items-center space-x-1">
                                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:user'"></mat-icon>
                                <div>{{ t('User') }} :</div>
                                <span class="text-right text-secondary font-bold"> {{ mySubscriptionInfo?.totalUserCredit }}</span>
                            </div>
                            <div class="ml-3 font-bold flex items-center space-x-1">
                                <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:videocam'"></mat-icon>
                                <div>{{ t('Video Reel') }} :</div>
                                <span class="text-right text-secondary font-bold"> {{ mySubscriptionInfo?.videoCreditsFup - mySubscriptionInfo?.videoCreditsConsumed }}</span>
                            </div>
                        </div>
                        <div *ngIf="mySubscriptionInfo && mySubscriptionInfo?.active && validSubscriptionForAnalytics"
                             class="flex items-start my-2">
                            <div class="text-2xl font-bold">{{ t('Other Module') }}</div>
                        </div>
                        <div class="ml-3 font-bold flex items-center space-x-1" *ngIf="validSubscriptionForAnalytics">
                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:insert_chart_outlined'"></mat-icon>
                            <div>{{ t('Advance Analytics') }}</div>
                            <span class="text-right text-secondary font-bold"> {{ t('will be available until') }} {{ mySubscriptionInfo?.analyticsCutoffTime | date:'dd MMMM yyyy' }}</span>
                        </div>
                        <div class="pl-3 pt-2 flex items-center"
                             *ngIf="orgSubscription && orgSubscription.packageType == 'LTD' && !smsConfigured && !emailConfigured && mySubscriptionInfo && mySubscriptionInfo.extraSmsCredit ==0 && mySubscriptionInfo.extraEmailCredit == 0">
                            <mat-icon class="icon-size-4" [svgIcon]="'heroicons_outline:info'"></mat-icon>
                            {{ t('Purchase extra credits to send more review requests.') }}
                            <a class="hover:underline  text-blue-600"
                               [routerLink]="'/agency-mode/detail'">{{ t('click here') }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #whatAppCredits>
    <img src="assets/icons/whatsapp3.svg" [alt]="'WhatsApp'" class="w-5">
</ng-template>
