import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../../../core/auth/auth.service";
import {MatStepper} from "@angular/material/stepper";
import {ReviewSiteMaster} from "../../models/review-site-master";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {QuickSetupService} from "../../services/quick-setup.service";
import {MatDialog} from "@angular/material/dialog";
import {AllLocationResponse} from "../../../../../shared/models/location.types";
import {LocationService} from "../../../../../shared/services/location.service";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {GPlace, GPlaceResponse} from "../../models/g-place";
import {HomeService} from "../../services/home.service";
import {Country} from "../../models/country";
import {TranslocoService} from '@ngneat/transloco';
import {SnackbarService} from "../../../../../shared/services/snackbar.service";
import {PropertyGroup} from "../../../../../shared/constants/property-group";
import {SettingService} from "../../../collect-review/services/setting.service";
import {ShortcutsService} from "../../../../../layout/common/shortcuts/shortcuts.service";
import {FileHandle} from "../../../../../shared/directives/drag-drop-directive";
import {SequenceService} from "../../../collect-review/services/sequence.service";
import {StageService} from "../../../collect-review/services/stage.service";
import {Stage} from "../../../collect-review/models/add-stage-request";
import {SquareService} from "../../../integration/services/square.service";
import {Channels} from "../../../../../shared/constants/channels";
import {TemplateService} from "../../../collect-review/services/template.service";
import {FreshreviewFromEmail, SignUpRoutingConfig} from "../../../../../shared/constants/sign-up-routing-config";
import {SignUpConfigType} from "../../../../../shared/models/sign-up-config-type";
import {ChannelTemplate} from "../../../collect-review/models/channel-template";
import {MatSelectChange} from "@angular/material/select";
import {PhoneValidator} from "../../../../../shared/validators/phone-validator";
import {ReviewSite} from "../../models/review-site";
import {LTDService} from "../../../agency-mode/services/ltd.service";
import {MediaService} from "../../../../../shared/services/media.service";

declare var google: any;

@Component({
    selector: 'app-quick-setup',
    templateUrl: './quick-setup.component.html'
})
export class QuickSetupComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('customLinkDialog') customLinkDialog: TemplateRef<any>;
    @ViewChild('newBusinessSetup') newBusinessSetup: TemplateRef<any>;
    websiteRegxStr = 'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=#!]*)';
    quickSetupForm: UntypedFormGroup;
    customReviewForm: UntypedFormGroup;
    reviewSiteMasterList: ReviewSiteMaster[] = [];
    selectedBusinessIndex = -1;
    isBusinessSearch = false;
    isBusinessSearching = false;
    loading = false;
    isLogoExist = false;
    links = [];
    placeService: any;
    googlePlaces: GPlaceResponse[] = [];
    countries: Country[] = [];
    supportedCountryName: string[] = [];
    showNextSetup = false;
    isMobileDevice = false;
    isAPICallComplete = true;
    signUpRouteConfig: SignUpConfigType[] = SignUpRoutingConfig;
    selectedSignUpConfig: SignUpConfigType;
    smsTemplate: ChannelTemplate[] = [];
    whatsAppTemplate: ChannelTemplate[] = [];
    emailTemplate: ChannelTemplate[] = [];
    isSetupComplete = true;
    siteIndex = -1;
    canEditSiteName = false;
    fromName = '';
    fromEmail = FreshreviewFromEmail;
    replyTo = '';
    feedbackEmail = '';
    innerWidth = window.innerWidth;

    constructor(private formBuilder: UntypedFormBuilder,
                private authService: AuthService,
                private quickSetupService: QuickSetupService,
                private matDialog: MatDialog,
                private locationService: LocationService,
                private router: Router,
                private snackBar: MatSnackBar,
                private homeService: HomeService,
                private translocoService: TranslocoService,
                private snackbarService: SnackbarService,
                private settingService: SettingService,
                private shortcutService: ShortcutsService,
                private sequenceService: SequenceService,
                private stageService: StageService,
                private squareService: SquareService,
                private templateService: TemplateService,
                private ltdService: LTDService,
                private mediaService: MediaService
    ) {
    }

    ngOnInit(): void {
        this.homeService.currentNativeDeviceInfo.subscribe(isNative => this.isMobileDevice = isNative);
        if (this.homeService.orgSubscription && this.homeService.orgSubscription.locationCredit == 0 && this.locationService.allLocation && this.locationService.allLocation.length) {
            if (this.locationService.allLocation.length > 1) {
                this.router.navigate(['dashboard/select-location']);
                this.locationService.currentLocationDetail = null;
            } else {
                this.locationService.currentLocationDetail = this.locationService.allLocation[0];
                this.router.navigate(['dashboard']);
            }
        }
        this.quickSetupForm = this.formBuilder.group({
            searchBusinessForm: this.formBuilder.group({
                name: ['', [Validators.required]],
                place: ['', Validators.required],
                businessSelected: ['', Validators.required],
            }),
            setupBusinessForm: this.formBuilder.group({
                name: ['', Validators.required],
                address: [''],
                businessType: ['',],
                country: ['', Validators.required],
                googlePlaceId: [''],
                logoImageUrl: [''],
                userId: ['', Validators.required],
                website: ['', Validators.pattern(this.websiteRegxStr)],
                googleRating: [''],
                googleReviews: [''],
                city: [''],
                phoneNo: ['', Validators.required]
            }),
            reviewSiteForm: this.formBuilder.group({
                links: this.formBuilder.array([
                    this.formBuilder.group({
                        locationId: ['', Validators.required],
                        logoURL: [''],
                        name: ['', Validators.required],
                        priority: [0],
                        reviewLink: ['', Validators.email],
                        isCustom: [false]
                    })
                ])
            })
        });
        this.homeService.getAllSupportedCountries().subscribe(countries => {
            this.countries = countries.sort((a, b) => a.countryName.toLowerCase() > b.countryName.toLowerCase() ? 1 : -1);
            this.supportedCountryName = this.countries.map(country => country['countryName'].toLowerCase());
        });
        this.customReviewForm = this.formBuilder.group({
            name: ['', Validators.required],
            link: ['', [Validators.required, Validators.pattern(this.websiteRegxStr)]],
        });
        const signUpFrom = localStorage.getItem('signup_from') || '';
        let signUpConfig = this.signUpRouteConfig.find(config => config.signUpFrom == signUpFrom);
        if (signUpConfig) {
            this.selectedSignUpConfig = signUpConfig;
        } else {
            this.selectedSignUpConfig = this.signUpRouteConfig.find(config => config.signUpFrom == '');
        }
        const provders = this.ltdService.providers;
        if (provders && provders.length > 0) {
            const emailProvider = provders.find(p => p.providerType == 'EMAIL');
            if (emailProvider) {
                this.fromName = emailProvider.properties['fromName'];
                this.fromEmail = emailProvider.properties['fromEmail'];
                this.feedbackEmail = emailProvider.properties['feedbackEmail'];
                this.replyTo = emailProvider.properties['replyTo'];
            }
        } else {
            this.fromName = this.authService.businessUser.fullName;
            this.feedbackEmail = this.authService.businessUser.emailAddress;
            this.replyTo = this.authService.businessUser.emailAddress;
        }
    }

    searchBusiness() {
        try {
            this.selectedBusinessIndex = -1;
            this.isBusinessSearch = false;
            this.isBusinessSearching = true;
            this.snackbarService.showSnackBar(this.translocoService.translate('Please wait while we fetch your location details'));
            this.placeService.findPlaceFromQuery({
                query: `${this.quickSetupForm.value.searchBusinessForm.name} in ${this.quickSetupForm.value.searchBusinessForm.place}`,
                fields: ['formatted_address', 'place_id', 'name', 'user_ratings_total']
            }, (results: GPlaceResponse[], status) => {
                this.googlePlaces = results.filter((place) => {
                    const countries = this.supportedCountryName.filter(country => place.formatted_address.toLowerCase().includes(country.toLowerCase()) && !place.formatted_address.toLowerCase().includes('india'));
                    return countries.length > 0;
                });
                this.isBusinessSearch = true;
                this.isBusinessSearching = false;
                this.snackBar.dismiss();
            });
        } catch (e) {
            console.log(e);
            this.isBusinessSearch = true;
            this.isBusinessSearching = false;
        }
    }

    selectBusiness(matStepper: MatStepper) {
        const placeId = this.googlePlaces[this.selectedBusinessIndex].place_id
        const existingLocation = this.locationService.allLocation.filter(location => location.googlePlaceId == placeId);
        if (existingLocation.length) {
            this.snackBar.open(this.translocoService.translate('Location Already Exists'), '', {duration: 5000,});
            return;
        }
        (this.quickSetupForm.controls['searchBusinessForm'] as FormGroup).controls['businessSelected'].setValue('selected');
        this.isLogoExist = false;
        const form: FormGroup = this.quickSetupForm.controls['setupBusinessForm'] as FormGroup;
        let countryName = '';
        this.placeService.getDetails({
            placeId: placeId,
            fields: ['address_components', 'rating', 'types', 'website', 'formatted_phone_number'],
        }, (results: GPlace, status) => {
            form.controls['name'].setValue(this.googlePlaces[this.selectedBusinessIndex].name);
            form.controls['businessType'].setValue(results.types[0]);
            const country = results.address_components.filter(addr => addr.types[0] == 'country');
            form.controls['googlePlaceId'].setValue(placeId);
            form.controls['googleRating'].setValue(results.rating);
            let address = this.googlePlaces[this.selectedBusinessIndex].formatted_address;
            if (countryName) {
                address = address.replace(countryName, '').trim();
                if (address.length && address[address.length - 1] == ',') {
                    address = address.substr(0, address.length - 2)
                }
            }
            form.controls['address'].setValue(address);
            form.controls['website'].setValue(results.website);
            form.controls['phoneNo'].setValue(results.formatted_phone_number);
            form.controls['googleReviews'].setValue(this.googlePlaces[this.selectedBusinessIndex].user_ratings_total);
            if (country.length) {
                const existingCountry = this.countries.filter(item => item.countryName.toLowerCase().includes(country[0].long_name.toLowerCase()));
                if (existingCountry.length) {
                    form.controls['country'].setValue(existingCountry[0].countryName);
                    form.controls['phoneNo'].setValidators([Validators.required, PhoneValidator.invalidCountryPhone(existingCountry[0].countryCode, existingCountry[0].dialCodePrefix)]);
                    form.controls['phoneNo'].updateValueAndValidity();
                }
            }
            const city = results.address_components.filter(addr => addr.types[0] == 'locality');
            if (city.length) {
                form.controls['city'].setValue(city[0].long_name);
            }
        });
        form.controls['userId'].setValue(this.authService.businessUser.userId);
        setTimeout(() => {
            form.controls['name'].updateValueAndValidity();
            form.controls['country'].updateValueAndValidity();
            form.controls['website'].updateValueAndValidity();
            form.controls['name'].markAsTouched();
            form.controls['country'].markAsTouched();
            form.controls['website'].markAsTouched();
            form.controls['phoneNo'].markAsTouched();
        }, 1000);
        matStepper.next();
    }

    createAndAttachUser() {
        this.loading = true;
        this.isSetupComplete = false;
        let formValue = (this.quickSetupForm.controls['setupBusinessForm'] as FormGroup).value;
        formValue['name'] = formValue['name'].replace(/[^a-zA-Z0-9 ]/g, '');
        if (!formValue['googlePlaceId']) {
            formValue['googlePlaceId'] = '';
        }
        if (!formValue['logoImageUrl']) {
            formValue['logoImageUrl'] = '';
        }
        if (!formValue['website']) {
            formValue['website'] = '';
        }
        if (!formValue['googleRating']) {
            formValue['googleRating'] = '';
        }
        if (!formValue['googleReviews']) {
            formValue['googleReviews'] = '';
        }
        if (!formValue['businessType']) {
            formValue['businessType'] = '';
        }
        this.quickSetupService.createAndAttachUser(formValue).subscribe({
            next: (res: any) => {
                this.setSenderId(res);
                localStorage.setItem('setupDate', new Date().toISOString());
                localStorage.setItem('setupLocationId', res.locationId);
                this.links = (this.quickSetupForm.controls['reviewSiteForm'] as FormGroup).value.links.filter(link => link.reviewLink.length).map((link, index) => {
                    link['locationId'] = res.locationId;
                    link['priority'] = index + 1;
                    return link;
                });
                this.saveAllReviewSites({
                    LocationId: res.locationId,
                    LocationName: res.name,
                    Country: res.countryName
                });
                this.homeService.getMyOrgSubscription().subscribe(res1 => this.homeService.orgSubscription = res1);
                this.locationService.fetchAllLocationForOrgUser().subscribe({
                    next: (res1: AllLocationResponse[]) => {
                        this.locationService.allLocation = res1;
                        this.locationService.currentLocationDetail = res1.filter(location => location.locationId == res.locationId)[0];
                        this.homeService.getMySubscriptionInfo(this.locationService.currentLocationDetail.locationId).subscribe(res => {
                            this.homeService.locationSubscription = res;
                        });
                        this.addLocationProperty(this.locationService.currentLocationDetail.locationId);
                        if (this.selectedSignUpConfig.setupDefaultSequence) {
                            this.setupDefaultSequence();
                        } else {
                            this.gotoFinalRoutes();
                        }
                    }, error: (error) => {
                        console.error(error);
                        this.loading = false;
                        this.isSetupComplete = true;
                    }
                });
                localStorage.removeItem('business_name');
                localStorage.removeItem('place');
                this.saveEmailSettings(res.locationId);
            },
            error: (error) => {
                this.snackBar.open(error.error.error, '', {duration: 5000});
                this.loading = false;
                this.isSetupComplete = true;
            }
        });
    }


    onFileSelect(event) {
        if (event.target.files.length > 0) {
            this.setImageFile(event.target.files[0]);
        }
    }

    get siteLinks() {
        return (this.quickSetupForm.controls['reviewSiteForm'] as FormGroup).get('links') as FormArray;
    }

    addReviewSite(locationId: string, logoURL: string, name: string, priority: number, reviewLink: string, isCustom = false) {
        this.siteLinks.push(this.formBuilder.group({
            locationId: locationId,
            logoURL: logoURL,
            name: name,
            priority: priority,
            reviewLink: reviewLink,
            isCustom: isCustom,
        }));
    }


    addManually(matStepper: MatStepper) {
        (this.quickSetupForm.controls['searchBusinessForm'] as FormGroup).controls['businessSelected'].setValue('selected');
        const form: FormGroup = this.quickSetupForm.controls['setupBusinessForm'] as FormGroup;
        form.reset()
        form.controls['userId'].setValue(this.authService.businessUser.userId);
        this.selectedBusinessIndex = -1;
        this.isLogoExist = false
        form.controls['name'].markAsTouched();
        form.controls['country'].markAsTouched();
        form.controls['website'].markAsTouched();
        form.controls['phoneNo'].markAsTouched();
        matStepper.next();
    }


    moveItemInFormArray(formArray: FormArray, fromIndex: number, toIndex: number): void {
        const from = this.clamp(fromIndex, formArray.length - 1);
        const to = this.clamp(toIndex, formArray.length - 1);
        if (from === to) {
            return;
        }
        const previous = formArray.at(from);
        const current = formArray.at(to);
        formArray.setControl(to, previous);
        formArray.setControl(from, current);
        this.setPriority();
    }

    clamp(value: number, max: number): number {
        return Math.max(0, Math.min(max, value));
    }

    drop(event: CdkDragDrop<string[]>) {
        const from = event.previousIndex;
        const to = event.currentIndex;
        this.moveItemInFormArray(this.siteLinks, from, to);
    }

    openDialog(site: ReviewSite = null, index = -1) {
        this.siteIndex = index;
        if (site) {
            this.customReviewForm.controls['name'].setValue(site.name);
            this.customReviewForm.controls['link'].setValue(site.reviewLink);
            this.canEditSiteName = site.isCustom;
        } else {
            this.customReviewForm.reset();
            this.canEditSiteName = true;
        }
        this.matDialog.open(this.customLinkDialog, {
            panelClass: window.innerWidth >= 1024 ? 'w-5/12' : 'w-full'
        });
    }

    closeDialog() {
        this.matDialog.closeAll();
    }

    addCustomSite() {
        if (this.siteIndex == -1) {
            this.addReviewSite('', '', this.customReviewForm.value.name, 0, this.customReviewForm.value.link, true);
        } else {
            (this.siteLinks.controls[this.siteIndex] as FormGroup).controls['name'].setValue(this.customReviewForm.value.name);
            (this.siteLinks.controls[this.siteIndex] as FormGroup).controls['reviewLink'].setValue(this.customReviewForm.value.link);
        }
        this.closeDialog();
        this.customReviewForm.reset();
        const configuredReviewSites = this.siteLinks.value.filter(site => site.reviewLink && site.reviewLink.length > 0);
        const notConfiguredReviewSites = this.siteLinks.value.filter(site => !site.reviewLink);
        while (this.siteLinks.length) {
            this.siteLinks.removeAt(0);
        }
        configuredReviewSites.forEach((site, index) => {
            this.addReviewSite(site.locationId, site.logoURL, site.name, index + 1, site.reviewLink, site.isCustom);
        });
        notConfiguredReviewSites.forEach((site, index) => {
            this.addReviewSite(site.locationId, site.logoURL, site.name, notConfiguredReviewSites.length + index, site.reviewLink, site.isCustom);
        });

    }


    showSummary(matStepper: MatStepper) {
        this.links = (this.quickSetupForm.controls['reviewSiteForm'] as FormGroup).value.links.filter(link => link.reviewLink.length).map((link, index) => {
            link['priority'] = index + 1;
            return link;
        });
        matStepper.next();
        this.showNextSetup = true;
    }

    getReviewSiteLinks(matStepper: MatStepper) {
        if (this.reviewSiteMasterList.length) {
            matStepper.next();
        } else {
            this.isAPICallComplete = false;
            this.quickSetupService.reviewSiteMaster().subscribe({
                next: (res) => {
                    this.reviewSiteMasterList.push(res.filter(site => site.name === 'Google')[0]);
                    this.reviewSiteMasterList = this.reviewSiteMasterList.concat(res.filter(site => site.name !== 'Google'));
                    matStepper.next();
                    if (this.siteLinks.length) {
                        this.siteLinks.removeAt(0);
                    }
                    this.reviewSiteMasterList.forEach((site, index) => {
                        let reviewLink = '';
                        if (site.name === 'Google' && this.selectedBusinessIndex >= 0) {
                            const placeId = this.googlePlaces[this.selectedBusinessIndex].place_id;
                            reviewLink = 'https://search.google.com/local/writereview?placeid=' + placeId;
                        }
                        this.addReviewSite('', site.logoURL, site.name, index + 1, reviewLink)
                    });
                    this.isAPICallComplete = true
                }, error: (error) => {
                    this.isAPICallComplete = true;
                    console.log(error.error.error);
                }
            });
        }
    }

    saveAllReviewSites(data: any) {
        this.quickSetupService.createReviewSites(this.links).subscribe({
            next: (res) => {
            },
            error: (error) => console.error(error)
        });
    }

    isReviewSiteInValid(): boolean {
        const invalidSite = this.siteLinks.value.filter(site => {
            return site['reviewLink'] && !this.validURL(site['reviewLink']);
        });
        return invalidSite.length > 0;
    }

    validURL(url: string): boolean {
        return url ? url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) != null : true;
    }

    testReviewLink(i: number, link: any) {
        this.selectedBusinessIndex = i;
        window.open(link.reviewLink, '_blank');
    }


    ngAfterViewInit() {
        const allLocation = JSON.parse(localStorage.getItem('all_location'));
        if (!allLocation || allLocation.length == 0) {
            this.matDialog.open(this.newBusinessSetup);
        }
        this.placeService = new google.maps.places.PlacesService(new google.maps.Map(document.getElementById("map")));
        this.templateService.getAllTemplates(Channels.SMS).subscribe(res => this.smsTemplate = res);
        this.templateService.getAllTemplates(Channels.EMAIL).subscribe(res => this.emailTemplate = res);
        this.templateService.getAllTemplates(Channels.WHATSAPP).subscribe(res => this.whatsAppTemplate = res);

    }

    addLocationProperty(locationId: String, propertyKey = 'locationSetup') {
        const propertiesData = {
            "groupName": PropertyGroup.GENERAL,
            "locPropertyDetailList": [{
                "propertyKey": propertyKey,
                "propertyValue": true
            }],
            "locationId": locationId
        }
        this.settingService.createOrUpdateProperties(propertiesData).subscribe(res => this.shortcutService.currentSetupProgressDetail = propertyKey == 'locationSetup' ? 1 : 2);
    }

    ngOnDestroy() {
        this.snackBar.dismiss();
    }

    filesDropped(files: FileHandle[]): void {
        if (files.length > 0) {
            this.setImageFile(files[0].file);
        }
    }

    setImageFile(file: File) {
        if ((file.size / (1024 * 1024)) > 1) {
            this.snackBar.open(this.translocoService.translate('Logo Size Less Than 1 MB'), '', {
                duration: 5000,
            });
            return;
        }
        const formData = new FormData();
        const now = new Date().getTime();
        const path = 'new_location/' + this.authService.businessUser.orgId + '/' + now + '.' + file.name.split('.')[1];
        formData.append('file', file);
        formData.append('path', path);
        this.mediaService.uploadFileToS3(formData).subscribe({
            next: (res: any) => {
                const form: FormGroup = this.quickSetupForm.controls['setupBusinessForm'] as FormGroup;
                form.controls['logoImageUrl'].setValue(res.message);
                this.isLogoExist = true;
            },
            error: (error) => {
                console.log(error);
            }
        });
    }

    setupDefaultSequence() {
        const sequenceRequestObject = {
            sequenceName: this.selectedSignUpConfig.defaultSequenceName,
            locationId: this.locationService.currentLocationDetail.locationId
        }
        this.sequenceService.createSequenceMaster(sequenceRequestObject).subscribe({
            next: (res) => {
                this.sequenceService.sequenceDetail = res;
                this.addLocationProperty(this.locationService.currentLocationDetail.locationId, 'sequenceSetup');
                this.createDefaultStages(res.sequenceMasterId);
                this.setDefaultSequence(res.sequenceMasterId);
            },
            error: (error) => {
                console.log(error.error);
                this.goToSequence();
            },
            complete: () => this.isAPICallComplete = true
        });
    }

    createDefaultStages(sequenceMasterId: string) {
        const templateForSequence = this.selectedSignUpConfig.countryCodeList.includes(this.locationService.currentLocationDetail.countryCode) ? this.selectedSignUpConfig.templateDetail : this.selectedSignUpConfig.otherTemplateDetail;
        let seqStageMasterList: Stage[] = [];
        templateForSequence.forEach((template, index) => {
            if (template.channelName == Channels.SMS && this.locationService.currentLocationDetail.smsEnabled && this.homeService.orgSubscription.smsEnabled) {
                const selectedTemplate = this.smsTemplate.find(dbTemplate => dbTemplate.name == template.templateName && dbTemplate.lang == template.language);
                if (selectedTemplate) {
                    seqStageMasterList.push({
                        bodyContent: selectedTemplate.bodyContent,
                        emailSubject: '',
                        whatsappTemplateName: '',
                        locationId: this.locationService.currentLocationDetail.locationId,
                        seqChannel: Channels.SMS,
                        seqNumber: index + 1,
                        sequenceStageMasterId: null,
                        triggerDelayInDays: index == 0 ? 0 : 1
                    });
                }
            } else if (template.channelName == Channels.EMAIL) {
                const selectedTemplate = this.emailTemplate.find(dbTemplate => dbTemplate.name == template.templateName && dbTemplate.lang == template.language);
                if (selectedTemplate) {
                    seqStageMasterList.push({
                        bodyContent: selectedTemplate.bodyContent,
                        emailSubject: selectedTemplate.emailSubject,
                        whatsappTemplateName: '',
                        locationId: this.locationService.currentLocationDetail.locationId,
                        seqChannel: Channels.EMAIL,
                        seqNumber: index + 1,
                        sequenceStageMasterId: null,
                        triggerDelayInDays: index == 0 ? 0 : 1
                    });
                }
            } else {
                if (this.locationService.currentLocationDetail.whatsappEnabled && this.homeService.orgSubscription.whatsappEnabled) {
                    const selectedTemplate = this.whatsAppTemplate.find(dbTemplate => dbTemplate.name == template.templateName && dbTemplate.lang == template.language);
                    if (selectedTemplate) {
                        seqStageMasterList.push({
                            bodyContent: selectedTemplate.bodyContent,
                            emailSubject: selectedTemplate.emailSubject,
                            whatsappTemplateName: selectedTemplate.name,
                            locationId: this.locationService.currentLocationDetail.locationId,
                            seqChannel: Channels.WHATSAPP,
                            seqNumber: index + 1,
                            sequenceStageMasterId: null,
                            triggerDelayInDays: index == 0 ? 0 : 1
                        });
                    }
                }
            }
        });
        const defaultStagesRequest = {
            sequenceMasterId: sequenceMasterId,
            seqStageMasterList: seqStageMasterList
        }
        this.stageService.addOrUpdateSeqStage(defaultStagesRequest).subscribe({
            next: (res1) => {
                if (res1) {
                    const {
                        seqStageMasterResList,
                        noOfStages,
                        ...rest
                    } = this.sequenceService.sequenceDetail;
                    this.sequenceService.sequenceDetail = {
                        seqStageMasterResList: res1,
                        noOfStages: res1.length,
                        ...rest
                    }
                    this.gotoFinalRoutes();
                }
            },
            error: (error) => {
                console.log(error.error);
                this.goToSequence();
            },
            complete: () => this.isAPICallComplete = true
        });
    }

    getSqInstallURL() {
        this.squareService.getAuthorizeUrl(this.authService.businessUser.orgId).subscribe({
            next: (res: any) => {
                window.open(res.message, '_self');
            },
            error: (error) => {
                console.log(error);
                this.isSetupComplete = true;
                this.snackbarService.showSnackBar(error.error.error);
            }
        })
    }

    goToSequence() {
        this.router.navigate(['/collect-review/sequence']);
    }

    gotoFinalRoutes() {
        if (this.selectedSignUpConfig.finalRoute) {
            this.isSetupComplete = true;
            this.router.navigateByUrl(this.selectedSignUpConfig.finalRoute);
        } else {
            if (this.selectedSignUpConfig.signUpFrom == 'square') {
                this.getSqInstallURL();
            } else {
                this.isSetupComplete = true;
                this.router.navigateByUrl('/dashboard');
            }
        }
    }

    changeCountry($event: MatSelectChange) {
        const selectedCountry = this.countries.find(c => c.countryName == $event.value);
        const form: FormGroup = this.quickSetupForm.controls['setupBusinessForm'] as FormGroup;
        form.controls['phoneNo'].setValidators([Validators.required, PhoneValidator.invalidCountryPhone(selectedCountry.countryCode, selectedCountry.dialCodePrefix)]);
        form.controls['phoneNo'].updateValueAndValidity();
    }

    setSenderId(res: any) {
        if (res.dynamicSmsSenderEnabled) {
            const senderId = res.name.replace(/ /g, "").replace('(', '').replace(')', '').toUpperCase().slice(0, 11);
            const propertiesData = {
                "groupName": PropertyGroup.SMS,
                "locPropertyDetailList": [{
                    "propertyKey": "senderId",
                    "propertyValue": senderId
                }],
                "locationId": res.locationId
            }
            this.settingService.createOrUpdateProperties(propertiesData).subscribe();
        }
    }

    setDefaultSequence(sequenceMasterId: string) {
        const propertiesData = {
            "groupName": PropertyGroup.GENERAL,
            "locPropertyDetailList": [{
                "propertyKey": "preferredSequence",
                "propertyValue": sequenceMasterId
            }],
            "locationId": this.locationService.currentLocationDetail.locationId
        }
        this.settingService.createOrUpdateProperties(propertiesData).subscribe({
            next: (res) => {
                localStorage.setItem('preferredSequence', sequenceMasterId);
            },
            error: (error) => console.log(error.error)
        });
    }


    deleteSite(site: any, siteIndex: number) {
        if (site.isCustom) {
            this.siteLinks.removeAt(siteIndex);
        } else {
            (this.siteLinks.controls[siteIndex] as FormGroup).controls['reviewLink'].setValue('');
            (this.siteLinks.controls[siteIndex] as FormGroup).controls['isConfigured'].setValue(false);
        }
        this.snackBar.open(this.translocoService.translate('Review Site disabled successfully.'), '', {duration: 3000});
        this.setPriorityAfterDelete(site.priority);
    }

    setPriorityAfterDelete(selectedPriority: number) {
        this.siteLinks.value.forEach((reviewSite: { priority: number }, i: string | number) => {
            if (reviewSite.priority > selectedPriority) {
                (this.siteLinks.controls[i] as FormGroup).controls['priority'].setValue(reviewSite.priority - 1);
            }
        });
    }

    setPriority() {
        const form = this.quickSetupForm.controls['reviewSiteForm'] as FormGroup;
        (form.get('links') as FormArray).reset(this.siteLinks.value);
        let priority = 0;
        this.siteLinks.value.forEach((reviewSite: { reviewLink: string }, i: string | number) => {
            if (reviewSite.reviewLink) {
                (this.siteLinks.controls[i] as FormGroup).controls['priority'].setValue(priority + 1);
                priority++;
            }
        });
        let configuredReviewSites = this.siteLinks.value.filter((site: {
            reviewLink: string;
        }) => site.reviewLink && site.reviewLink !== '').length;
        this.siteLinks.value.forEach((reviewSite: { reviewLink: string }, i: string | number) => {
            if (!reviewSite.reviewLink || reviewSite.reviewLink.length == 0) {
                (this.siteLinks.controls[i] as FormGroup).controls['priority'].setValue(configuredReviewSites + 1);
                configuredReviewSites++;
            }
        });
        const sortedSites = this.siteLinks.value.sort((a, b) => a.priority - b.priority);
        while (this.siteLinks.length) {
            this.siteLinks.removeAt(0);
        }
        sortedSites.forEach(site => {
            this.addReviewSite('', site.logoURL, site.name, site.priority, site.reviewLink, site.isCustom);
        });
    }

    saveEmailSettings(locationId: string) {
        const propertiesData = {
            "groupName": PropertyGroup.EMAIL,
            "locPropertyDetailList": [
                {"propertyKey": "fromName", "propertyValue": this.fromName},
                {"propertyKey": "fromEmail", "propertyValue": this.fromEmail},
                {"propertyKey": "replyTo", "propertyValue": this.replyTo},
                {"propertyKey": "feedbackEmail", "propertyValue": this.feedbackEmail}
            ],
            "locationId": locationId,
        }
        this.isAPICallComplete = false;
        this.settingService.createOrUpdateProperties(propertiesData).subscribe();
    }
}
