<div *transloco="let t"
     class="fuse-vertical-navigation-item-wrapper"
     [class.fuse-vertical-navigation-item-has-subtitle]="!!item.subtitle"
     [ngClass]="item.classes?.wrapper">

    <div
            class="fuse-vertical-navigation-item"
            [ngClass]="{'fuse-vertical-navigation-item-active': active,
                    'fuse-vertical-navigation-item-disabled': item.disabled,
                    'fuse-vertical-navigation-item-active-forced': item.active}"
            [matTooltipPosition]="'above'" [matTooltip]="t(item.tooltip) || ''">

        <!-- Icon -->
        <ng-container *ngIf="item.icon">
            <mat-icon
                    class="fuse-vertical-navigation-item-icon"
                    [ngClass]="item.classes?.icon"
                    [svgIcon]="item.icon"></mat-icon>
        </ng-container>

        <!-- Title & Subtitle -->
        <div class="fuse-vertical-navigation-item-title-wrapper">
            <div class="fuse-vertical-navigation-item-title">
                <span [ngClass]="item.classes?.title">
                    {{ t(item.title) }}
                </span>
            </div>
            <ng-container *ngIf="item.subtitle">
                <div class="fuse-vertical-navigation-item-subtitle">
                    <span [ngClass]="item.classes?.subtitle">
                        {{ t(item.subtitle) }}
                    </span>
                </div>
            </ng-container>
        </div>

        <!-- Badge -->
        <ng-container *ngIf="item.badge">
            <div class="fuse-vertical-navigation-item-badge">
                <div
                        class="fuse-vertical-navigation-item-badge-content"
                        [ngClass]="item.badge.classes">
                    {{ t(item.badge.title) }}
                </div>
            </div>
        </ng-container>

    </div>

</div>

<ng-container *ngIf="!skipChildren">

    <div class="fuse-vertical-navigation-item-children">

        <ng-container *ngFor="let item of item.children; trackBy: trackByFn">

            <!-- Skip the hidden items -->
            <ng-container
                    *ngIf="(!isMobileDevice || (isMobileDevice && !item.hideOnMobile)) && ((item.hidden && !item.hidden(item)) || !item.hidden)">
                <!-- Basic -->
                <ng-container *ngIf="item.type === 'basic'">
                    <fuse-vertical-navigation-basic-item
                            [item]="item"
                            [name]="name"></fuse-vertical-navigation-basic-item>
                </ng-container>

                <!-- Collapsable -->
                <ng-container *ngIf="item.type === 'collapsable'">
                    <fuse-vertical-navigation-collapsable-item
                            [item]="item"
                            [name]="name"
                            [autoCollapse]="autoCollapse"></fuse-vertical-navigation-collapsable-item>
                </ng-container>

                <!-- Divider -->
                <ng-container *ngIf="item.type === 'divider'">
                    <fuse-vertical-navigation-divider-item
                            [item]="item"
                            [name]="name"></fuse-vertical-navigation-divider-item>
                </ng-container>

                <!-- Group -->
                <ng-container *ngIf="item.type === 'group'">
                    <fuse-vertical-navigation-group-item
                            [item]="item"
                            [name]="name"></fuse-vertical-navigation-group-item>
                </ng-container>

                <!-- Spacer -->
                <ng-container *ngIf="item.type === 'spacer'">
                    <fuse-vertical-navigation-spacer-item
                            [item]="item"
                            [name]="name"></fuse-vertical-navigation-spacer-item>
                </ng-container>

            </ng-container>

        </ng-container>

    </div>

</ng-container>
