<div *transloco="let t" class="flex flex-col flex-auto min-w-0">
    <div class="flex-auto p-6 sm:p-10 mx-auto md:w-3/4 w-full">
        <div class="mb-8 text-3xl font-extrabold tracking-tight leading-none">{{ t('Setup Your Business') }}</div>
        <div class="flex flex-col lg:flex-row items-center w-full space-y-3 lg:space-y-0 space-x-0 lg:space-x-3"
             *ngIf="isSetupComplete">
            <form [formGroup]="quickSetupForm" class="sm:p-4 w-full bg-card shadow rounded-2xl overflow-hidden">
                <mat-stepper #matStepper [linear]="true" [orientation]="innerWidth > 767 ? 'horizontal' : 'vertical'">
                    <mat-step #matStepperStep1 [formGroupName]="'searchBusinessForm'"
                              [stepControl]="quickSetupForm.get('searchBusinessForm')">
                        <ng-template matStepLabel>{{ t('Search Business') }}</ng-template>
                        <p class="my-2 font-medium">
                            {{ t('Fill in your Business Details as per your Google Profile') }}
                        </p>
                        <div class="flex flex-col md:flex-row justify-center ml-3 md:ml-0">
                            <mat-form-field class="flex-auto">
                                <input #businessName [formControlName]="'name'" [placeholder]="t('Business Name')"
                                       matInput>
                                <mat-icon [svgIcon]="'heroicons_solid:archive'" class="icon-size-5"
                                          matPrefix></mat-icon>
                                <mat-error>{{ t('Business Name is required') }}</mat-error>
                            </mat-form-field>
                            <mat-form-field class="flex-auto ml-0 md:ml-2">
                                <input #cityName [formControlName]="'place'" [placeholder]="t('City Name')" matInput>
                                <mat-icon [svgIcon]="'heroicons_solid:location-marker'" class="icon-size-5"
                                          matPrefix></mat-icon>
                                <mat-error>{{ t('City Name is required') }}</mat-error>
                            </mat-form-field>
                            <button (click)="searchBusiness()" [color]="'primary'"
                                    [disabled]="matStepperStep1.stepControl.pristine || !businessName.value || !cityName.value || isBusinessSearching"
                                    class="ml-0 md:ml-2 mt-1" mat-flat-button type="button">
                                <mat-icon *ngIf="!isBusinessSearching" [svgIcon]="'heroicons_solid:search'"
                                          class="icon-size-5" matPrefix></mat-icon>
                                <div *ngIf="isBusinessSearching"
                                     class="spinner-border animate-spin inline-block w-4 h-4 border-4 rounded-full text-blue-600"
                                     role="status">
                                </div>
                            </button>
                        </div>
                        <div *ngIf="isBusinessSearch" class="w-full md:w-[calc(100%-60px)] ml-3 md:ml-0">
                            <div *ngIf="googlePlaces && googlePlaces.length"
                                 class="flex flex-col md:flex-row flex-wrap">
                                <div *ngFor="let business of googlePlaces;let i=index;"
                                     class="w-full md:w-1/2 pr-[15px] md:pr-[10px]">
                                    <fuse-card (click)="selectedBusinessIndex=i;"
                                               class="flex items-center mt-4 w-full md:min-h-[92%] p-2 filter-info hover:cursor-pointer">
                                        <img [alt]="t('Logo')" src=""
                                             class="w-12 h-12 min-w-12 min-h-12 mr-4 sm:w-16 sm:h-16 sm:min-w-16 sm:min-h-16 sm:mr-6 rounded-full">
                                        <div class="flex flex-col">
                                            <div class="text-xl sm:text-2xl font-semibold leading-tight">{{ business.name }}</div>
                                            <div class="text-md leading-tight text-primary pr-10">{{ business.formatted_address }}</div>
                                            <div class="text-md text-secondary mt-1"></div>
                                            <mat-icon *ngIf="selectedBusinessIndex==i"
                                                      class="absolute right-2 bottom-2 text-green-700 font-bold scale-150"
                                                      svgIcon="heroicons_outline:check-circle"></mat-icon>
                                        </div>
                                    </fuse-card>
                                </div>
                            </div>

                            <div class="flex flex-col sm:flex-row justify-between pr-[15px] md:pr-0 mt-2">
                                <button (click)="addManually(matStepper)" class="px-8 mt-5"
                                        mat-stroked-button type="button">
                                    {{ t('Not Found. Add Business Details Manually') }}
                                </button>
                                <button (click)="selectBusiness(matStepper)" [color]="'primary'"
                                        [disabled]="selectedBusinessIndex<0" class="px-8 mt-5" mat-flat-button
                                        type="button">
                                    {{ t('Next Step') }}
                                </button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step #matStepperStep2 [formGroupName]="'setupBusinessForm'"
                              [stepControl]="quickSetupForm.get('setupBusinessForm')">
                        <ng-template matStepLabel>{{ t('Setup Business') }}</ng-template>
                        <p class="my-2 font-medium">{{ t('Add Business Details') }}</p>
                        <div class="grid sm:grid-col-1 md:grid-cols-2 md:gap-1 ml-3 md:ml-0">
                            <mat-form-field class="flex-auto">
                                <input [formControlName]="'name'" [placeholder]="t('Business Name')" matInput>
                                <mat-icon [svgIcon]="'heroicons_solid:archive'" class="icon-size-5"
                                          matPrefix></mat-icon>
                                <mat-error>{{ t('Business Name is required') }}</mat-error>
                            </mat-form-field>
                            <mat-form-field class="flex-auto">
                                <input [formControlName]="'address'" [placeholder]="t('Address')" matInput>
                                <mat-icon [svgIcon]="'heroicons_solid:location-marker'" class="icon-size-5"
                                          matPrefix></mat-icon>
                            </mat-form-field>
                            <mat-form-field class="flex-auto">
                                <input [formControlName]="'phoneNo'" [placeholder]="t('Phone')" matInput>
                                <mat-icon [svgIcon]="'heroicons_solid:phone'" class="icon-size-5"
                                          matPrefix></mat-icon>
                                <mat-error
                                        *ngIf="quickSetupForm.get('setupBusinessForm').get('phoneNo').hasError('invalidCountryPhone')">
                                    {{ t('Enter Valid Phone Number') }}.
                                </mat-error>
                                <mat-error
                                        *ngIf="quickSetupForm.get('setupBusinessForm').get('phoneNo').hasError('required')">
                                    {{ t('Phone Number is required.') }}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="flex-auto">
                                <input [formControlName]="'city'" [placeholder]="t('City Name')" matInput>
                                <mat-icon [svgIcon]="'heroicons_solid:archive'" class="icon-size-5"
                                          matPrefix></mat-icon>
                                <mat-error>{{ t('City Name is required') }}.</mat-error>
                            </mat-form-field>
                            <mat-form-field class="flex-auto">
                                <mat-icon [svgIcon]="'heroicons_solid:flag'" class="icon-size-5"
                                          matPrefix></mat-icon>
                                <mat-select [formControlName]="'country'" [placeholder]="t('Select Country')"
                                            (selectionChange)="changeCountry($event)">
                                    <mat-option *ngFor="let country of countries;"
                                                [value]="country.countryName">{{ country.countryName }}
                                    </mat-option>
                                </mat-select>
                                <mat-error>{{ t('Country Name is required') }}</mat-error>
                            </mat-form-field>
                            <mat-form-field class="flex-auto">
                                <input [formControlName]="'businessType'" [placeholder]="t('Business Type')" matInput>
                                <mat-icon [svgIcon]="'heroicons_solid:cube'" class="icon-size-5"
                                          matPrefix></mat-icon>
                            </mat-form-field>
                            <div class="w-full">
                                <mat-form-field class="flex-auto w-full">
                                    <input [formControlName]="'website'" [placeholder]="t('Website')" matInput>
                                    <mat-icon [svgIcon]="'heroicons_solid:link'" class="icon-size-5"
                                              matPrefix></mat-icon>
                                    <mat-error>{{ t('Website URL is invalid.') }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="w-full">
                                <mat-form-field class="flex-auto w-full">
                                    <input [(ngModel)]="fromName" [placeholder]="t('From Name')"
                                           class="text-gray-400" readonly [ngModelOptions]="{standalone: true}"
                                           matInput>
                                    <mat-icon [svgIcon]="'heroicons_solid:user'" class="icon-size-5"
                                              matPrefix></mat-icon>
                                    <mat-hint>{{ t('From Name') }} *</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="w-full">
                                <mat-form-field class="flex-auto w-full">
                                    <input [(ngModel)]="fromEmail" [placeholder]="t('From Email')"
                                           class="text-gray-400" readonly [ngModelOptions]="{standalone: true}"
                                           matInput>
                                    <mat-icon [svgIcon]="'heroicons_solid:mail'" class="icon-size-5"
                                              matPrefix></mat-icon>
                                    <mat-hint>{{ t('From Email') }} *</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="w-full">
                                <mat-form-field class="flex-auto w-full">
                                    <input [(ngModel)]="replyTo" [placeholder]="t('ReplyTo Email')"
                                           class="text-gray-400" readonly [ngModelOptions]="{standalone: true}"
                                           matInput>
                                    <mat-icon [svgIcon]="'heroicons_solid:mail'" class="icon-size-5"
                                              matPrefix></mat-icon>
                                    <mat-hint>{{ t('ReplyTo Email') }} *</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="w-full">
                                <mat-form-field class="flex-auto w-full">
                                    <input [(ngModel)]="feedbackEmail" [placeholder]="t('Feedback Email')"
                                           class="text-gray-400" readonly [ngModelOptions]="{standalone: true}"
                                           matInput>
                                    <mat-icon [svgIcon]="'heroicons_solid:mail'" class="icon-size-5"
                                              matPrefix></mat-icon>
                                    <mat-hint>{{ t('Feedback Email') }} *</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="flex items-center space-x-5 md:space-x-10 px-0 md:px-10 justify-center mb-5 md:mb-0 border-2 py-2 rounded-2xl border-dashed mx-1 w-full md:w-1/2"
                             appDragAndDrop (files)="filesDropped($event)">
                            <img [src]="isLogoExist ? quickSetupForm.value.setupBusinessForm.logoImageUrl:'assets/images/logo/default_logo.png'"
                                 alt="" class="h-20 w-20 rounded-full">
                            <div>
                                <div class="w-full text-center font-bold hover:cursor-pointer"
                                     (click)="imageFileInput.click()">
                                    <div>{{ isLogoExist ? t('Change your Logo') : t('Drag or Upload your Logo here') }}</div>
                                </div>
                                <input type="file" accept="image/*" (change)="onFileSelect($event)"
                                       #imageFileInput class="hidden"/>
                            </div>
                        </div>
                        <div class="text-slate-400 mt-2">
                            * {{ t('You can modify these values in the Advanced Settings - Under Custom Email Provider') }}
                        </div>
                        <div class="flex justify-between items-center mt-8 ml-2 md:ml-0">
                            <button class="px-8" mat-stroked-button matStepperPrevious type="button">
                                {{ t('Back') }}
                            </button>
                            <button (click)="getReviewSiteLinks(matStepper)" [color]="'primary'"
                                    [disabled]="matStepperStep2.stepControl.invalid || !validURL(quickSetupForm.value.setupBusinessForm.website) || !isAPICallComplete"
                                    class="px-8" mat-flat-button type="button"> {{ t('Continue') }}
                            </button>
                        </div>
                    </mat-step>
                    <mat-step [formGroupName]="'reviewSiteForm'" [stepControl]="quickSetupForm.get('reviewSiteForm')">
                        <ng-template matStepLabel>{{ t('Review Sites') }}</ng-template>
                        <div class="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-x-5">
                            <div class="w-full col-span-1 md:col-span-2">
                                <div class="text-xl font-bold mb-1">{{ t('Choose Review Site') }}</div>
                                <div class="p-3 border rounded-2xl">
                                    <div class="grid flex-col divide-y items-center h-auto md:h-72 overflow-auto gap-2"
                                         [ngClass]="innerWidth>1920 ? 'grid-cols-4': innerWidth>1430 && innerWidth<=1920 ? 'grid-cols-3' : innerWidth>600 && innerWidth<=1430 ? 'grid-cols-2':'grid-cols-1'"
                                         formArrayName="links">
                                        <fuse-card (click)="openDialog()" [matTooltip]="t('Add Custom Review Site')"
                                                   [matTooltipPosition]="'above'"
                                                   class="flex items-center border p-2 filter-info w-full h-12 min-h-12 cursor-pointer">
                                                             <span class="mr-4 w-6 h-6 min-w-6 min-h-6 md:w-8 md:h-8 md:min-w-8 md:min-h-8 bg-blue-700 text-2xl font-bold text-white flex justify-center items-center rounded-full"
                                                             >C</span>
                                            <div class="text-md md:text-xl font-semibold leading-tight"> {{ t('Custom') }}</div>
                                        </fuse-card>
                                        <ng-container *ngFor="let site of siteLinks.controls;let i=index;"
                                                      [formGroupName]="i">
                                            <fuse-card (click)="openDialog(site.value,i)" [matTooltip]="site.value.name"
                                                       [matTooltipPosition]="'above'" [id]="'site_{{i}}'"
                                                       *ngIf="!site.value.reviewLink"
                                                       class="flex items-center border p-2 filter-info w-full h-12 min-h-12 cursor-pointer">
                                                <img class="w-6 h-6 min-w-6 min-h-6 md:w-8 md:h-8 md:min-w-8 md:min-h-8 mr-4 rounded-full"
                                                     [src]="site.value.logoURL" [alt]="t('Logo')"
                                                     *ngIf="!site.value.isCustom">
                                                <div class="text-md md:text-xl font-semibold leading-tight">
                                                    {{ site.value.name }}
                                                </div>
                                            </fuse-card>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full">
                                <div class="text-xl font-bold mb-1">{{ t('My Review Site') }}</div>
                                <div class="p-3 border rounded-2xl">
                                    <div class="flex flex-col space-y-2 divide-y items-center h-auto md:h-72 overflow-auto pb-1"
                                         cdkDropList (cdkDropListDropped)="drop($event)" formArrayName="links">
                                        <ng-container *ngFor="let site of siteLinks.controls;let i=index;"
                                                      [formGroupName]="i">
                                            <fuse-card
                                                    class="items-center border p-2 filter-info w-full flex h-12 min-h-12"
                                                    [id]="'site_{{i}}'" cdkDrag *ngIf="site.value.reviewLink">
                                                <mat-icon
                                                        [ngClass]="site.value.reviewLink ? 'text-green-600' : 'text-red-600'"
                                                        [matTooltip]="t('Change Priority')"
                                                        [svgIcon]="'heroicons_solid:menu'"
                                                        class="icon-size-5 text-hint text-xl font-bold cursor-move m-1"
                                                        cdkDragHandle></mat-icon>
                                                <span class="mr-0 md:mr-6 w-6 h-6 min-w-6 min-h-6 md:w-8 md:h-8 md:min-w-8 md:min-h-8 bg-blue-700 uppercase text-2xl font-bold text-white flex justify-center items-center rounded-full"
                                                      *ngIf="site.value.isCustom">{{ site.value.name[0] }}</span>
                                                <img class="w-6 h-6 min-w-6 min-h-6 md:w-8 md:h-8 md:min-w-8 md:min-h-8 mr-0 md:mr-6 rounded-full"
                                                     [src]="site.value.logoURL" [alt]="t('Logo')"
                                                     (click)="openDialog(site.value,i)" *ngIf="!site.value.isCustom">
                                                <div class="flex justify-between w-full items-center">
                                                    <div class="text-md md:text-xl font-semibold leading-tight mr-2 md:mr-0"
                                                         [matTooltip]="site.value.reviewLink?'':'Not Configured'"
                                                         [matTooltipPosition]="'above'"
                                                         (click)="openDialog(site.value,i)">{{ site.value.name }}
                                                    </div>
                                                    <div class="flex items-center space-x-1 mb-2">
                                                        <div class="font-bold w-4 md:w-5 h-4 md:h-5 bg-gray-400 text-white flex justify-center items-center rounded-full p-1 mt-2 md:mt-1"
                                                             matTooltip="Priority {{ site.value.priority }}"
                                                             [matTooltipPosition]="'above'">{{ site.value.priority }}
                                                        </div>
                                                        <button [matTooltip]="t('Edit Review Sites')"
                                                                [matTooltipPosition]="'above'" type="button"
                                                                class="font-bold icon-size-4 md:icon-size-5"
                                                                (click)="openDialog(site.value,i)"
                                                                *ngIf="!isMobileDevice">
                                                            <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                                                        </button>
                                                        <button matTooltip="{{t('Delete Review Site')}}"
                                                                [matTooltipPosition]="'above'" type="button"
                                                                class="font-bold icon-size-4 md:icon-size-5"
                                                                [disabled]="site.value.name == 'Google' || !site.value.reviewLink"
                                                                (click)="deleteSite(site.value,i)">
                                                            <mat-icon class="text-red-400"
                                                                      svgIcon="heroicons_outline:trash"></mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </fuse-card>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-between mt-8 ml-2 md:ml-0">
                            <button class="px-8 " mat-stroked-button matStepperPrevious type="button">
                                {{ t('Back') }}
                            </button>
                            <button (click)="showSummary(matStepper)" [color]="'primary'"
                                    [disabled]="isReviewSiteInValid()" class="px-8" mat-flat-button
                                    type="button"> {{ t('Proceed') }}
                            </button>
                        </div>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>{{ t('Done') }}</ng-template>
                        <h1 class="text-2xl font-bold my-2">{{ t('Business Information') }}</h1>
                        <div class="flex w-full items-center justify-center rounded-xl">
                            <div class="flex flex-col md:flex-row justify-between w-full shadow border rounded-xl p-5 overflow-auto items-center md:items-start">
                                <div class="w-full lg:w-[calc(100%-88px)]">
                                    <div class="flex flex-col md:flex-row mb-3 md:mb-0">
                                        <span class="w-full md:w-40 md:min-w-[10rem]">{{ t('Business Name') }}</span>
                                        <span class="font-bold overflow-auto break-words"
                                              [style]="{'text-shadow': '0 0 black'}">{{ quickSetupForm.value.setupBusinessForm.name }}</span>
                                    </div>
                                    <div class="flex flex-col md:flex-row mb-3 md:mb-0">
                                        <span class="w-full md:w-40 md:min-w-[10rem]">{{ t('Business Address') }}</span>
                                        <span class="font-bold overflow-auto break-words"
                                              [style]="{'text-shadow': '0 0 black'}">{{ quickSetupForm.value.setupBusinessForm.address }}</span>
                                    </div>
                                    <div class="flex flex-col md:flex-row mb-3 md:mb-0">
                                        <span class="w-full md:w-40 md:min-w-[10rem]">{{ t('City Name') }}</span>
                                        <span class="font-bold overflow-auto break-words"
                                              [style]="{'text-shadow': '0 0 black'}">{{ quickSetupForm.value.setupBusinessForm.city }}</span>
                                    </div>
                                    <div class="flex flex-col md:flex-row mb-3 md:mb-0">
                                        <span class="w-full md:w-40 md:min-w-[10rem]">{{ t('Business Type') }}</span>
                                        <span class="font-bold overflow-auto break-words"
                                              [style]="{'text-shadow': '0 0 black'}">{{ quickSetupForm.value.setupBusinessForm.businessType }}</span>
                                    </div>
                                    <div class="flex flex-col md:flex-row">
                                        <span class="w-full md:w-40 md:min-w-[10rem]">{{ t('Website') }}</span>
                                        <span class="font-bold overflow-auto break-words"
                                              [style]="{'text-shadow': '0 0 black'}">{{ quickSetupForm.value.setupBusinessForm.website }}</span>
                                    </div>
                                    <div class="flex flex-col md:flex-row">
                                        <span class="w-full md:w-40 md:min-w-[10rem]">{{ t('From Name') }}</span>
                                        <span class="font-bold overflow-auto break-words"
                                              [style]="{'text-shadow': '0 0 black'}">{{ fromName }}</span>
                                    </div>
                                    <div class="flex flex-col md:flex-row">
                                        <span class="w-full md:w-40 md:min-w-[10rem]">{{ t('From Email') }}</span>
                                        <span class="font-bold overflow-auto break-words"
                                              [style]="{'text-shadow': '0 0 black'}">{{ fromEmail }}</span>
                                    </div>
                                    <div class="flex flex-col md:flex-row">
                                        <span class="w-full md:w-40 md:min-w-[10rem]">{{ t('ReplyTo Email') }}</span>
                                        <span class="font-bold overflow-auto break-words"
                                              [style]="{'text-shadow': '0 0 black'}">{{ replyTo }}</span>
                                    </div>
                                    <div class="flex flex-col md:flex-row">
                                        <span class="w-full md:w-40 md:min-w-[10rem]">{{ t('Feedback Email') }}</span>
                                        <span class="font-bold overflow-auto break-words"
                                              [style]="{'text-shadow': '0 0 black'}">{{ feedbackEmail }}</span>
                                    </div>
                                </div>
                                <div class="my-5 lg:mx-5 lg:my-0">
                                    <img [src]="isLogoExist ? quickSetupForm.value.setupBusinessForm.logoImageUrl:'assets/images/logo/default_logo.png'"
                                         alt="" class="w-12 h-12 min-h-12 min-w-12 rounded-full">
                                </div>
                            </div>
                        </div>
                        <h1 class="text-2xl font-bold mt-3">{{ t('Review Links') }}</h1>
                        <div class="grid mt-2 gap-1"
                             [ngClass]="innerWidth>1920 ? 'grid-cols-4': innerWidth>1430 && innerWidth<=1920 ? 'grid-cols-3' : innerWidth>600 && innerWidth<=1430 ? 'grid-cols-2':'grid-cols-1'">
                            <fuse-card (click)="testReviewLink(i,link)" *ngFor="let link of links;let i=index;"
                                       [matTooltipPosition]="'above'" matTooltipHideDelay="100"
                                       matTooltipShowDelay="100"
                                       class="items-center border p-2 filter-info hover:cursor-pointer"
                                       matTooltip="{{t('Test')}} {{ link.name }} {{t('Review Link')}}">
                                <img *ngIf="!link.isCustom" [alt]="t('Logo')" [src]="link.logoURL"
                                     class="w-8 h-8 min-w-8 min-h-8 mr-5 rounded-full">
                                <span *ngIf="link.isCustom"
                                      class="w-8 h-8 min-w-8 min-h-8 mr-5 bg-blue-700 uppercase text-3xl font-bold text-white flex justify-center items-center rounded-full"
                                >{{ link.name[0] }}</span>
                                <div class="flex justify-between items-center w-full">
                                    <div class="text-xl md:text-2xl font-semibold leading-tight">{{ link.name }}</div>
                                    <a [href]="link.reviewLink" class="font-bold scale-100 md:scale-125 flex"
                                       target="_blank" title="{{t('Test Link')}}">
                                        <mat-icon svgIcon="heroicons_outline:external-link"
                                                  class="w-[18px] h-[18px] min-w-[18px] min-h-[18px] text-[18px] leading-[18px]"></mat-icon>
                                    </a>
                                </div>
                            </fuse-card>
                        </div>
                        <div class="flex justify-between mt-8">
                            <button class="px-8" mat-stroked-button matStepperPrevious type="button">
                                {{ t('Back') }}
                            </button>
                            <button (click)="createAndAttachUser()" [color]="'primary'" [disabled]="loading"
                                    class="px-8" mat-flat-button type="button"> {{ t('Confirm') }}
                            </button>
                        </div>
                    </mat-step>
                </mat-stepper>
            </form>
            <div *ngIf="showNextSetup && selectedSignUpConfig && !selectedSignUpConfig.setupDefaultSequence"
                 class="flex justify-center flex-col items-center p-5 space-y-3 bg-card border w-full lg:w-72 rounded-2xl shadow divide-y">
                <h1 class="font-bold">{{ t('Next Step') }}</h1>
                <div class="py-2 px-3 font-semibold text-purple-800 text-center">{{ t('Setup your Sequences') }}
                </div>
            </div>
        </div>
        <div class="flex justify-center items-center h-80 flex-col mx-auto w-full rounded-2xl bg-card"
             *ngIf="!isSetupComplete">
            <mat-spinner></mat-spinner>
            <div class="text-xl font-semibold text-center">{{ t('Please wait while we set your business.') }}</div>
        </div>
    </div>
</div>
<br>

<ng-template #customLinkDialog *transloco="let t">
    <div class="flex flex-col  max-h-screen -m-6">
        <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
            <div class="text-lg font-medium">{{ t('Add Custom Review Site') }}</div>
            <button (click)="closeDialog()" [tabIndex]="-1" mat-icon-button>
                <mat-icon [svgIcon]="'heroicons_outline:x'" class="text-current"></mat-icon>
            </button>
        </div>
        <form [formGroup]="customReviewForm" class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto">
            <mat-form-field>
                <mat-label>{{ t('Review Site Name') }}</mat-label>
                <input [formControlName]="'name'" [placeholder]="t('Review Site Name')"
                       [readonly]="!canEditSiteName" [autofocus]="canEditSiteName" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ t('Review Site Link') }}</mat-label>
                <input [formControlName]="'link'" [autofocus]="!canEditSiteName" [placeholder]="t('Review Site Link')"
                       matInput>
            </mat-form-field>
            <div class="flex justify-between">
                <button (click)="closeDialog()" mat-stroked-button>{{ t('Close') }}</button>
                <button type="button" (click)="addCustomSite()" [disabled]="customReviewForm.invalid"
                        [color]="'primary'" mat-flat-button>
                    {{ t('Add') }}
                </button>
            </div>
        </form>
    </div>
</ng-template>
<div class="hidden" id="map"></div>
<ng-template #newBusinessSetup *transloco="let t">
    <div class="flex flex-col sm:w-80 max-h-screen items-center">
        <img alt="" class="w-44" src="assets/images/logo/logo.svg">
        <div class="font-bold text-2xl">{{ t('Welcome') }}!</div>
        <div class="mt-2 text-center">{{ t('First Time Setup') }}</div>
        <button (click)="closeDialog()" [color]="'primary'" class="mt-3" mat-flat-button type="button">
            Let's get you set up
        </button>
    </div>
</ng-template>
