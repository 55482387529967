<button mat-icon-button [matMenuTriggerFor]="userActions" class="-mr-3">
    <span class="relative">
         <div class="w-8 h-8">
             <span class="w-8 h-8 bg-blue-700 uppercase font-bold text-lg text-white flex justify-center items-center rounded-full"
             >{{user.fullName && user.fullName[0] }}</span>
         </div>
    </span>
</button>
<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span *transloco="let t">{{t('Signed In As')}}</span>
            <span class="mt-1.5 text-md font-medium">{{user.emailAddress}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="deleteUser()" *ngIf="isAppleDevice">
        <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
        <span *transloco="let t">{{t('Delete User')}}</span>
    </button>
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span *transloco="let t">{{t('Sign Out')}}</span>
    </button>
</mat-menu>
