import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {FuseFullscreenModule} from '@fuse/components/fullscreen';
import {FuseLoadingBarModule} from '@fuse/components/loading-bar';
import {FuseNavigationModule} from '@fuse/components/navigation';
import {LanguagesModule} from 'app/layout/common/languages/languages.module';
import {ShortcutsModule} from 'app/layout/common/shortcuts/shortcuts.module';
import {UserModule} from 'app/layout/common/user/user.module';
import {SharedModule} from 'app/shared/shared.module';
import {CompactLayoutComponent} from 'app/layout/layouts/vertical/compact/compact.component';
import {MatRippleModule} from "@angular/material/core";
import {TranslocoModule} from '@ngneat/transloco';
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NewFeaturesModule} from "../../../common/new-features/new-features.module";
import {DashboardModule} from "../../../../modules/admin/dashboard/dashboard.module";
import {MatDialogModule} from "@angular/material/dialog";
import {FuseCardModule} from "../../../../../@fuse/components/card";

@NgModule({
    declarations: [
        CompactLayoutComponent
    ],
    imports: [
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        FuseFullscreenModule,
        FuseLoadingBarModule,
        FuseNavigationModule,
        LanguagesModule,
        ShortcutsModule,
        UserModule,
        SharedModule,
        MatRippleModule,
        TranslocoModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatInputModule,
        NewFeaturesModule,
        DashboardModule,
        MatDialogModule,
        FuseCardModule
    ],
    exports: [
        CompactLayoutComponent
    ]
})
export class CompactLayoutModule {
}
