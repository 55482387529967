import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {ProviderResponse} from "../models/provider-response";
import {ProviderConfig, ProviderConfigResponse} from "../models/provider-config";
import {AIPrompt} from "../models/AIPrompt";

@Injectable({
    providedIn: 'root'
})
export class LTDService {

    constructor(private http: HttpClient) {
    }

    get providers() {
        return this.getLocalStorage('providers');
    }

    set providers(providerResponses: ProviderResponse[]) {
        localStorage.setItem('providers', JSON.stringify(providerResponses));
    }

    get providerConfig() {
        return this.getLocalStorage('provider_config');
    }

    set providerConfig(provider_config: ProviderConfig[]) {
        localStorage.setItem('provider_config', JSON.stringify(provider_config));
    }

    getProviderConfig() {
        return this.http.get<ProviderConfigResponse>(`${environment.BASE_URL}provider-config/properties`);
    }

    getProvidersForOrg(orgId: string) {
        return this.http.get<ProviderResponse[]>(`${environment.BASE_URL}provider-config/${orgId}`);
    }

    setProvider(providerData: any) {
        return this.http.put(`${environment.BASE_URL}provider-config`, providerData);
    }


    getLocalStorage(key: string): any {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (e) {
            console.error('Error getting data local storage Data', e);
            return null;
        }
    }


    testEmail(data: any) {
        return this.http.post(`${environment.BASE_URL}provider-config/testEmail`, data);
    }

    testSMS(orgId: string, businessName: string, customerName: string, phone: string, locationId: string) {
        return this.http.post(`${environment.BASE_URL}provider-config/testSMS`, {
            orgId, businessName, customerName, phone, locationId
        });
    }

    getAIPrompts() {
        return this.http.get<AIPrompt[]>(`${environment.BASE_URL}openAI/getAIPrompts`);
    }

}
