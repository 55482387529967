import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {LocationProperty} from "../models/location-property";

@Injectable({
    providedIn: 'root'
})
export class SettingService {

    constructor(private http: HttpClient) {
    }

    createOrUpdateProperties(propertiesData: any) {
        return this.http.post(`${environment.BASE_URL}locationProperty/createOrUpdateProperties`, propertiesData);
    }

    getAllProperties(locationId: string, groupName: string) {
        return this.http.get<LocationProperty[]>(`${environment.BASE_URL}locationProperty/getAllProperties/${locationId}/${groupName}`);
    }

    uploadMMSMedia(reqObj: any) {
        return this.http.post(`${environment.BASE_URL}locationProperty/uploadMMSMedia`, reqObj);
    }

    getMMSDataList(locationId: string) {
        return this.http.get(`${environment.BASE_URL}locationProperty/getProperty/${locationId}/mediaLinks`);
    }

}
