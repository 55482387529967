import {Component, OnInit} from '@angular/core';
import {ContactPayload, Contacts} from "@capacitor-community/contacts";
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-contact-list',
    templateUrl: './contact-list.component.html'
})
export class ContactListComponent implements OnInit {
    isContactFetched = false;
    contacts: ContactPayload[] = [];
    roweContacts: ContactPayload[] = [];

    ngOnInit() {
        this.printContactsData().then((value) => {
            this.isContactFetched = true;
            console.log(value);
        }, reason => {
            this.isContactFetched = true;
            console.log(reason);
        });
    }

    constructor(private dialogRef: MatDialogRef<ContactListComponent>) {
    }

    async printContactsData() {
        const result = await Contacts.getContacts({
            projection: {
                // Specify which fields should be retrieved.
                name: true,
                phones: true,
                emails: true,
                image: true
            },
        });
        this.contacts = result.contacts;
        const namedContact = this.contacts.filter(c => c.name != null);
        const unNamedContact = this.contacts.filter(c => c.name == null);
        this.contacts = [...namedContact, ...unNamedContact];
        this.roweContacts = this.contacts;
    };

    closeDialog(contact: ContactPayload | null = null) {
        this.dialogRef.close(contact);
    }

    searchContact(searchText: string) {
        if (searchText) {
            this.contacts = this.roweContacts.filter(contact => {
                let nameExist = false;
                let numberExist = false;
                let emailExist = false;
                if (contact.name && contact.name.display) {
                    nameExist = contact.name.display.toLowerCase().includes(searchText.toLowerCase());
                }
                if (contact.phones && contact.phones.length && contact.phones[0].number) {
                    numberExist = contact.phones[0].number.toLowerCase().includes(searchText.toLowerCase());
                }
                if (contact.emails && contact.emails.length && contact.emails[0].address) {
                    emailExist = contact.emails[0].address.toLowerCase().includes(searchText.toLowerCase());
                }
                return nameExist || numberExist || emailExist;
            });
        } else {
            this.contacts = this.roweContacts;
        }
    }
}
