import {AbstractControl, ValidatorFn} from '@angular/forms';
import libphonenumber from 'google-libphonenumber';

export class PhoneValidator {


    static invalidCountryPhone = (countryCode: string, dialCodePrefix: string): ValidatorFn => {
        return (phoneControl: AbstractControl): { [key: string]: boolean } => {
            if (phoneControl.value) {
                try {
                    let phoneNo = phoneControl.value + '';
                    if (phoneNo.includes('+')) {
                        if (phoneNo.includes(dialCodePrefix)) {
                            phoneNo = phoneNo.replace(dialCodePrefix, '').trim();
                        } else {
                            return {
                                invalidCountryPhone: true
                            };
                        }

                    }
                    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
                    const phoneNumber = '' + phoneNo + '',
                        region = countryCode,
                        number = phoneUtil.parse(phoneNumber, region),
                        isValidNumber = phoneUtil.isValidNumber(number);
                    if (isValidNumber) {
                        return null;
                    }
                } catch (e) {
                    return {
                        invalidCountryPhone: true
                    };
                }
                return {
                    invalidCountryPhone: true
                };
            } else {
                return null;
            }
        };
    }
}
