import {Component, OnInit} from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';
import {CommonService} from './shared/services/common.service';
import {fromEvent, merge, Observable, Observer} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {environment} from "../environments/environment";
import {HomeService} from "./modules/admin/dashboard/services/home.service";
import {NavigationEnd, Router} from "@angular/router";
import {ChatboxColors, Crisp} from "crisp-sdk-web";

declare let ClarityPlugin: any;
declare var gtag;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    browserLanguage: string = '';
    availableLanguages: Array<any> = [];
    /**
     * Constructor
     */
    isOnline = false;

    constructor(private translocoService: TranslocoService,
                private homeService: HomeService,
                private commonService: CommonService,
                private router: Router) {
        this.availableLanguages = this.translocoService.getAvailableLangs();
        this.browserLanguage = this.commonService.getBrowserLanguage();
        if (this.availableLanguages.find(lang => lang.id == this.browserLanguage)) {
            this.browserLanguage = this.translocoService.getDefaultLang();
        }
        this.translocoService.setActiveLang(this.browserLanguage);
        const navEndEvent$ = router.events.pipe(
            filter(e => e instanceof NavigationEnd)
        );
        navEndEvent$.subscribe((e: NavigationEnd) => {
            gtag('config', 'G-6FW6EKPEZV', {'page_path': e.urlAfterRedirects});
        });
        if (environment.app_url.includes(location.hostname)) {
            Crisp.configure(environment.crisp_website_id, {safeMode: true, autoload: true});
            Crisp.setColorTheme(ChatboxColors.DeepPurple);
        }
    }

    checkNetworkStatus$() {
        return merge(
            fromEvent(window, 'offline').pipe(map(() => false)),
            fromEvent(window, 'online').pipe(map(() => true)),
            new Observable((sub: Observer<boolean>) => {
                sub.next(navigator.onLine);
                sub.complete();
            }));
    }

    ngOnInit() {
        this.checkNetworkStatus$().subscribe(isOnline => this.isOnline = isOnline);
        if (this.homeService.isNativeDevice) {
            ClarityPlugin.initialize(environment.clarity_project_id, this.success, this.failure, true);
        }
    }

    success(message: string) {
        console.log(message);
    }

    failure(error: string) {
        console.log(error);
    }
}
