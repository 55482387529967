import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {SquareConnection} from "../models/square-connection";
import {SquareLocation} from "../models/square-location";

@Injectable({
    providedIn: 'root'
})
export class SquareService {

    constructor(private http: HttpClient) {
    }

    getAuthorizeUrl(orgId: string) {
        return this.http.get(`${environment.BASE_URL}square/getAuthorizeUrl/${orgId}`);
    }

    getAllSquareLocations(orgId: string) {
        return this.http.get<SquareLocation[]>(`${environment.BASE_URL}square/getAllSquareLocations/${orgId}`);
    }

    getAllConnections(orgId: string) {
        return this.http.get<SquareConnection[]>(`${environment.BASE_URL}square/getAllConnections/${orgId}`);
    }

    updateConnection(squareConnection: SquareConnection) {
        return this.http.put<SquareConnection[]>(`${environment.BASE_URL}square/updateConnection`, squareConnection);
    }

    createConnection(squareConnection: SquareConnection) {
        return this.http.post<SquareConnection[]>(`${environment.BASE_URL}square/createConnection`, squareConnection);
    }

    disconnectConnection(orgId: String) {
        return this.http.delete(`${environment.BASE_URL}square/disconnectConnection/${orgId}`);
    }

    verifyOrgAuthenticationStatus(orgId: String) {
        return this.http.get(`${environment.BASE_URL}square/verifyOrgAuthenticationStatus/${orgId}`);
    }
}
