import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from '@angular/router';
import {Observable, of, switchMap} from 'rxjs';
import {AuthService} from 'app/core/auth/auth.service';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    /**
     * Constructor
     */

    constructor(
        private authService: AuthService,
        private router: Router
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl, route);
    }


    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl, childRoute);
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this._check('/');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private _check(redirectURL: string, route: ActivatedRouteSnapshot = null): Observable<boolean> {
        // Check the authentication status
        return this.authService.check()
            .pipe(
                switchMap((authenticated) => {

                    // If the user is not authenticated...
                    if (!authenticated) {
                        // Redirect to the sign-in page
                        if (this.authService.businessUser) {
                            const alerted = sessionStorage.getItem('alerted') || '';
                            if (alerted != 'yes') {
                                sessionStorage.setItem('alerted ', 'yes');
                                alert('You have been logged out.');
                            }
                        }
                        this.authService.signOut().subscribe(() => {
                            localStorage.clear();
                            console.clear();
                            // Set the authenticated flag to false
                            this.authService.authenticated = false;
                            sessionStorage.setItem('alerted ', 'yes');
                        });
                        this.router.navigate(['sign-in'], {queryParams: {redirectURL}});
                        // Prevent the access
                        return of(false);
                    }
                    const allLocation = JSON.parse(localStorage.getItem('all_location'));
                    if (allLocation && allLocation.length) {
                        if (!redirectURL.includes('confirmation-required') && !redirectURL.includes('agency-mode') && redirectURL != '/dashboard' && redirectURL != '/dashboard/select-location') {
                            const orgSubscription = JSON.parse(localStorage.getItem('org_subscription'));
                            if (orgSubscription && orgSubscription['active']) {
                                return of(true);
                            } else {
                                this.router.navigate(['/dashboard']);
                                location.reload();
                                return of(false);
                            }
                        }
                    }
                    return of(true);
                })
            );
    }
}
