import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AllLocationResponse} from "../../../../../shared/models/location.types";
import {LocationService} from "../../../../../shared/services/location.service";
import {ReviewSite} from "../../models/review-site";
import {QuickSetupService} from "../../services/quick-setup.service";
import {MatDialog} from "@angular/material/dialog";
import {FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AuthService} from "../../../../../core/auth/auth.service";
import {FuseConfirmationConfig, FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {Router} from "@angular/router";
import {HomeService} from "../../services/home.service";
import {TranslocoService} from '@ngneat/transloco';
import {DialogResult} from "../../../../../shared/constants/dialog-result";
import {PhoneValidator} from "../../../../../shared/validators/phone-validator";
import {PropertyGroup} from "../../../../../shared/constants/property-group";
import {SettingService} from "../../../collect-review/services/setting.service";
import {User} from "../../../../../shared/models/user.types";
import {MediaService} from "../../../../../shared/services/media.service";


@Component({
    selector: 'app-edit-location',
    templateUrl: './edit-location.component.html'
})
export class EditLocationComponent implements OnInit {
    @ViewChild('customLinkDialog') customLinkDialog: TemplateRef<any>;
    @ViewChild('logoFileInput') logoFileInput: ElementRef;
    currentLocation: AllLocationResponse;

    locationForm: UntypedFormGroup;
    reviewSiteForm: UntypedFormGroup;
    canEditSiteName = false;
    siteSearched = false;
    logoUrl;
    siteIndex = -1;
    isAPICallComplete = true;
    isMobileDevice = false;
    user: User;
    innerWidth = window.innerWidth;

    constructor(private formBuilder: UntypedFormBuilder,
                private locationService: LocationService,
                private quickSetupService: QuickSetupService,
                private matDialog: MatDialog,
                private authService: AuthService,
                private snackBar: MatSnackBar,
                private fuseConfirmationService: FuseConfirmationService,
                private router: Router,
                private homeService: HomeService,
                private translocoService: TranslocoService,
                private settingService: SettingService,
                private mediaService: MediaService
    ) {
    }

    ngOnInit(): void {
        this.isMobileDevice = this.homeService.isNativeDevice;
        this.locationService.currentLocation.subscribe(location => this.currentLocation = location);
        this.locationForm = this.formBuilder.group({
            locationId: [this.currentLocation.locationId, Validators.required],
            name: [this.currentLocation.name, Validators.required],
            address: [this.currentLocation.address, Validators.required],
            phoneNo: [this.currentLocation.phoneNo, [Validators.required, PhoneValidator.invalidCountryPhone(this.currentLocation.countryCode, this.currentLocation.dialCodePrefix)]],
            website: [this.currentLocation.website, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')],
            logoImageUrl: [this.currentLocation.logoURL],
            userId: [this.authService.businessUser.userId],
            country: [this.currentLocation.countryName, Validators.required],
            fromName: [''],
            fromEmail: [''],
            replyTo: [''],
            feedbackEmail: [''],
            links: this.formBuilder.array([
                this.formBuilder.group({
                    locationId: ['', Validators.required],
                    logoURL: [''],
                    reviewSiteId: [''],
                    name: ['', Validators.required],
                    priority: [0],
                    reviewLink: [''],
                    isCustom: [false],
                    isNew: [false],
                    isConfigured: [false]
                })
            ])
        });
        this.reviewSiteForm = this.formBuilder.group({
            name: ['', Validators.required],
            reviewLink: ['', [Validators.required, Validators.pattern('https?://[^/]+/[^?]+(\\?([^&]+&)*[^&]+)?')]]
        });
        this.user = this.authService.businessUser;
        this.getReviewSite();
        this.getLocationProperty();
    }

    editLocation() {
        this.isAPICallComplete = false;
        let {links, ...locationData} = this.locationForm.value;
        this.quickSetupService.updateLocationInfo(locationData).subscribe({
            next: (res) => {
                this.locationService.currentLocationDetail = res;
                this.locationService.fetchAllLocationForOrgUser().subscribe(locations => this.locationService.allLocation = locations);
                this.createReviewSite(links);
                this.router.navigate(['dashboard']);
            }
        });
    }

    openDialog(site: ReviewSite = null, index = -1) {
        this.matDialog.closeAll();
        this.siteIndex = index;
        if (site) {
            this.reviewSiteForm.controls['name'].setValue(site.name);
            this.reviewSiteForm.controls['reviewLink'].setValue(site.reviewLink);
            this.canEditSiteName = (this.siteLinks.controls[index] as FormGroup).value.isNew;
            this.canEditSiteName = this.canEditSiteName || (this.siteLinks.controls[index] as FormGroup).value.isCustom;
        } else {
            this.reviewSiteForm.reset();
            this.canEditSiteName = true;
        }
        this.matDialog.open(this.customLinkDialog, {
            hasBackdrop: false, panelClass: window.innerWidth >= 1024 ? 'w-5/12' : 'w-full'
        });
    }

    closeDialog() {
        this.matDialog.closeAll();
    }

    addCustomSite() {
        if (this.siteIndex == -1) {
            this.addReviewSite(
                this.currentLocation.locationId,
                '',
                this.reviewSiteForm.value.name,
                this.siteLinks.length ? (this.siteLinks.controls[this.siteLinks.length - 1] as FormGroup).value.priority + 1 : 0,
                this.reviewSiteForm.value.reviewLink,
                true,
                true,
                '',
                true
            );
        } else {
            (this.siteLinks.controls[this.siteIndex] as FormGroup).controls['name'].setValue(this.reviewSiteForm.value.name);
            (this.siteLinks.controls[this.siteIndex] as FormGroup).controls['reviewLink'].setValue(this.reviewSiteForm.value.reviewLink);
            (this.siteLinks.controls[this.siteIndex] as FormGroup).controls['isConfigured'].setValue(true);
        }
        this.setPriority();
        this.locationForm.markAsDirty();
        this.closeDialog();
    }

    get siteLinks() {
        return this.locationForm.get('links') as FormArray;
    }

    addReviewSite(locationId: string, logoURL: string, name: string, priority: number, reviewLink: string, isCustom = false, isNew = false, reviewSiteId = '', isConfigured = false) {
        this.siteLinks.push(this.formBuilder.group({
            locationId: locationId,
            logoURL: logoURL,
            name: name,
            priority: priority,
            reviewLink: reviewLink,
            isCustom: isCustom,
            reviewSiteId: reviewSiteId,
            isConfigured: isConfigured
        }));
    }

    getReviewSite() {
        const reviewSiteMaster = JSON.parse(localStorage.getItem('review_site_master'));
        if (reviewSiteMaster && reviewSiteMaster.length) {
            this.siteLinks.removeAt(0);
            const reviewSiteLocation = JSON.parse(localStorage.getItem('review_site_location'));
            this.siteSearched = true;
            const reviewSites = reviewSiteLocation.sort((a, b) => a.priority - b.priority).filter(site => site.priority);
            reviewSites.forEach(site => {
                const reviewSite = reviewSiteMaster.filter(s => s.name == site.name);
                this.addReviewSite(site.locationId, reviewSite.length ? reviewSite[0].logoURL : '', site.name, site.priority, site.reviewLink, reviewSite.length == 0, false, site.reviewSiteId, true);
            });
            reviewSiteMaster.forEach(site => {
                const notExistSite = this.siteLinks.value.filter(s => s.name == site.name);
                if (!notExistSite.length) {
                    this.addReviewSite(this.currentLocation.locationId, site.logoURL, site.name, this.siteLinks.length + 1, '');
                }
            });
        } else {
            this.quickSetupService.reviewSiteMaster().subscribe(res => {
                localStorage.setItem('review_site_master', JSON.stringify(res));
                this.siteLinks.removeAt(0);
                this.quickSetupService.getReviewSiteByLocation(this.currentLocation.locationId).subscribe({
                    next: (res1) => {
                        localStorage.setItem('review_site_location', JSON.stringify(res1));
                        this.siteSearched = true;
                        const reviewSites = res1.sort((a, b) => a.priority - b.priority).filter(site => site.priority);
                        reviewSites.forEach(site => {
                            const reviewSite = res.filter(s => s.name == site.name);
                            this.addReviewSite(site.locationId, reviewSite.length ? reviewSite[0].logoURL : '', site.name, site.priority, site.reviewLink, reviewSite.length == 0, false, site.reviewSiteId, true);
                        });
                        res.forEach(site => {
                            const notExistSite = this.siteLinks.value.filter(s => s.name == site.name);
                            if (!notExistSite.length) {
                                this.addReviewSite(this.currentLocation.locationId, site.logoURL, site.name, this.siteLinks.length + 1, '');
                            }
                        });
                    },
                    error: (error) => console.error(error.error)
                });
            });
        }
    }


    moveItemInFormArray(formArray: FormArray, fromIndex: number, toIndex: number): void {
        const from = this.clamp(fromIndex, formArray.length - 1);
        const to = this.clamp(toIndex, formArray.length - 1);
        if (from === to) {
            return;
        }
        const previous = formArray.at(from);
        const current = formArray.at(to);
        formArray.setControl(to, previous);
        formArray.setControl(from, current);
        this.setPriority();
    }

    clamp(value: number, max: number): number {
        return Math.max(0, Math.min(max, value));
    }

    drop(event: CdkDragDrop<string[]>) {
        const from = event.previousIndex;
        const to = event.currentIndex;
        this.moveItemInFormArray(this.siteLinks, from, to);
        this.locationForm.markAsDirty();
    }

    onFileSelect(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            if ((file.size / (1024 * 1024)) > 1) {
                this.logoFileInput.nativeElement.value = "";
                this.snackBar.open(this.translocoService.translate('Logo Size Less Than 1 MB'), '', {
                    duration: 5000,
                });
                return;
            }
            const formData = new FormData();
            const now = new Date().getTime();
            const path = 'location/' + this.authService.businessUser.orgId + '/' + now + '.' + file.name.split('.')[1];
            formData.append('file', file);
            formData.append('path', path);
            this.mediaService.uploadFileToS3(formData).subscribe({
                next: (res: any) => {
                    this.logoUrl =res.message;
                    this.locationForm.controls['logoImageUrl'].setValue(res.message);
                    this.locationForm.markAsDirty();
                },
                error: (error) => {
                    console.log(error);
                }
            });
        }
    }

    deleteLocation() {
        const deleteLocationConfig: FuseConfirmationConfig = {
            title: this.translocoService.translate('Delete Location'),
            message: `${this.translocoService.translate('Delete Location Confirmation Message')} <b>${this.currentLocation.name}</b>?`,
            actions: {
                confirm: {
                    label: this.translocoService.translate('Delete'),
                }
            }
        }
        this.fuseConfirmationService.open(deleteLocationConfig).afterClosed().subscribe(result => {
            if (result == DialogResult.Confirm) {
                this.locationService.disableLocation(this.currentLocation.locationId).subscribe({
                    next: () => {
                        this.getMyOrgSubscription();
                        this.snackBar.open(this.translocoService.translate('Location Successfully Deleted'), '', {
                            duration: 2000,
                        });
                        this.locationService.allLocation = this.locationService.allLocation.filter(location => location.locationId !== this.currentLocation.locationId);
                        if (this.locationService.allLocation.length == 0) {
                            this.locationService.currentLocationDetail = null;
                            this.router.navigate(['/dashboard/quick-setup']);
                        } else if (this.locationService.allLocation.length == 1) {
                            this.locationService.currentLocationDetail = this.locationService.allLocation[0];
                            this.router.navigate(['dashboard']);
                        } else {
                            this.router.navigate(['/dashboard/select-location']);
                            this.locationService.currentLocationDetail = null;
                        }
                    },
                    error: () => {
                        this.snackBar.open(this.translocoService.translate('Something Went Wrong'), '', {
                            duration: 5000,
                        });
                    }
                });
            }
        });
    }

    getMyOrgSubscription() {
        this.homeService.getMyOrgSubscription().subscribe({
            next: (res) => {
                this.homeService.orgSubscription = res;
            },
            error: (error) => {
                this.snackBar.open(this.translocoService.translate('Issue With Subscription'), '', {duration: 10000,});
            }
        });
    }

    deleteSite(site: any, siteIndex: number) {
        if (site.reviewSiteId) {
            this.quickSetupService.disableReviewSite(site.reviewSiteId).subscribe({
                next: (res) => {
                    localStorage.removeItem('review_site_master');
                    localStorage.removeItem('review_site_location');
                    if (site.isCustom) {
                        this.siteLinks.removeAt(siteIndex);
                    } else {
                        (this.siteLinks.controls[siteIndex] as FormGroup).controls['reviewLink'].setValue('');
                        (this.siteLinks.controls[siteIndex] as FormGroup).controls['isConfigured'].setValue(false);
                    }
                    this.snackBar.open(this.translocoService.translate('Review Site disabled successfully.'), '', {duration: 3000});
                    this.setPriorityAfterDelete(site.priority);
                },
                error: (error) => this.snackBar.open(error.error.error)
            });
        } else {
            if (site.isCustom) {
                this.siteLinks.removeAt(siteIndex);
            } else {
                (this.siteLinks.controls[siteIndex] as FormGroup).controls['reviewLink'].setValue('');
                (this.siteLinks.controls[siteIndex] as FormGroup).controls['isConfigured'].setValue(false);
            }
            this.snackBar.open(this.translocoService.translate('Review Site disabled successfully.'), '', {duration: 3000});
            this.setPriorityAfterDelete(site.priority);
        }
    }

    setPriority() {
        (this.locationForm.get('links') as FormArray).reset(this.siteLinks.value);
        let priority = 0;
        this.siteLinks.value.forEach((reviewSite: { reviewLink: string }, i: string | number) => {
            if (reviewSite.reviewLink) {
                (this.siteLinks.controls[i] as FormGroup).controls['priority'].setValue(priority + 1);
                priority++;
            }
        });
        let configuredReviewSites = this.siteLinks.value.filter((site: {
            reviewLink: string;
        }) => site.reviewLink && site.reviewLink !== '').length;
        this.siteLinks.value.forEach((reviewSite: { reviewLink: string }, i: string | number) => {
            if (!reviewSite.reviewLink || reviewSite.reviewLink.length == 0) {
                (this.siteLinks.controls[i] as FormGroup).controls['priority'].setValue(configuredReviewSites + 1);
                configuredReviewSites++;
            }
        });
        const sortedSites = this.siteLinks.value.sort((a, b) => a.priority - b.priority);
        while (this.siteLinks.length) {
            this.siteLinks.removeAt(0);
        }
        sortedSites.forEach(site => {
            this.addReviewSite(site.locationId, site.logoURL, site.name, site.priority, site.reviewLink, site.isCustom, site.isNew, site.reviewSiteId, site.isConfigured);
        });
    }

    setPriorityAfterDelete(selectedPriority: number) {
        this.siteLinks.value.forEach((reviewSite: { priority: number }, i: string | number) => {
            if (reviewSite.priority > selectedPriority) {
                (this.siteLinks.controls[i] as FormGroup).controls['priority'].setValue(reviewSite.priority - 1);
            }
        });
        let {links, ...locationData} = this.locationForm.value;
        this.createReviewSite(links);
    }

    createReviewSite(links) {
        if (links.length) {
            links = links.filter(link => link['isConfigured']).map(({
                                                                        isCustom, isNew, isConfigured, ...rest
                                                                    }, index) => {
                rest['priority'] = index + 1;
                return rest;
            });
            this.quickSetupService.createReviewSites(links).subscribe(res => { });
            this.locationForm.reset(this.locationForm.value);
            this.isAPICallComplete = true;
            localStorage.removeItem('review_site_master');
            localStorage.removeItem('review_site_location');
        }
    }

    getLocationProperty() {
        this.settingService.getAllProperties(this.currentLocation.locationId, PropertyGroup.EMAIL).subscribe({
            next: (res) => {
                const fromNameProperty = res.filter(property => property.propertyKey === 'fromName');
                const fromEmailProperty = res.filter(property => property.propertyKey === 'fromEmail');
                const replyToProperty = res.filter(property => property.propertyKey === 'replyTo');
                const feedbackEmailProperty = res.filter(property => property.propertyKey === 'feedbackEmail');
                if (fromNameProperty.length) {
                    this.locationForm.controls['fromName'].setValue(fromNameProperty[0].propertyValue);
                }
                if (fromEmailProperty.length) {
                    this.locationForm.controls['fromEmail'].setValue(fromEmailProperty[0].propertyValue);
                }
                if (replyToProperty.length) {
                    this.locationForm.controls['replyTo'].setValue(replyToProperty[0].propertyValue);
                }
                if (feedbackEmailProperty.length) {
                    this.locationForm.controls['feedbackEmail'].setValue(feedbackEmailProperty[0].propertyValue);
                }
            },
            error: (error) => console.error(error.error)
        });
    }
}
