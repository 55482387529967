import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  getBrowserLanguage(){
    return navigator.language.slice(0,2);
  }
}
