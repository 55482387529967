<div class="bg-card shadow rounded-2xl m-auto h-auto md:max-h-160 overflow-auto p-5 w-full"
     [ngClass]="allLocations.length > 6 ? 'md:max-w-1/2' :'md:max-w-sm'" *transloco="let t">
    <h1 class="text-3xl font-extrabold tracking-tight leading-none text-center py-2">{{ t('Select Location To Continue') }}</h1>
    <div class="grid gap-2" [ngClass]="allLocations.length>6 && 'md:grid-cols-2'">
        <fuse-card *ngFor="let location of allLocations;" (click)="setLocation(location)"
                   class="flex items-center hover:bg-gray-100 w-sm p-2 filter-info hover:cursor-pointer border shadow-md">
            <img class="w-16 h-16 rounded-full" [src]="location.logoURL" alt="{{t('Logo')}}">
            <div class="ml-2">
                <h1 class="text-2xl font-semibold leading-tight">{{ location.name }}</h1>
                <span class="text-md leading-tight text-slate-500">{{ location.address }}</span>
            </div>
        </fuse-card>
    </div>
</div>
