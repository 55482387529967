import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ReviewSiteMaster} from "../models/review-site-master";
import {environment} from 'environments/environment';
import {ReviewSite} from "../models/review-site";
import {AllLocationResponse} from "../../../../shared/models/location.types";

@Injectable({
    providedIn: 'root'
})
export class QuickSetupService {

    constructor(private http: HttpClient) {
    }


    createAndAttachUser(data: any) {
        return this.http.post(`${environment.BASE_URL}location/createAndAttachUser`, data);
    }

    reviewSiteMaster() {
        return this.http.get<ReviewSiteMaster[]>(`${environment.BASE_URL}reviewSiteMaster/listAll`);
    }

    getReviewSiteByLocation(locationId: string) {
        return this.http.get<ReviewSite[]>(`${environment.BASE_URL}reviewSite/listByLocation/${locationId}`);
    }

    createReviewSites(data: any) {
        return this.http.post(`${environment.BASE_URL}reviewSite/createReviewSites`, data);
    }

    updateLocationInfo(data: any) {
        return this.http.post<AllLocationResponse>(`${environment.BASE_URL}location/updateLocationInfo/`, data);
    }

    disableReviewSite(reviewSiteId: string) {
        return this.http.delete(`${environment.BASE_URL}reviewSite/disable/${reviewSiteId}`);
    }


}
