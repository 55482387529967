import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {User} from '../../shared/models/user.types';
import {environment} from 'environments/environment';
import JSEncrypt from 'jsencrypt';
import {UserByEmail} from "../../modules/admin/agency-mode/models/user-by-email";
import {WhiteLabelProperty} from "../../shared/models/white-label-property";

@Injectable()
export class AuthService {
    authenticated: boolean = false;
    alerted = false;

    /**
     * Constructor
     */
    constructor(
        private httpClient: HttpClient,
        private router: Router
    ) {
    }

    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    set idToken(token: string) {
        localStorage.setItem('idToken', token);
    }

    get idToken(): string {
        return localStorage.getItem('idToken') ?? '';
    }

    set businessUser(user: User) {
        localStorage.setItem('business_user', JSON.stringify(user));
    }

    get refreshToken() {
        return localStorage.getItem('refreshToken') ?? '';
    }

    set refreshToken(token: string) {
        localStorage.setItem('refreshToken', token);
    }

    get businessUser(): User {
        let user: User;
        const data = JSON.parse(localStorage.getItem('business_user'));
        if (data) {
            user = {
                firebaseUserId: data['firebaseUserId'],
                emailAddress: data['emailAddress'],
                fullName: data['fullName'],
                orgId: data['orgId'],
                userId: data['userId'],
                roleName: data['roleName'],
                permissionList: data['permissionList'],
                hubspotToken: data['hubSpotToken'],
                roleType: data['roleType'],
                level: data['level'],
                source: data['source'],
                idToken: data['idToken'],
                refreshToken: data['refreshToken'],
            };
        }
        return user;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param emailAddress
     */
    forgotPassword(emailAddress: string): Observable<any> {
        return this.httpClient.post(`${environment.BASE_URL}publicAPI/forgotPassword`, {emailAddress});
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this.httpClient.post('api/auth/reset-password', password);
    }


    signOut(): Observable<any> {
        if (this.businessUser && this.businessUser.emailAddress) {
            return this.httpClient.post(`${environment.BASE_URL}publicAPI/signOut`, {
                email: this.businessUser.emailAddress
            });
        } else {
            return of(true);
        }
    }


    signUpBackend(body: any): Observable<User> {
        return this.httpClient.post<User>(`${environment.BASE_URL}publicAPI/signUp`, body);
    }

    signInBackend(body: any): Observable<User> {
        return this.httpClient.post<User>(`${environment.BASE_URL}publicAPI/signIn`, body);
    }

    sendVerificationMail() {
        return this.httpClient.post(`${environment.BASE_URL}userAuth/resendEmailVerify`, {});
    }

    resetPasswordVerifyEmailHandlers(actionMode: string, oobCode: string, newPassword: string) {
        return this.httpClient.post(`${environment.BASE_URL}publicAPI/verifyEmailOrResetPassword`, {
            actionMode,
            oobCode,
            newPassword
        });
    }


    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        if (this.accessToken && this.refreshToken && this.businessUser != null) {
            return of(true);
        } else {
            return of(false);
        }
    }

    getNewAccessToken() {
        return this.httpClient.get<{
            idToken: string
        }>(`${environment.BASE_URL}publicAPI/refreshToken?refreshToken=${this.refreshToken}`);
    }

    changeAlertStatus(status: boolean) {
        this.alerted = status;
    }

    inviteUser(data: any) {
        return this.httpClient.post(`${environment.BASE_URL}userAuth/inviteUser`, data);
    }

    getUsersByLocationId(locationId: string) {
        return this.httpClient.get(`${environment.BASE_URL}userAuth/getUsersByLocationId/${locationId}`);
    }

    disableUser(userId: string) {
        return this.httpClient.put(`${environment.BASE_URL}userAuth/disableUser/${userId}`, {});
    }

    updateUser(userId: string, userRole: string) {
        return this.httpClient.put(`${environment.BASE_URL}userAuth/updateUserRole`, {userId, userRole});
    }

    resendInvite(businessName: string, userId: string, locationId: string) {
        return this.httpClient.put(`${environment.BASE_URL}userAuth/resendInvite`, {businessName, userId, locationId});
    }

    addLocation(locationIds: string[], userId: string) {
        return this.httpClient.put(`${environment.BASE_URL}userLocation/addLocation`, {locationIds, userId});
    }

    removeLocation(locationIds: string[], userId: string) {
        return this.httpClient.put(`${environment.BASE_URL}userLocation/removeLocation`, {
            locationIds,
            userId
        });
    }

    getAllLocationsPerUser(userId: string) {
        return this.httpClient.get<Array<string>>(`${environment.BASE_URL}userLocation/getAllLocationsPerUser/${userId}`);
    }

    deleteUser(orgId: string, userId: string, userName: string) {
        return this.httpClient.post(`${environment.BASE_URL}userAuth/deleteUser`, {orgId, userId, userName});

    }

    encrypt(text: string) {
        const publicKey = `-----BEGIN PUBLIC KEY-----
${environment.ENCRYPTION_PUBLIC_KEY}
-----END PUBLIC KEY-----`;
        const encryptor = new JSEncrypt();
        encryptor.setPublicKey(publicKey);
        return encryptor.encrypt(text);
    }

    getUserByEmail(emailId: string): Observable<UserByEmail> {
        return this.httpClient.get<UserByEmail>(encodeURI(`${environment.BASE_URL}userLocation/getUserByEmail/${emailId}`));
    }

    getCustomDomainWhiteLabelledProperties(customDomain: string): Observable<WhiteLabelProperty> {
        return this.httpClient.get<WhiteLabelProperty>(encodeURI(`${environment.BASE_URL}publicAPI/getCustomDomainWhiteLabelledProperties?customDomain=${customDomain}`));
    }
}
