<button mat-icon-button (click)="openPanel()" #newFeaturesOrigin>
    <ng-container *ngIf="newFeatures.length > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-indigo-600 text-indigo-50 text-xs font-medium">
                {{newFeatures.length}}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:inbox'"></mat-icon>
</button>

<!-- Shortcuts panel -->
<ng-template #newFeaturesPanel *transloco="let t">
    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="flex items-center text-lg font-medium leading-10">
                {{t('New Features')}}
            </div>
        </div>
        <div class="relative flex flex-col flex-auto sm:max-h-120 -mb-px overflow-y-auto bg-card divide-y">
            <div class="flex hover:bg-gray-50 justify-between items-center group p-5 hover:cursor-pointer"
                 (click)="goToFeatures(newFeature)"
                 *ngFor="let newFeature of newFeatures; trackBy: trackByFn">
                <div class="flex flex-col space-y-1">
                    <div class="text-black font-semibold">{{ newFeature.title }}</div>
                    <div class="text-slate-600 text-sm">{{ newFeature.desc }}</div>
                </div>
                <button mat-icon-button class="sm:opacity-0 sm:group-hover:opacity-100" [routerLink]="newFeature.link">
                    <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:arrow-right'"></mat-icon>
                </button>
            </div>
        </div>
    </div>
</ng-template>
