export const environment = {
    production: false,
    BASE_URL: 'https://gatewaydev.freshreview.co/',
    GOOGLE_PLACE_API_KEY: "AIzaSyBUQ2_mcD5frX8xAINyePVkhee6hPwmF3k",
    stripe_publishable_key: 'pk_test_51LGbUPCYUnslNwfLVFhXqOhg3JsbIeeokNSLjYlFnH9fTyba6xRP03KsFkTQ7qCldS2NZDQEl20ebWgrvwcpL4zW00zKQuUTR2',
    stripe_pricing_table_id: 'prctbl_1MgP0tCYUnslNwfLs0AsVome',
    stripe_customer_portal: 'https://billing.stripe.com/p/login/test_3csaGk7Vubmg0YoeUU',
    zaiper_client_id: 'd1YhWX7HuQlb3tGL0rQiRIPbn9wnWDOgNqAsHIhB',
    app_url: 'https://freshreview-2-dev.web.app/',
    widget_url: 'https://fr-widget-js-v2.s3.us-east-2.amazonaws.com/widget-dev.min.js',
    micro_site_url: 'https://microsite-dev-59d1b.web.app/',
    clarity_project_id: 'emfnp0y5cs',
    customer_list_sample_file_url: 'https://freshreview-dev.s3.us-east-2.amazonaws.com/sample_csv/freshreview_customer_sample.csv',
    funnel_url: 'https://freshreview-feedback-dev.web.app/',
    mark_up_percentage: 100,
    gmb_basic_payment_link: 'https://buy.stripe.com/28oaHR0QZ4g8fle289',
    gmb_pro_payment_link: 'https://buy.stripe.com/8wM6rBbvD7sk4GA9AA',
    ENCRYPTION_PUBLIC_KEY: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwPXSV53YTG3I2gi6FKviCZK8Wt6bbp3vgRT7GMRd5JkJ00WK8XC5o6N1m3b6LA7VyKA75Ft5APbZ3wjNxfh5+CAa3xsa9FcGmBz+Hi1QldIn7qTBfOiCbJnOynSfkkQKPNHeeaMkdy0/Bi8oi6YIhd+hg5vCR7dT7ZSTbQiSH5Y+xVCYf3dQeILRqlSqNtYa274nGID9/6vnT/CeL38DAmtko3ramtXieTgy6O5csG3rUkOwquNQnqch1rjh6PptBGg69VAF3Gh2p/f0X6hcmf+PWqmLIt1i76Dnz3vt4SU/C3+b6xZ0h2arFFQo095PwxWovvpUEc9VE4dzVBO8owIDAQAB',
    creatomate_template_id: '66f853ff-294e-49ec-b1d4-c1da6ee83feb'
};
