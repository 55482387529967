<div class="flex flex-row w-full">
    <div *ngIf="ratingIcon=='mat_outline:star'">
        <mat-icon *ngFor="let r of getRating();let i=index;" [class]="className ? className : 'icon-size-'+size"
                  (click)="sendResponse(i+1)" [ngClass]="{ 'text-orange-500': activeStarColor.length == 0 }"
                  [ngStyle]="{ color: activeStarColor }"
                  [svgIcon]="'mat_outline:star'">
        </mat-icon>
        <mat-icon *ngIf="isHalf" [ngClass]="{ 'text-orange-500': activeStarColor.length == 0 }"
                  [ngStyle]="{ color: activeStarColor }" [class]="className ? className : 'icon-size-'+size"
                  [svgIcon]="'mat_outline:star_half'">
        </mat-icon>
        <mat-icon *ngFor="let r of getRemainingRating();let i=index;"
                  [class]="className ? className : 'icon-size-'+size"
                  (click)="sendResponse(rating + i+1)" [svgIcon]="ratingIcon" [ngStyle]="{ color: inactiveStarColor }">
        </mat-icon>
    </div>
    <div *ngIf="ratingIcon!=='mat_outline:star'">
        <mat-icon *ngFor="let r of getAllRating();let i=index" [class]="className ? className : 'icon-size-'+size"
                  [ngClass]="{'text-orange-500': rating>i}" [svgIcon]="iconList[i]">
        </mat-icon>
    </div>
</div>
