import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {TranslocoModule} from '@ngneat/transloco';
import {UserComponent} from 'app/layout/common/user/user.component';
import {SharedModule} from 'app/shared/shared.module';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {RouterLink} from "@angular/router";

@NgModule({
    declarations: [
        UserComponent
    ],
    imports: [
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        SharedModule,
        TranslocoModule,
        MatSnackBarModule,
        RouterLink
    ],
    exports: [
        UserComponent
    ]
})
export class UserModule {
}
