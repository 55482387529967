import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-email-preview-template',
    templateUrl: './email-preview-template.component.html'
})
export class EmailPreviewTemplateComponent implements OnInit {

    @Input() fontFamily = 'Arial';
    @Input() buttonColour = '#4937b2';
    @Input() headerColour = '#4937b2';
    @Input() backgroundColour = '#ffffff';
    @Input() businessLogo = '{{logo}}';
    @Input() businessName = '{{businessName}}';
    @Input() businessAddress = '{{businessAddress}}';
    @Input() message = '{{message}}';
    @Input() customLogoUrl = '';
    @Input() poweredByUrl = '';
    @Input() whiteLabelEnabled = false;
    @Input() ctaText = 'Leave a Review';
    @Input() footerText = 'Thanks,<br />{{businessName}}&nbsp;Team.<br />\n {{businessAddress}}</b>';
    @Input() footerTextAlign = 'center';

    constructor() {
    }

    ngOnInit(): void {
    }

}
