import {Component, OnInit} from '@angular/core';
import {LocationService} from "../../../../../shared/services/location.service";
import {AllLocationResponse} from "../../../../../shared/models/location.types";
import {Router} from "@angular/router";

@Component({
    selector: 'app-location',
    templateUrl: './location.component.html'
})
export class LocationComponent implements OnInit {
    allLocations: AllLocationResponse[] = [];


    constructor(private locationService: LocationService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.allLocations = this.locationService.allLocation;
    }

    setLocation(location: AllLocationResponse) {
        this.locationService.currentLocationDetail = location;
        this.router.navigate(['dashboard']);
    }
}
