<div class="w-full p-2" *transloco="let t">
    <div class="font-semibold"> {{ t('Media Files') }}</div>
    <div class="flex space-x-3 overflow-x-auto my-2 p-2 border rounded">
        <div *ngIf="mmsDataList && mmsDataList.length<10"
             class="min-w-24 w-24 h-24 md:min-w-40 md:w-40 md:h-40 flex flex-col items-center justify-center rounded-2xl cursor-pointer border-2 border-gray-300 border-dashed hover:bg-hover transition-colors duration-150 ease-in-out"
             (click)="openMediaDialog()" [matTooltip]="t('Add New')" [matTooltipPosition]="'above'">
            <mat-icon class="icon-size-6 md:icon-size-8 text-hint" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
            <span class="text-center text-sm md:text-2xl text-slate-400">{{ t('Add New') }}</span>
        </div>
        <div *ngFor="let mediaFile of mmsDataList; let i = index"
             (click)="selectMedia(mediaFile, i)"
             class="p-1 border rounded min-w-24 w-24 h-24 md:min-w-40 md:w-40 md:h-40 cursor-pointer hover:border-primary flex items-center justify-center group"
             [matTooltip]="mediaFile.fileName"
             [matTooltipPosition]="'above'"
             [ngClass]="{'border-2 border-primary' : selectedMediaFileIndex == i}">
            <!-- Audio file type -->
            <div class="flex flex-col items-center justify-center min-w-20" *ngIf="mediaFile.fileType=='audio'">
                <mat-icon class="icon-size-6" [svgIcon]="'audiotrack'"></mat-icon>
                <span class="text-center text-sm">{{ t('Audio') }}</span>
            </div>

            <!-- Video file type -->
            <div class="flex flex-col items-center justify-center min-w-20" *ngIf="mediaFile.fileType=='video'">
                <mat-icon class="icon-size-6" [svgIcon]="'ondemand_video'"></mat-icon>
                <span class="text-center text-sm">{{ t('Video') }}</span>
            </div>

            <!-- Image file display -->
            <img class="w-full h-full object-cover" [src]="mediaFile.fileLink" alt="" *ngIf="mediaFile.fileType == 'image'">
        </div>
    </div>
</div>

<ng-template #mediaFileDialog>
    <div class="flex flex-col  max-h-screen -m-6" *transloco="let t">
        <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
            <div class="text-lg font-medium">{{ t('Upload Media') }}</div>
            <button mat-icon-button (click)="closeDialog()" [tabIndex]="-1">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>
        <form [formGroup]="mmsSettingsForm" class="flex flex-col flex-auto overflow-y-auto w-full">
            <mat-stepper #matStepper [linear]="true">
                <mat-step [label]="t( mediaType=='' ? 'Media Type' : 'Upload Background Image')">
                    <input type="file" (change)="onFileSelect($event)" #mediaFileInput class="hidden"/>
                    <div class="grid" [ngClass]="{'grid-cols-3 gap-2' : mediaTypes.length >1}" *ngIf="mediaType==''">
                        <div class="flex flex-col items-center p-5 rounded hover:bg-hover border hover:border-primary w-full space-y-2 cursor-pointer"
                             *ngFor="let mediaType of mediaTypes"
                             (click)="selectMediaType(mediaType.type, mediaFileInput)">
                            <mat-icon [svgIcon]="mediaType.icon" class="icon-size-8"></mat-icon>
                            <div>{{ t(mediaType.name) }}</div>
                        </div>
                    </div>
                    <div class="border-dashed rounded-2xl shadow border-2 border-gray-400 p-10 flex flex-col items-center justify-center space-y-2 m-5"
                         appDragAndDrop (files)="filesDropped($event)" *ngIf="mediaType!=''">
                        <div class="w-full text-center font-bold">
                            <mat-icon class="icon-size-12" svgIcon="feather:upload"></mat-icon>
                            <div>{{ t('Drag and Drop Image here.') }}</div>
                            <div class="mt-2">-OR-</div>
                        </div>
                        <div class="flex space-x-2">
                            <button mat-stroked-button type="button" (click)="mediaFileInput.click()">
                                {{ t('Choose Image') }}
                            </button>
                        </div>
                    </div>

                </mat-step>
                <mat-step [label]="t('Done')">
                    <div class="flex justify-center items-center flex-col">
                        <mat-form-field class="fuse-mat-no-subscript w-full">
                            <input [formControlName]="'fileName'" matInput [placeholder]="t('File Name')"
                                   type="text">
                        </mat-form-field>
                        <img [src]="mmsSettingsForm.controls['propertyValue'].value" alt=""
                             class="border-2 w-44 border-white shadow"
                             *ngIf="mediaFile && mediaFile?.type.includes('image')">
                        <audio class="shadow rounded-full w-full" controls
                               [src]="mmsSettingsForm.controls['propertyValue'].value"

                               *ngIf="mediaFile && mediaFile?.type.includes('audio')">
                        </audio>
                        <video class="border-2 shadow rounded bg-white p-1 w-72 h-auto" controls
                               [src]="mmsSettingsForm.controls['propertyValue'].value"
                               *ngIf="mediaFile && mediaFile?.type.includes('video')">
                        </video>
                    </div>
                    <div class="flex justify-between items-center pt-4">
                        <button mat-stroked-button class="min-w-32" type="button"
                                (click)="matStepper.previous();"> {{ t('Change file') }}
                        </button>
                        <button (click)="submit()" *ngIf="mediaFile && mediaFile?.name"
                                [color]="'primary'" [disabled]="!isAPICallComplete || mmsSettingsForm.invalid"
                                mat-flat-button>
                            {{ t('Save') }}
                        </button>
                    </div>
                </mat-step>
            </mat-stepper>
        </form>
    </div>
</ng-template>
