import {Features} from "../models/features";

export const NewFeatures: Features[] = [
    {
        title: 'Multi User',
        desc: 'Manage Your Team',
        link: '/agency-mode/user-list',
        featureKey: 'whitelabelEnabled',
        roles: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        title: 'WhatsApp',
        desc: 'WhatsApp Messaging for Review Campaigns',
        link: '/collect-review/sequence',
        featureKey: '',
        roles: ['SUPER_ADMIN', 'ADMIN', 'USER']
    },
    {
        title: 'Review Funnel',
        desc: 'Review Funnel Landing Page for your business',
        link: '/collect-review/funnel-page',
        featureKey: 'businessFunnelEnabled',
        roles: ['SUPER_ADMIN']
    },
    {
        title: 'Micro Site',
        desc: 'Micro-site Showcasing reviews',
        link: '/display-review/micro-site',
        featureKey: 'micrositeEnabled',
        roles: ['SUPER_ADMIN', 'ADMIN', 'USER']
    },
    {
        title: 'Google Reviews',
        desc: 'Monitor Google Reviews',
        link: '/monitor-reviews/my-reviews',
        featureKey: '',
        roles: ['SUPER_ADMIN', 'ADMIN', 'USER']
    },
    {
        title: 'Google Widgets and Badges',
        desc: 'Embed & showcase google Reviews',
        link: '/display-review/review-widget',
        featureKey: 'widgetsEnabled',
        roles: ['SUPER_ADMIN', 'ADMIN', 'USER']
    },
    {
        title: 'Social Media Post Generator',
        desc: 'Generator Social Media Post',
        link: '/display-review/social-media-post',
        featureKey: '',
        roles: ['SUPER_ADMIN', 'ADMIN', 'USER']
    }
];
