import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ShortcutsService {
    public setupProgressDetail = new BehaviorSubject<number>(0);
    currentSetupProgress = this.setupProgressDetail.asObservable();

    constructor() {
    }

    get currentSetupProgressDetail(): number {
        return this.getLocalStorage('current_progress');
    }

    set currentSetupProgressDetail(progress: number) {
        localStorage.setItem('current_progress', JSON.stringify(progress));
        this.setupProgressDetail.next(progress);
    }

    getLocalStorage(key): any {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (e) {
            console.error('Error getting data local storage Data', e);
            return null;
        }
    }
}
