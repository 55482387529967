import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {WhiteLabelProperty} from "../models/white-label-property";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class WhiteLabelPropertyService {
    public whiteLabelPropertyInfo = new BehaviorSubject<WhiteLabelProperty>(null);
    currentWhiteLabelPropertyInfo = this.whiteLabelPropertyInfo.asObservable();

    constructor(private http: HttpClient) {
    }

    get whiteLabelProperty(): WhiteLabelProperty {
        let property: WhiteLabelProperty;
        let data = localStorage.getItem('whiteLabelProperty');
        if (data && data != 'undefined') {
            data = JSON.parse(data)
            property = {
                createdAt: data['createdAt'],
                updatedAt: data['updatedAt'],
                createdBy: data['createdBy'],
                createdByName: data['createdByName'],
                updatedBy: data['updatedBy'],
                updatedByName: data['updatedByName'],
                id: data['id'],
                orgId: data['orgId'],
                customLogoUrl: data['customLogoUrl'],
                faviconUrl: data['faviconUrl'],
                companyName: data['companyName'],
                poweredByUrl: data['poweredByUrl'],
                whiteLabelEnabled: data['whiteLabelEnabled'],
                titleText: data['titleText'],
                dashboardLogo: data['dashboardLogo']
            };
        }
        return property;
    }

    set whiteLabelProperty(value: WhiteLabelProperty) {
        localStorage.setItem('whiteLabelProperty', JSON.stringify(value));
        this.whiteLabelPropertyInfo.next(value);
    }

    createAndUpdateWhiteLabelProperties(data: any) {
        return this.http.post(`${environment.BASE_URL}whiteLabelProperties/createAndUpdateWhiteLabelProperties`, data);
    }

    getWhiteLabelProperties(orgId: string) {
        return this.http.get<WhiteLabelProperty>(`${environment.BASE_URL}whiteLabelProperties/getWhiteLabelProperties/${orgId}`);
    }
}
