import {Features} from "../models/features";
import {Roles} from "./roles";

export const NewFeatures: Features[] = [
    {
        title: 'NPS, CSAT and Survey',
        desc: 'Create NPS, CSAT and Survey Form for your business',
        link: '/collect-review/funnel-page',
        featureKey: 'funnelCustomizationEnabled',
        roles: [Roles.SUPER_ADMIN]
    },
    {
        title: 'Invite Review Ratio',
        desc: 'Invite Review Ratio Analytics',
        link: '/analytics/invite-review-ratio',
        featureKey: 'funnelCustomizationEnabled',
        roles: [Roles.SUPER_ADMIN]
    },
    {
        title: 'Channel Based Analytics',
        desc: 'Channel Based Analytics',
        link: '/analytics/channel-based',
        featureKey: 'funnelCustomizationEnabled',
        roles: [Roles.SUPER_ADMIN]
    },
    {
        title: 'NPS, CSAT and Survey Analytics',
        desc: 'NPS, CSAT and Survey Analytics',
        link: '/analytics/fresh-feedback',
        featureKey: 'funnelCustomizationEnabled',
        roles: [Roles.SUPER_ADMIN]
    },
    {
        title: 'Custom SMS Provider',
        desc: 'Setup SMS Provider',
        link: '/agency-mode/sms-provider',
        featureKey: 'smsProviderEnabled',
        roles: [Roles.SUPER_ADMIN]
    },
    {
        title: 'Custom EMAIL Provider',
        desc: 'Setup EMAIL Provider',
        link: '/agency-mode/email-provider',
        featureKey: '',
        roles: [Roles.SUPER_ADMIN]
    },
    {
        title: 'Custom Open AI Provider',
        desc: 'Setup Open AI Provider',
        link: '/agency-mode/open_ai-provider',
        featureKey: 'openAiProviderEnabled',
        roles: [Roles.SUPER_ADMIN]
    },
    {
        title: 'Custom Domain',
        desc: 'Configure your custom domain',
        link: '/custom-domain/domain-list',
        featureKey: 'customDomainEnabled',
        roles: [Roles.SUPER_ADMIN]
    },
    {
        title: 'White Label',
        desc: 'White Label',
        link: '/agency-mode/white-label',
        featureKey: 'whitelabelEnabled',
        roles: [Roles.SUPER_ADMIN]
    }
];
