import {
    ChangeDetectionStrategy,
    Component, Input,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {Router} from '@angular/router';
import {User} from "../../../shared/models/user.types";
import {AuthService} from "../../../core/auth/auth.service";
import {HomeService} from "../../../modules/admin/dashboard/services/home.service";
import {FuseConfirmationConfig, FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {DialogResult} from "../../../shared/constants/dialog-result";
import {Crisp} from "crisp-sdk-web";

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user'
})
export class UserComponent implements OnInit {
    user: User;
    @Input() isAppleDevice = false;

    constructor(
        private router: Router,
        private authService: AuthService,
        private homeService: HomeService,
        private fuseConfirmationService: FuseConfirmationService,
        private translocoService: TranslocoService,
        private snackbarService: SnackbarService
    ) {
    }

    ngOnInit(): void {
        this.user = this.authService.businessUser;
    }

    signOut(): void {
        this.authService.signOut().subscribe({
            next: (res) => {
                Crisp.setTokenId(); // 1. Clear the token value
                Crisp.session.reset();
                this.router.navigate(['/sign-in']);
                localStorage.clear();
                console.clear();
                // Set the authenticated flag to false
                this.authService.authenticated = false;
                sessionStorage.setItem('alerted ', 'yes');
                location.reload();
            }, error: (error) => {
                Crisp.setTokenId(); // 1. Clear the token value
                Crisp.session.reset();
                this.router.navigate(['/sign-in']);
                localStorage.clear();
                console.clear();
                // Set the authenticated flag to false
                this.authService.authenticated = false;
                sessionStorage.setItem('alerted ', 'yes');
                location.reload();
            }
        });
    }

    deleteUser() {
        const deleteUserConfig: FuseConfirmationConfig = {
            title: this.translocoService.translate('Delete User'),
            message: this.translocoService.translate('Delete User Confirmation'),
            actions: {
                confirm: {
                    label: this.translocoService.translate('Delete'),
                }
            }
        }
        this.fuseConfirmationService.open(deleteUserConfig).afterClosed().subscribe(result => {
            if (result == DialogResult.Confirm) {
                this.homeService.deleteUser(this.user.fullName, this.user.userId, this.user.orgId).subscribe({
                    next: (res: any) => {
                        this.snackbarService.showSnackBar(res.message, 1000);
                        this.signOut();
                    },
                    error: (error) => console.error(error.error.error)
                });
            }
        });
    }
}
