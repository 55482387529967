<div class="w-full -m-6" *transloco="let t">
    <div class="flex flex-0 items-center justify-between h-16 pr-5 pl-8 bg-primary text-on-primary w-[120%]">
        <div class="text-lg font-medium">{{ t('Contact List') }}</div>
        <button mat-icon-button (click)="closeDialog()" [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <div class="w-[110%]">
        <div class="ml-5">
            <!--        <div class="ml-5" *ngIf="isContactFetched && contacts.length">-->
            <mat-form-field class="mt-1 w-full">
                <input type="text" matInput [placeholder]="t('Search Contact')"
                       (keyup)="searchContact(searchText.value)" #searchText>
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            <div class="text-center mb-1">{{ t('Select a contact to enroll in a sequence.') }}</div>
            <div class="flex flex-col overflow-auto max-h-[650px]">
                <div class="flex space-x-1 border shadow items-center rounded-2xl p-2 px-3 hover:bg-gray-200 mb-2"
                     (click)="closeDialog(contact)" *ngFor="let contact of contacts">
                    <img [src]="contact.image.base64String" class="w-12 h-12 rounded-full border" alt=""
                         *ngIf="contact.image">
                    <div class="w-12 h-12 text-white bg-primary rounded-full border flex justify-center items-center text-xl font-bold"
                         *ngIf="!contact.image && contact.name?.display"> {{ contact.name?.display[0] }}
                    </div>
                    <div class="flex flex-col -space-y-1">
                        <div class="font-semibold">{{ contact.name?.display ?? 'NA' }}</div>
                        <div class="text-sm">{{ contact.phones?.[0]?.number }}</div>
                        <div class="text-sm">{{ contact?.emails?.[0]?.address ?? 'NA' }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-center items-center h-80 flex-col w-full rounded-2xl bg-card text-center p-5"
             *ngIf="!isContactFetched">
            <mat-spinner></mat-spinner>
            <div class="text-xl font-semibold">{{ t('Please wait while we fetching your contact list.') }}</div>
        </div>
    </div>
</div>