import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuestionResponse1} from "../../../modules/admin/collect-review/models/question-response";

@Component({
    selector: 'app-question-preview',
    templateUrl: './question-preview.component.html'
})
export class QuestionPreviewComponent implements OnInit {
    @Input() question: QuestionResponse1;
    @Input() backgroundColor = '#FFFFFF';
    @Input() redirectThreshold = 0;
    @Input() font = '';
    @Input() isValueSelected = false;
    @Output() sendResponseToParent: EventEmitter<string> = new EventEmitter();
    rating1_10 = Array.from({length: 10}, (_, i) => i + 1);
    iconList = [
        'mat_outline:mood_bad',
        'mat_outline:sentiment_dissatisfied',
        'mat_outline:sentiment_neutral',
        'mat_outline:sentiment_satisfied',
        'mat_outline:sentiment_very_satisfied'
    ]
    rating = 0;

    ngOnInit() {
    }

    sendResponse(rating: number) {
        this.rating = rating;
        this.isValueSelected = true;
        this.sendResponseToParent.emit(rating >= this.redirectThreshold ? 'positive' : 'negative');
    }
}
