import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
    selector: 'rating',
    templateUrl: './rating.component.html'
})
export class RatingComponent implements OnInit {
    @Input() rating: number = 5;
    @Input() size: number = 8;
    @Input() activeStarColor: string = '';
    @Input() inactiveStarColor: string = '';
    @Input() className = '';
    @Input() ratingIcon = 'mat_outline:star';
    @Output() sendResponseToQuestion: EventEmitter<number> = new EventEmitter();

    iconList = [
        'mat_outline:mood_bad',
        'mat_outline:sentiment_dissatisfied',
        'mat_outline:sentiment_neutral',
        'mat_outline:sentiment_satisfied',
        'mat_outline:sentiment_very_satisfied'
    ]

    constructor() {
    }

    ngOnInit(): void {
    }

    getAllRating() {
        return new Array(5);
    }
    getRating() {
        return new Array(Math.floor(this.rating || 0));
    }

    get isHalf(): boolean {
        return this.rating - Math.floor(this.rating || 0) > 0;
    }

    getRemainingRating() {
        if (this.isHalf) {
            return new Array(4 - Math.floor(this.rating || 4));
        } else {
            return new Array(5 - Math.floor(this.rating || 0));
        }
    }
    sendResponse(rating: number) {
        this.sendResponseToQuestion.emit(rating);
    }
}
