import {Route} from '@angular/router';
import {AuthGuard} from 'app/core/auth/guards/auth.guard';
import {NoAuthGuard} from 'app/core/auth/guards/noAuth.guard';
import {LayoutComponent} from 'app/layout/layout.component';
import {InitialDataResolver} from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch: 'full', redirectTo: 'dashboard'},
    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'forgot-password',
                loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)
            },
            {
                path: 'reset-password',
                loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)
            },
            {
                path: 'sign-in',
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)
            },
            {
                path: 'sign-up',
                loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)
            }
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'email-handler',
                loadChildren: () => import('app/modules/auth/email-handler/email-handler.module').then(m => m.AuthEmailHandlerModule)
            }
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data :{
            layout: 'compact'
        },
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('app/modules/admin/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'collect-review',
                loadChildren: () => import('app/modules/admin/collect-review/collect-review.module').then(m => m.CollectReviewModule)
            },
            {
                path: 'analytics',
                loadChildren: () => import('app/modules/admin/analytics/analytics.module').then(m => m.AnalyticsModule)
            },
            {
                path: 'review-marketing',
                loadChildren: () => import('app/modules/admin/review-marketing/review-marketing.module').then(m => m.ReviewMarketingModule)
            },
            {
                path: 'agency-mode',
                loadChildren: () => import('app/modules/admin/agency-mode/agency-mode.module').then(m => m.AgencyModeModule)
            },
            {
                path: 'monitor-reviews',
                loadChildren: () => import('app/modules/admin/monitor-reviews/monitor-reviews.module').then(m => m.MonitorReviewsModule)
            },
            {
                path: 'integrations',
                loadChildren: () => import('app/modules/admin/integration/integration.module').then(m => m.IntegrationModule)
            },
            {
                path: 'un-authorized',
                loadChildren: () => import('app/modules/auth/un-authorized/un-authorized.module').then(m => m.UnAuthorizedModule)
            },
            {
                path: 'error-1',
                loadChildren: () => import('app/modules/auth/error-1/error-1.module').then(m => m.Error1Module)
            },
            {
                path: 'custom-domain',
                loadChildren: () => import('app/modules/admin/custom-domain/custom-domain.module').then(m => m.CustomDomainModule)
            }
        ]
    }
];
