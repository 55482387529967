import {Pipe, PipeTransform} from '@angular/core';
import {DateTime} from "luxon";

@Pipe({
    name: 'timeago'
})
export class TimeagoPipe implements PipeTransform {
    diffMinutes = 0;

    transform(value: string): string {
        const newDateTime = DateTime.fromISO(value, {zone: "utc"}).setZone(DateTime.local().zoneName).toISO();
        const date1: any = new Date(newDateTime);
        const date2: any = new Date();
        this.diffMinutes = Math.ceil(Math.abs(date2 - date1) / (1000 * 60));

        if (this.diffMinutes > 525600) {
            return this.getTimeAgoString(525600, 'year');
        } else if (this.diffMinutes > 43200) {
            return this.getTimeAgoString(43200, 'month');
        } else if (this.diffMinutes > 10080) {
            return this.getTimeAgoString(10080, 'week');
        } else if (this.diffMinutes > 1440) {
            return this.getTimeAgoString(1440, 'day');
        } else if (this.diffMinutes > 60) {
            return this.getTimeAgoString(60, 'hour');
        } else {
            return this.getTimeAgoString(1, 'min');
        }
    }

    getTimeAgoString(factor: number, unit: string) {
        const value = Math.round(this.diffMinutes / factor);
        return value + (value > 1 ? ` ${unit}s` : ` ${unit}`) + ' ago';
    }
}
