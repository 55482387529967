<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>
<fuse-vertical-navigation *ngIf="showSideBar" class="dark bg-gray-900 print:hidden" [appearance]="'compact'"
                          [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'"
                          [navigation]="navigation.compact" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center mt-3 mb-4 hover:cursor-pointer" [routerLink]="'/dashboard'">
            <img class="w-20"
                 [src]="orgSubscription && orgSubscription.whitelabelEnabled && orgSubscription.whitelabelConfigured && whiteLabelProperty && whiteLabelProperty.whiteLabelEnabled ? whiteLabelProperty.customLogoUrl : 'assets/images/logo/logo.svg'"
                 alt="">
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 overflow-x-hidden" *transloco="let t">
    <div class="h-7 text-white w-full" *ngIf="isAppleDevice"></div>
    <div class="py-1 text-white w-full bg-purple-600 text-center cursor-pointer" [matTooltip]="'Click here to verify'"
         (click)="verifyEmailAddress()"
         *ngIf="!isEmailVerified && currentLocation !=null">{{ t('Your account is not verified yet. Please check your email for more details. In case you have not received the email, click here to verify again.') }}
    </div>
    <div class="relative flex flex-0 items-center bg-card w-full h-16 px-4 md:px-6 z-49 shadow">
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')" *ngIf="showSideBar">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <div class="pl-2 cursor-pointer border-0 max-w-96" matRipple [matMenuTriggerFor]="projectsMenu">
            <div class="flex items-center">
                <div class="overflow-hidden -space-y-3">
                    <div class="font-medium leading-6 truncate">{{ currentLocation ? currentLocation.name : t('Add New Location') }}</div>
                    <span class="text-xs truncate" *ngIf="currentLocation">{{ currentLocation.address }} </span>
                </div>
                <div class="flex items-center justify-center pl-2">
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:chevron-down'"></mat-icon>
                </div>
            </div>
            <mat-menu #projectsMenu="matMenu" class="max-h-[500px] w-96">
                <button (click)="addNewLocation()" mat-menu-item
                        [disabled]="locationCredit<1"
                        [ngClass]="{'text-purple-600 ':locationCredit,'text-slate-400 ':!locationCredit}"
                        *ngIf="user && user.roleType == 'SUPER_ADMIN'"
                        class="items-center font-bold text-md">
                    <mat-icon class="icon-size-4"
                              [ngClass]="{'text-purple-600 ':locationCredit,'text-slate-400 ':!locationCredit}"
                              [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                    {{ t('Add New Location') }}
                </button>
                <div class="flex flex-col space-y-2">
                    <div class="flex flex-col -space-y-1 py-1 px-2 hover:bg-gray-100 hover:cursor-pointer"
                         (click)="changeLocation(location)" *ngFor="let location of allLocation;">
                        <div class="font-bold break-words">{{ location.name }}</div>
                        <span class="text-xs mt-1" *ngIf="location.address">{{ location.address }} </span>
                    </div>
                </div>
            </mat-menu>
        </div>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <shortcuts [setupProgressStatus]="setupProgressStatus"
                       *ngIf="orgSubscription && orgSubscription.packageType !='LTD' && showSideBar && setupProgressStatus !=3"
                       class="w-0 md:w-auto"></shortcuts>
            <div class="w-0 md:w-auto invisible md:visible cursor-pointer" [routerLink]="'/agency-mode/pricing'"
                 [matTooltip]="t('Goto Subscription')"
                 *ngIf="user && user.roleType== 'SUPER_ADMIN' && currentLocation && !isMobileDevice">
                <div class="rounded border py-2 px-3 bg-purple-100 font-bold"
                     *ngIf="subscriptionRemainingDays < 50 && orgSubscription && orgSubscription.packageType != 'LTD'">
                    {{ t('Subscription') }} {{ subscriptionRemainingDays >= 0 ? (subscriptionRemainingDays == 0 ? t('will expire today') : t('Will Expire In') + ' ' + subscriptionRemainingDays + ' ' + t('Days')) : t('Expired') }}
                </div>
                <div class="rounded border py-2 px-3 bg-purple-100 font-bold"
                     *ngIf="orgSubscription && orgSubscription.packageType == 'LTD'">
                    {{ t('You’re on LTD Plan') }}
                </div>
            </div>
            <new-features class="w-auto hidden sm:block" *ngIf="currentLocation"></new-features>
            <languages></languages>
            <user [isAppleDevice]="isAppleDevice"></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div class="relative flex flex-0 items-center justify-center w-full h-8 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">
        {{ orgSubscription && orgSubscription.whitelabelEnabled && orgSubscription.whitelabelConfigured && whiteLabelProperty && whiteLabelProperty.whiteLabelEnabled ? whiteLabelProperty.companyName : 'Freshreview' }}
            &copy; {{ currentYear }}
        </span>
    </div>
</div>
<ng-template #subscriptionExpired *transloco="let t">
    <div class="flex flex-col w-full md:w-80 max-h-screen items-center relative">
        <button mat-icon-button (click)="closeDialog()" [tabIndex]="-1" class="absolute -top-3 -right-3 bg-gray-100">
            <mat-icon class="text-current font-bold icon-size-4" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
        <img src="assets/images/logo/logo.svg" alt="" class="w-44">
        <div class="font-bold text-2xl" *ngIf="!isMobileDevice">{{ t("It's time to upgrade") }}</div>
        <div class="mt-2 text-center">
            {{ isMobileDevice ? t('Please login to the webapp with your credentials to resolve this issue') : t('Your subscription has expired.To add another subscription, you need to choose a paid plan') }}
        </div>
        <div class="flex items-center space-x-1 justify-between pt-3" *ngIf="!isMobileDevice">
            <button mat-stroked-button (click)="closeDialog()">No, Thanks</button>
            <button mat-flat-button [color]="'primary'" (click)="goToPlan()">View Plans
            </button>
        </div>
    </div>
</ng-template>

