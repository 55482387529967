import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {SequenceMaster} from "../models/sequence-master";


@Injectable({
    providedIn: 'root'
})
export class SequenceService {
    constructor(private http: HttpClient) {
    }

    get sequenceDetail(): SequenceMaster {
        let sequenceMaster: SequenceMaster;
        const data = JSON.parse(localStorage.getItem('sequence_detail'));
        if (data) {
            sequenceMaster = {
                createdAt: data['createdAt'],
                updatedAt: data['updatedAt'],
                createdBy: data['createdBy'],
                createdByName: data['createdByName'],
                updatedBy: data['updatedBy'],
                updatedByName: data['updatedByName'],
                sequenceMasterId: data['sequenceMasterId'],
                sequenceName: data['sequenceName'],
                locationId: data['locationId'],
                noOfStages: data['noOfStages'],
                seqStageMasterResList: data['seqStageMasterResList']
            };
        }
        return sequenceMaster;
    }

    set sequenceDetail(sequenceDetail: SequenceMaster) {
        localStorage.setItem('sequence_detail', JSON.stringify(sequenceDetail));
    }

    createSequenceMaster(addSequenceRequestObj: { locationId: string, sequenceName: string }) {
        return this.http.post<SequenceMaster>(`${environment.BASE_URL}sequenceMaster/createSequenceMaster`, addSequenceRequestObj);
    }

    disableSequenceMaster(sequenceMasterId: string) {
        return this.http.put(`${environment.BASE_URL}sequenceMaster/disableSequenceMaster/${sequenceMasterId}`, {});
    }

    enrollCustomer(newCustomerRequestObj: any) {
        return this.http.post(`${environment.BASE_URL}sequence/enrollCustomer`, newCustomerRequestObj);
    }

    enrollCustomerList(customerListId: string, locationId: string, sequenceMasterId: string, sendLater: number) {
        return this.http.post(`${environment.BASE_URL}sequence/enrollCustomerList`, {
            customerListId, locationId, sequenceMasterId, sendLater
        });
    }

    pauseSeqTxns(seqTxnIds: string[]) {
        return this.http.post(`${environment.BASE_URL}sequence/pauseSeqTxns`, seqTxnIds);
    }

    updateSequenceName(sequenceMasterId: string, sequenceName: string) {
        return this.http.put(`${environment.BASE_URL}sequenceMaster/updateSequenceName`, {
            sequenceMasterId,
            sequenceName
        });
    }

    generateAITemplate(businessName: string, businessType: string, templateType: string, followupCount: number, language = 'English') {
        return this.http.post(`${environment.BASE_URL}openAI/generateAITemplate`, {
            businessName, businessType, templateType, followupCount, language
        });
    }

    getOldCustomerInfo(locationId: string, status: string, timeDuration: number) {
        return this.http.get(`${environment.BASE_URL}sequence/getOldCustomerInfo?locationId=${locationId}&status=${status}&timeDuration=${timeDuration}`);
    }

    enrollOlderCustomers(locationId: string, sequenceMasterId: string, status: string, duration: number, sendLater: number) {
        return this.http.post(`${environment.BASE_URL}sequence/enrollOlderCustomers`, {
            locationId, sequenceMasterId, status, duration, sendLater
        });
    }
}
