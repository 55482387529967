import {SignUpConfigType} from "../models/sign-up-config-type";
import {Channels} from "./channels";

export const SignUpRoutingConfig: SignUpConfigType [] = [
    {
        signUpFrom: '',
        setupDefaultSequence: true,
        defaultSequenceName: 'Default',
        templateDetail: [
            {channelName: Channels.EMAIL, templateName: 'Generic Email 1', language: 'English'},
            {channelName: Channels.WHATSAPP, templateName: 'review_2', language: 'English'}
        ],
        finalRoute: '/dashboard',
        countryCodeList: ['IN', 'AE', 'SG'],
        otherTemplateDetail: [
            {channelName: Channels.EMAIL, templateName: 'Generic Email 1', language: 'English'},
            {channelName: Channels.SMS, templateName: 'Review Template', language: 'English'}
        ]
    },
    {
        signUpFrom: 'square',
        setupDefaultSequence: true,
        defaultSequenceName: 'Default',
        templateDetail: [
            {channelName: Channels.EMAIL, templateName: 'Generic Email 1', language: 'English'},
            {channelName: Channels.WHATSAPP, templateName: 'review_2', language: 'English'}
        ],
        finalRoute: '',
        countryCodeList: ['IN', 'AE', 'SG'],
        otherTemplateDetail: [
            {channelName: Channels.EMAIL, templateName: 'Generic Email 1', language: 'English'},
            {channelName: Channels.SMS, templateName: 'Review Template', language: 'English'}
        ]
    },
    {
        signUpFrom: 'LTD',
        setupDefaultSequence: true,
        defaultSequenceName: 'Default',
        templateDetail: [
            {channelName: Channels.EMAIL, templateName: 'Generic Email 1', language: 'English'},
            {channelName: Channels.WHATSAPP, templateName: 'review_2', language: 'English'}
        ],
        finalRoute: '/agency-mode/pricing',
        countryCodeList: ['IN', 'AE', 'SG'],
        otherTemplateDetail: [
            {channelName: Channels.EMAIL, templateName: 'Generic Email 1', language: 'English'},
            {channelName: Channels.SMS, templateName: 'Review Template', language: 'English'}
        ]
    }
]

export const FreshreviewFromEmail = 'reviews@email.freshreviewapp.com';
