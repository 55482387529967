import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {ReviewInfo} from '../models/review-info';
import {ConsolidateReview} from '../models/consolidate-review';
import {MySubscriptionInfo} from '../models/my-subscription-info';
import {GoogleRatingInfo} from '../models/google-rating-info';
import {MyOrgSubscription} from "../models/my-org-subscription";
import {Country} from "../models/country";
import {BehaviorSubject} from "rxjs";
import {SequenceMaster} from "../../collect-review/models/sequence-master";


@Injectable({
    providedIn: 'root'
})
export class HomeService {
    public locationSubscriptionInfo = new BehaviorSubject<MySubscriptionInfo>(null);
    currentLocationSubscriptionInfo = this.locationSubscriptionInfo.asObservable();
    public orgSubscriptionInfo = new BehaviorSubject<MyOrgSubscription>(null);
    currentOrgSubscriptionInfo = this.orgSubscriptionInfo.asObservable();
    public nativeDeviceInfo = new BehaviorSubject<boolean>(false);
    currentNativeDeviceInfo = this.nativeDeviceInfo.asObservable();


    constructor(private http: HttpClient) {
    }


    getReviewInformation(locationId: string) {
        return this.http.get<ReviewInfo>(`${environment.BASE_URL}dashboard/getReviewInformation/${locationId}`);
    }


    getConsolidateReviews2(locationId: string) {
        return this.http.get<ConsolidateReview>(`${environment.BASE_URL}dashboard/getConsolidateReviewsV2/${locationId}`);
    }

    getMySubscriptionInfo(locationId: string) {
        return this.http.get<MySubscriptionInfo>(`${environment.BASE_URL}subscription/myLocationSubscription/${locationId}`);
    }

    getMyOrgSubscription() {
        return this.http.get<MyOrgSubscription>(`${environment.BASE_URL}subscription/myOrgSubscription`);
    }

    getGoogleRatingInfo(locationId: string) {
        return this.http.get<GoogleRatingInfo>(`${environment.BASE_URL}dashboard/getGoogleRatingInfo/${locationId}`);
    }

    get locationSubscription(): MySubscriptionInfo {
        let subscription: MySubscriptionInfo;
        const data = JSON.parse(localStorage.getItem('location_subscription'));
        if (data) {
            subscription = {
                locationId: data['locationId'],
                totalUserCredit: data['totalUserCredit'],
                totalSmsCredit: data['totalSmsCredit'],
                totalMmsCredit: data['totalMmsCredit'],
                totalWaCredit: data['totalWaCredit'],
                totalEmailCredit: data['totalEmailCredit'],
                totalWidgetCredit: data['totalWidgetCredit'],
                totalReviewSiteCredit: data['totalReviewSiteCredit'],
                subscriptionEndDate: data['subscriptionEndDate'],
                subscriptionStartDate: data['subscriptionStartDate'],
                extraSmsCredit: data['extraSmsCredit'],
                extraMmsCredit: data['extraMmsCredit'],
                extraWhatsappCredit: data['extraWhatsappCredit'],
                smsFUP: data['smsFUP'],
                emailFUP: data['emailFUP'],
                whatsAppFUP: data['whatsAppFUP'],
                active: data['active'],
                totalCustomerCredit: data['totalCustomerCredit'],
                mmsFup: data['mmsFup'],
                mmsCount: data['mmsCount'],
                extraEmailCredit: data['extraEmailCredit']
            };
        }
        return subscription;
    }

    set locationSubscription(subscription: MySubscriptionInfo) {
        localStorage.setItem('location_subscription', JSON.stringify(subscription));
        this.locationSubscriptionInfo.next(subscription);
    }

    get orgSubscription(): MyOrgSubscription {
        let orgSubscription: MyOrgSubscription;
        const data = JSON.parse(localStorage.getItem('org_subscription'));
        if (data) {
            orgSubscription = {
                subscriptionId: data['subscriptionId'],
                orgId: data['orgId'],
                locationCredit: data['locationCredit'],
                packageName: data['packageName'],
                packageType: data['packageType'],
                subscriptionEndDate: data['subscriptionEndDate'],
                subscriptionStartDate: data['subscriptionStartDate'],
                stripeCustomerId: data['stripeCustomerId'],
                micrositeEnabled: data['micrositeEnabled'],
                businessFunnelEnabled: data['businessFunnelEnabled'],
                artificialIntelligenceEnabled: data['artificialIntelligenceEnabled'],
                emailCustomDomainEnabled: data['emailCustomDomainEnabled'],
                whitelabelEnabled: data['whitelabelEnabled'],
                widgetsEnabled: data['widgetsEnabled'],
                mmsEnabled: data['mmsEnabled'],
                whatsappEnabled: data['whatsappEnabled'],
                zapierIntegration: data['zapierIntegration'],
                nativeIntegration: data['nativeIntegration'],
                pabblyIntegration: data['pabblyIntegration'],
                smsEnabled: data['smsEnabled'],
                monitorReviews: data['monitorReviews'],
                outScraperEnabled: data['outScraperEnabled'],
                customDomainEnabled: data['customDomainEnabled'],
                active: data['active'],
                applicableOn: data['applicableOn'],
                whitelabelConfigured: data['whitelabelConfigured'],
                multiEmailProvider: data['multiEmailProvider']
            };
        }
        return orgSubscription;
    }

    set orgSubscription(orgSubscription: MyOrgSubscription) {
        localStorage.setItem('org_subscription', JSON.stringify(orgSubscription));
        this.orgSubscriptionInfo.next(orgSubscription);
    }

    getAllSupportedCountries() {
        return this.http.get<Country[]>(`${environment.BASE_URL}country/getAllSupportedCountries`);
    }


    getAllSequenceMaster(locationId: string) {
        return this.http.get<SequenceMaster[]>(`${environment.BASE_URL}sequenceMaster/getAllSequenceMaster/${locationId}`);
    }

    set isNativeDevice(isNative: boolean) {
        localStorage.setItem('native_device', JSON.stringify(isNative));
        this.nativeDeviceInfo.next(isNative);
    }

    get isNativeDevice(): boolean {
        return JSON.parse(localStorage.getItem('native_device') || 'false');
    }

    deleteUser(userName, userId, orgId) {
        return this.http.post(`${environment.BASE_URL}userAuth/deleteUser`, {userName, userId, orgId});
    }
}
