import {NgModule} from '@angular/core';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRadioModule} from "@angular/material/radio";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatStepperModule} from "@angular/material/stepper";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {FuseCardModule} from "../../../../@fuse/components/card";
import {QuickSetupComponent} from "./components/quick-setup/quick-setup.component";
import {DashboardRoutingModule} from "./dashboard-routing.module";
import {HomeComponent} from "./components/home/home.component";
import {LocationComponent} from './components/location/location.component';
import {NgApexchartsModule} from "ng-apexcharts";
import {MatMenuModule} from "@angular/material/menu";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {RatingModule} from "../../../../@fuse/components/rating/rating.module";
import {TranslocoModule} from '@ngneat/transloco';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {EditLocationComponent} from './components/edit-location/edit-location.component';
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatRippleModule} from "@angular/material/core";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatTableModule} from "@angular/material/table";
import {MatDialogModule} from "@angular/material/dialog";
import {SharedModule} from "../../../shared/shared.module";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatTabsModule} from "@angular/material/tabs";



@NgModule({
    declarations: [
        QuickSetupComponent,
        HomeComponent,
        LocationComponent,
        EditLocationComponent
    ],

    imports: [
        CommonModule,
        DashboardRoutingModule,
        MatIconModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRadioModule,
        DragDropModule,
        MatStepperModule,
        FuseCardModule,
        NgApexchartsModule,
        MatMenuModule,
        MatTooltipModule,
        MatButtonToggleModule,
        RatingModule,
        TranslocoModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatRippleModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatTableModule,
        MatDialogModule,
        SharedModule,
        MatSidenavModule,
        NgOptimizedImage,
        MatProgressSpinnerModule,
        MatTabsModule
    ],
    exports: [
        LocationComponent
    ],
    providers: [
        SnackbarService
    ]

})
export class DashboardModule {
}
