import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {AddStageResponse} from "../models/add-stage-response";

@Injectable({
    providedIn: 'root'
})
export class StageService {

    constructor(private http: HttpClient) {
    }

    addOrUpdateSeqStage(data: any) {
        return this.http.post<AddStageResponse[]>(`${environment.BASE_URL}seqStageMaster/addOrUpdateSeqStage`, data);
    }
    disableSequenceStageMaster(sequenceStageMasterId:string) {
        return this.http.put(`${environment.BASE_URL}seqStageMaster/disableSequenceStageMaster/${sequenceStageMasterId}`, {});
    }

}
