import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {ChannelTemplate} from "../models/channel-template";

@Injectable({
    providedIn: 'root'
})
export class TemplateService {

    constructor(private http: HttpClient) {
    }

    getAllTemplates(channel: string) {
        return this.http.get<ChannelTemplate[]>(`${environment.BASE_URL}templateMaster/getAllTemplates/${channel}`);
    }

}
