import {Injectable, NgZone} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {

    constructor(
        public snackbar: MatSnackBar,
        private zone: NgZone,
    ) {
    }

    showSnackBar(message: string, duration = 0) {
        const config = new MatSnackBarConfig();
        config.panelClass = ['background-red'];
        config.verticalPosition = 'bottom';
        config.horizontalPosition = 'center';
        if (duration) {
            config.duration = duration;
        }
        this.zone.run(() => {
            this.snackbar.open(message, 'x', config);
        });
    }

}
