/* tslint:disable:max-line-length */
import {FuseNavigationItem} from '@fuse/components/navigation';
import {Roles} from "../../constants/roles";

export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'mat_outline:dashboard',
        link: '/dashboard',
        classes: {
            icon: 'icon-size-4'
        }
    },
    {
        id: 'reviews',
        title: 'Collect Reviews',
        type: 'aside',
        icon: 'mat_outline:send',
        classes: {
            icon: 'icon-size-4'
        },
        children: [
            {
                id: 'review-invites',
                title: 'Invites',
                subtitle: 'Send review requests',
                icon: 'mat_outline:reviews',
                type: 'basic',
                link: '/collect-review/invite-customer'
            },
            {
                id: 'navigation-features.level.0.1-2',
                title: 'Sequences',
                subtitle: 'Automate multi-step review campaigns',
                icon: 'heroicons_outline:lightning-bolt',
                type: 'basic',
                link: '/collect-review/sequence'
            },
            {
                id: 'navigation-features.level.0.1-2',
                title: 'Customer',
                subtitle: 'Manage your customers',
                icon: 'heroicons_outline:user',
                type: 'basic',
                link: '/collect-review/customer'
            },
            {
                id: 'navigation-features.level.0.1-2',
                title: 'Configurations',
                subtitle: 'Customise your review requests',
                icon: 'feather:aperture',
                type: 'basic',
                link: '/collect-review/settings'
            },
            {
                id: 'navigation-features.level.0.1-2',
                title: 'QR Code',
                subtitle: 'Generate QR Code',
                icon: 'heroicons_outline:qrcode',
                type: 'basic',
                link: '/collect-review/qr-code',
                badge: {
                    title: 'New',
                    classes: 'px-2 bg-teal-400 text-black rounded'
                }
            },
            {
                id: 'navigation-features.level.0.1-2',
                title: 'Review Funnel',
                subtitle: 'Review Funnel Landing Page for your business',
                icon: 'mat_outline:contact_page',
                type: 'basic',
                link: '/collect-review/funnel-page',
                badge: {
                    title: 'New',
                    classes: 'px-2 bg-teal-400 text-black rounded'
                },
                roleType: [Roles.SUPER_ADMIN]
            },
            {
                id: 'navigation-features.level.0.1-2',
                title: 'Email Widget',
                subtitle: 'Email signature widget',
                icon: 'heroicons_outline:mail',
                type: 'basic',
                disabled: true,
                badge: {
                    title: 'Coming soon!',
                    classes: 'px-2 bg-teal-400 text-black rounded'
                }
            }
        ]
    },
    {
        id: 'analytics',
        title: 'Analytics',
        type: 'aside',
        icon: 'heroicons_outline:chart-bar',
        classes: {
            icon: 'icon-size-4'
        },
        children: [
            {
                id: 'review-analytics',
                title: 'Review',
                subtitle: 'Review Analytics',
                icon: 'mat_outline:stars',
                type: 'basic',
                link: '/analytics/review'
            },
            {
                id: 'campaign-analytics',
                title: 'Sequence',
                subtitle: 'Sequence Analytics',
                icon: 'mat_outline:campaign',
                type: 'basic',
                link: '/analytics/sequence'
            },
            {
                id: 'funnel-analytics',
                title: 'Review Funnel',
                subtitle: 'Review Funnel Analytics',
                icon: 'mat_outline:contact_page',
                type: 'basic',
                link: '/analytics/funnel'
            }
        ]
    },
    {
        id: 'monitor',
        title: 'Monitor Reviews',
        type: 'aside',
        icon: 'heroicons_outline:annotation',
        badge: {
            title: '',
            classes: 'ml-2 w-3 h-3 bg-teal-400 rounded-full'
        },
        classes: {
            icon: 'icon-size-4'
        },
        children: [
            {
                id: 'navigation-features.level.0.1-1',
                title: 'Google Reviews',
                subtitle: 'View & respond reviews',
                icon: 'heroicons_outline:star',
                type: 'basic',
                link: '/monitor-reviews/my-reviews',
                badge: {
                    title: 'New',
                    classes: 'px-2 bg-teal-400 text-black rounded'
                }
            }
        ]
    },
    {
        id: 'display',
        title: 'Display Reviews',
        type: 'aside',
        icon: 'mat_outline:star_outline',
        classes: {
            icon: 'icon-size-4'
        },
        badge: {
            title: '',
            classes: 'ml-2 w-3 h-3 bg-teal-400 rounded-full'
        },
        children: [
            {
                id: 'navigation-features.level.0.1-1',
                title: 'Google Widgets and Badges',
                subtitle: 'Embed & showcase reviews',
                icon: 'mat_outline:widgets',
                type: 'basic',
                link: '/display-review/review-widget',
                badge: {
                    title: 'New',
                    classes: 'px-2 bg-teal-400 text-black rounded'
                },
                hideOnMobile: true
            },
            {
                id: 'navigation-features.level.0.1-2',
                title: 'Social Post Generator',
                subtitle: 'Generate Social Media Posts',
                icon: 'mat_outline:important_devices',
                type: 'basic',
                link: '/display-review/social-media-post',
                badge: {
                    title: 'New',
                    classes: 'px-2 bg-teal-400 text-black rounded'
                },
                hideOnMobile: true
            },
            {
                id: 'navigation-features.level.0.1-3',
                title: 'Microsite',
                subtitle: 'Micro-site showcasing reviews',
                icon: 'mat_outline:phonelink',
                type: 'basic',
                link: '/display-review/micro-site',
                badge: {
                    title: 'New',
                    classes: 'px-2 bg-teal-400 text-black rounded'
                }
            }
        ]
    },
    {
        id: 'integration',
        title: 'Integrations',
        type: 'aside',
        icon: 'heroicons_outline:puzzle',
        badge: {
            title: '',
            classes: 'ml-2 w-3 h-3 bg-teal-400 rounded-full'
        },
        classes: {
            icon: 'icon-size-4'
        },
        roleType: [Roles.SUPER_ADMIN],
        children: [
            {
                id: 'native',
                title: 'Native',
                subtitle: 'Native Integrations',
                icon: 'heroicons_outline:link',
                type: 'basic',
                link: '/integrations/native',
                badge: {
                    title: 'New',
                    classes: 'px-2 bg-teal-400 text-black rounded'
                }
            },
            {
                id: 'zapier',
                title: 'Zapier',
                subtitle: 'Zapier Integrations',
                icon: 'heroicons_outline:link',
                type: 'basic',
                link: '/integrations/zapier',
                badge: {
                    title: 'New',
                    classes: 'px-2 bg-teal-400 text-black rounded'
                }
            }, {
                id: 'pabbly',
                title: 'Pabbly',
                subtitle: 'Pabbly Integrations',
                icon: 'heroicons_outline:link',
                type: 'basic',
                link: '/integrations/pabbly',
                badge: {
                    title: 'Coming Soon',
                    classes: 'px-2 bg-teal-400 text-black rounded'
                }
            }

        ]
    },
    {
        id: 'settings',
        title: 'Agency Mode',
        type: 'aside',
        icon: 'heroicons_solid:cog',
        classes: {
            icon: 'icon-size-4'
        },
        badge: {
            title: '',
            classes: 'ml-2 w-3 h-3 bg-teal-400 rounded-full'
        },
        roleType: [Roles.SUPER_ADMIN, Roles.ADMIN],
        children: [
            {
                id: 'manage-location',
                title: 'Manage Locations',
                subtitle: 'Manage your locations here',
                icon: 'heroicons_solid:location-marker',
                type: 'basic',
                link: '/agency-mode/manage-location',
                roleType: [Roles.SUPER_ADMIN],
            }, {
                id: 'manage-user',
                title: 'Manage Users',
                subtitle: 'Manage Users',
                icon: 'heroicons_solid:users',
                type: 'basic',
                link: '/agency-mode/user-list',
                roleType: [Roles.SUPER_ADMIN, Roles.ADMIN],
            }, {
                id: 'sms-provider',
                title: 'Custom SMS Provider',
                subtitle: 'Setup SMS Provider',
                icon: 'mat_outline:sms',
                type: 'basic',
                link: '/agency-mode/sms-provider',
                roleType: [Roles.SUPER_ADMIN],
            }, {
                id: 'custom-provider',
                title: 'Custom EMAIL Provider',
                subtitle: 'Setup EMAIL Provider',
                icon: 'mat_outline:mail',
                type: 'basic',
                link: '/agency-mode/email-provider',
                roleType: [Roles.SUPER_ADMIN],
                orgKey: 'emailCustomDomainEnabled'
            }, {
                id: 'openai-provider',
                title: 'Custom Open AI Provider',
                subtitle: 'Setup Open AI Provider',
                icon: 'heroicons_outline:sparkles',
                type: 'basic',
                link: '/agency-mode/open_ai-provider',
                roleType: [Roles.SUPER_ADMIN],
            },
            {
                id: 'google-review-provider',
                title: 'Custom Google Review Provider',
                subtitle: 'Setup Google Review Provider',
                icon: 'mat_solid:reviews',
                type: 'basic',
                link: '/agency-mode/google-review-provider',
                roleType: [Roles.SUPER_ADMIN],
            },
            {
                id: 'email-setup',
                title: 'Custom Domain',
                subtitle: 'Configure your custom domain',
                icon: 'mat_outline:language',
                type: 'basic',
                link: '/custom-domain/domain-list',
                badge: {
                    title: 'New',
                    classes: 'px-2 bg-teal-400 text-black rounded'
                },
                orgKey: 'customDomainEnabled',
                roleType: [Roles.SUPER_ADMIN],
            },
            {
                id: 'white-label',
                title: 'White Label',
                subtitle: 'White Label',
                badge: {
                    title: '',
                    classes: 'ml-2 w-3 h-3 bg-teal-400 rounded-full'
                },
                icon: 'mat_solid:bookmarks',
                type: 'basic',
                link: '/agency-mode/white-label',
                roleType: [Roles.SUPER_ADMIN],
                orgKey: 'whitelabelEnabled'
            }, {
                id: 'subscription',
                title: 'Subscription',
                subtitle: 'Manage Subscription',
                icon: 'mat_outline:inventory',
                type: 'basic',
                link: '/agency-mode/pricing',
                roleType: [Roles.SUPER_ADMIN],
                hideOnMobile: true
            }, {
                id: 'affiliate-program',
                title: 'Affiliate Program',
                subtitle: 'Affiliate Program',
                icon: 'mat_outline:monetization_on',
                type: 'basic',
                disabled: true,
                // externalLink: true,
                // link: 'https://affiliates.freshreview.co/login',
                roleType: [Roles.SUPER_ADMIN],
                hideOnMobile: true,
                // target: '_blank'
                tooltip: 'Coming Soon'
            }
        ]
    },
];
