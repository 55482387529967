import {Component, Input} from '@angular/core';
import {ReviewsPerScore} from "../../models/review-per-score";
import {RatingInfo} from "../../models/rating-info";

@Component({
    selector: 'review-percentage',
    templateUrl: './review-percentage.component.html'
})
export class ReviewPercentageComponent {
    @Input() reviewsPerScore: ReviewsPerScore;
    @Input() totalReviews: number;
    @Input() extraClass: string = 'my-1';
}
