import {
    Directive,
    HostListener,
    HostBinding,
    Output,
    EventEmitter
} from "@angular/core";
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

export interface FileHandle {
    file: File,
    url: SafeUrl
}

@Directive({
    selector: "[appDragAndDrop]"
})
export class DragDropDirective {
    @Output() files: EventEmitter<FileHandle[]> = new EventEmitter();

    // Bind a CSS class to the element when dragging over it
    @HostBinding('class.drag-over') isDragging = false;

    constructor(private sanitizer: DomSanitizer) {
    }

    // Add class when dragging over
    @HostListener("dragover", ["$event"]) public onDragOver(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.isDragging = true; // Apply the CSS class
    }

    // Remove class when drag leaves the element
    @HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.isDragging = false; // Remove the CSS class
    }

    // Handles the drop event
    @HostListener('drop', ['$event'])
    public onDrop(evt: DragEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.isDragging = false;

        let files: FileHandle[] = [];
        const dataTransferItems = evt.dataTransfer?.files;

        if (dataTransferItems) {
            for (let i = 0; i < dataTransferItems.length; i++) {
                const file = dataTransferItems[i];

                // Check if the file is an image and its size is less than 10 MB
                if (file.type.startsWith('image/') && file.size <= 10_000_000) { // 10 MB = 10,000,000 bytes
                    const url = this.sanitizer.bypassSecurityTrustUrl(
                        window.URL.createObjectURL(file)
                    );
                    files.push({ file, url });
                } else if (file.size > 10_000_000) {
                    console.warn(`File ${file.name} exceeds the 10 MB size limit`);
                } else {
                    console.warn(`File ${file.name} is not a valid image`);
                }
            }
        }

        // Emit valid image files under the size limit
        if (files.length > 0) {
            this.files.emit(files);
        } else {
            console.warn('No valid images were dropped');
        }
    }

}
