import {Injectable} from '@angular/core';
import {AuthService} from 'app/core/auth/auth.service';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {AllLocationResponse} from '../models/location.types';
import {BehaviorSubject, Observable} from 'rxjs';
import {DateTime, Interval} from "luxon";

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    public locationDetail = new BehaviorSubject<AllLocationResponse>(null);
    currentLocation = this.locationDetail.asObservable();
    public allLocationDetail = new BehaviorSubject<AllLocationResponse[]>([]);
    allLocations = this.allLocationDetail.asObservable();

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient) {
    }

    fetchAllLocationForOrgUser(): Observable<AllLocationResponse[]> {
        return this.httpClient.get<AllLocationResponse[]>(`${environment.BASE_URL}location/listAllLocationForOrgUser/${this.authService.businessUser.orgId}/${this.authService.businessUser.userId}`);
    }

    get currentLocationDetail(): AllLocationResponse {
        return this.getLocalStorage('current_location');
    }

    set currentLocationDetail(location: AllLocationResponse) {
        localStorage.setItem('current_location', JSON.stringify(location));
        this.locationDetail.next(location);
    }

    get allLocation() {
        return this.getLocalStorage('all_location');
    }

    set allLocation(location: AllLocationResponse[]) {
        localStorage.setItem('all_location', JSON.stringify(location));
        this.allLocationDetail.next(location);
    }

    getLocalStorage(key): any {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (e) {
            console.error('Error getting data local storage Data', e);
            return null;
        }
    }

    disableLocation(locationId: string) {
        return this.httpClient.delete(`${environment.BASE_URL}location/disableLocation/${locationId}`);
    }

    getSetupDelay(): number {
        let setupDelayInMinutes: number;
        const setupLocationId = localStorage.getItem('setupLocationId');
        const setupDate = localStorage.getItem('setupDate');
        if (setupDate && this.currentLocationDetail && setupLocationId == this.currentLocationDetail.locationId) {
            setupDelayInMinutes = Interval.fromDateTimes(DateTime.fromISO(setupDate), DateTime.now()).length('minutes');
        } else {
            setupDelayInMinutes = 8;
        }
        return setupDelayInMinutes;
    }
}
