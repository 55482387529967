import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslocoService} from "@ngneat/transloco";
import {SettingService} from "../../../modules/admin/collect-review/services/setting.service";
import {MatDialog} from "@angular/material/dialog";
import {MatStepper} from "@angular/material/stepper";
import {MediaType} from "../../models/media-type";
import {Channels} from "../../constants/channels";
import {FileHandle} from "../../directives/drag-drop-directive";

@Component({
    selector: 'app-mms-media-file',
    templateUrl: './mms-media-file.component.html'
})
export class MmsMediaFileComponent implements OnInit {
    @Input() locationId: string = '';
    @Input() mediaType: string = '';
    @ViewChild('mediaFileDialog') mediaFileDialog: TemplateRef<any>;
    @ViewChild('matStepper') matStepper: MatStepper;
    @Output() mediaFileChanged = new EventEmitter<MediaType>();
    @Output() getMediaFiles = new EventEmitter<MediaType[]>();
    mediaFile: File;
    mmsSettingsForm: UntypedFormGroup;
    isAPICallComplete = true;
    mmsDataList: MediaType[] = [];
    selectedMediaFileIndex = -1;
    mediaTypes = [
        {name: 'Image', icon: 'image', type: 'image/png'},
        {name: 'Audio', icon: 'audiotrack', type: 'audio/mp4'},
        {name: 'Video', icon: 'video_library', type: 'video/mp4'},
    ]
    fileSelected = false;


    constructor(private formBuilder: FormBuilder,
                private snackBar: MatSnackBar,
                private translocoService: TranslocoService,
                private settingService: SettingService,
                private matDialog: MatDialog) {
    }

    ngOnInit() {
        this.mmsSettingsForm = this.formBuilder.group({
            fileName: ['', [Validators.required]],
            propertyValue: ['', [Validators.required]],
            groupName: [Channels.MMS, [Validators.required]],
            fileType: ['', [Validators.required]],
            propertyKey: ['mediaLinks', [Validators.required]],
            locationId: [this.locationId, [Validators.required]]
        });
        this.getMMSDataList();
        if (this.mediaType) {
            this.mediaTypes = this.mediaTypes.filter(mediaType => mediaType.type == this.mediaType);
        }
    }

    getMMSDataList() {
        this.settingService.getMMSDataList(this.locationId).subscribe({
            next: (res: any) => {
                const mmsDataList = JSON.parse(res.propertyValue);
                if (mmsDataList && mmsDataList.mmsDataList && mmsDataList.mmsDataList.length > 0) {
                    this.mmsDataList = mmsDataList.mmsDataList;
                    this.getMediaFiles.emit(this.mmsDataList);
                }
            },
            error: (error) => console.log(error.error)
        });
    }

    onFileSelect(event) {
        if (event.target.files.length > 0) {
            this.setMediaFilePath(event.target.files[0]);
        }
    }

    setMediaFilePath(file: File) {
        this.mediaFile = file;
        if ((this.mediaFile.size / (1024 * 1024)) > 5) {
            this.snackBar.open(this.translocoService.translate('Media File Size Less Than 5 MB'), '', {
                duration: 5000,
            });
            this.mediaFile = null;
            return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            this.mmsSettingsForm.controls['propertyValue'].setValue(e.target?.result as string);
            this.mmsSettingsForm.markAsDirty();
        }
        reader.readAsDataURL(this.mediaFile);
        this.mmsSettingsForm.controls['fileName'].setValue(this.mediaFile.name);
        this.mmsSettingsForm.controls['fileType'].setValue(this.mediaFile.type.split('/')[0]);
        this.fileSelected = true;
        this.matStepper.next();
    }

    submit() {
        this.isAPICallComplete = false;
        this.settingService.uploadMMSMedia(this.mmsSettingsForm.value).subscribe({
            next: (res: any) => {
                this.isAPICallComplete = true;
                this.snackBar.open(this.translocoService.translate('Media File Uploaded'), '', {duration: 3000,});
                this.closeDialog();
                this.getMMSDataList();
            },
            error: (error) => {
                console.error(error.error);
                this.isAPICallComplete = true
                this.snackBar.open(error.error.error, '', {duration: 3000,});
                this.closeDialog();
            }
        });
    }

    openMediaDialog() {
        this.mediaFile = null;
        this.mmsSettingsForm.reset();
        this.mmsSettingsForm.controls['locationId'].setValue(this.locationId);
        this.mmsSettingsForm.controls['propertyKey'].setValue('mediaLinks');
        this.mmsSettingsForm.controls['groupName'].setValue(Channels.MMS);
        this.mmsSettingsForm.markAsUntouched();
        this.matDialog.open(this.mediaFileDialog, {width: '400px'});
    }

    closeDialog() {
        this.matDialog.closeAll();
    }

    selectMediaType(type: string, imageFileInput: HTMLInputElement) {
        imageFileInput.accept = type;
        imageFileInput.click();
    }

    selectMedia(mediaFile: MediaType, i: number) {
        this.selectedMediaFileIndex = i;
        this.mediaFileChanged.emit(mediaFile);
    }

    filesDropped(files: FileHandle[]) {
        if (files.length > 0) {
            this.setMediaFilePath(files[0].file);
        }
    }
}
