import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {User} from "../../../../../shared/models/user.types";
import {AuthService} from "../../../../../core/auth/auth.service";
import {ReviewInfo} from "../../models/review-info";
import {HomeService} from "../../services/home.service";
import {ApexAxisChartSeries, ApexChart, ApexTitleSubtitle, ApexTooltip, ApexXAxis, ChartComponent} from "ng-apexcharts";
import {ConsolidateReview} from "../../models/consolidate-review";
import {GoogleRatingInfoDetail} from "../../models/google-rating-info";
import {MySubscriptionInfo} from "../../models/my-subscription-info";
import {LocationService} from "../../../../../shared/services/location.service";
import {AllLocationResponse} from "../../../../../shared/models/location.types";
import {Router} from "@angular/router";
import {MyOrgSubscription} from "../../models/my-org-subscription";
import {DateTime} from "luxon";
import {PropertyGroup} from "../../../../../shared/constants/property-group";
import {SettingService} from "../../../collect-review/services/setting.service";
import {ShortcutsService} from "../../../../../layout/common/shortcuts/shortcuts.service";
import {Device} from "@capacitor/device";
import {TranslocoService} from "@ngneat/transloco";
import {QuickSetupService} from "../../services/quick-setup.service";
import {LTDService} from "../../../agency-mode/services/ltd.service";
import {WhiteLabelPropertyService} from "../../../agency-mode/services/white-label-property.service";
import {SurveyCategoryCount} from "../../models/survey-category-count";

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    title: ApexTitleSubtitle;
    colors: string[],
    tooltip: ApexTooltip
};

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
    @ViewChild("chart") chart: ChartComponent;
    public chartOptions: Partial<ChartOptions>;
    currentUser: User;
    reviewInfo: ReviewInfo;
    consolidateReviews: ConsolidateReview;
    googleRatingInfoBefore: GoogleRatingInfoDetail;
    googleRatingInfoAfter: GoogleRatingInfoDetail;
    mySubscriptionInfo: MySubscriptionInfo;
    currentLocation: AllLocationResponse;
    orgSubscription: MyOrgSubscription;
    isAPICallComplete = false;
    isMobileDevice = false;
    isAppleDevice = false;
    setupDelayInMinutes = 0;
    windowWidth = window.innerWidth;
    setupProgressStatus = 1;
    smsConfigured = false;
    emailConfigured = false;
    companyName = 'Freshreview';
    validSubscriptionForAnalytics = false;
    npsSurveyCategory: SurveyCategoryCount;
    csatSurveyCategory: SurveyCategoryCount;


    constructor(private authService: AuthService,
                private homeService: HomeService,
                private locationService: LocationService,
                private router: Router,
                private settingService: SettingService,
                private shortcutService: ShortcutsService,
                private translocoService: TranslocoService,
                private quickSetupService: QuickSetupService,
                private ltdService: LTDService,
                private whiteLabelPropertyService: WhiteLabelPropertyService
    ) {
    }

    ngOnInit() {
        if (!this.router.url.includes('dashboard')) {
            this.router.navigate(['dashboard']);
        }
        const logDeviceInfo = async () => {
            const info = await Device.getInfo();
            this.isAppleDevice = info.platform == 'ios';
        };
        logDeviceInfo().then(info => {
            if (info != undefined) {
                this.isAppleDevice = info['platform'] == 'ios';
            }
        });
        this.homeService.currentNativeDeviceInfo.subscribe(isNative => this.isMobileDevice = isNative);
        this.chartOptions = {
            colors: ['#6654e2', '#108112'],
            series: [
                {
                    name: "Invite Review Ratio",
                    data: []
                }
            ],
            chart: {
                height: 350,
                type: "bar",
                toolbar: {
                    show: false
                }
            },
            xaxis: {
                categories: []
            },
            tooltip: {
                followCursor: true,
                theme: 'dark'
            },
        };
        this.currentUser = this.authService.businessUser;
        this.homeService.currentOrgSubscriptionInfo.subscribe(info => this.orgSubscription = info);
        this.locationService.currentLocation.subscribe(location => {
            if (location) {
                this.currentLocation = location;
            } else {
                const allLocation = this.locationService.allLocation;
                if (allLocation && this.orgSubscription && this.orgSubscription.active) {
                    if (allLocation.length == 1) {
                        this.locationService.currentLocationDetail = allLocation[0];
                    } else if (allLocation.length > 1) {
                        this.router.navigate(['/dashboard/select-location']);
                    } else {
                        this.router.navigate(['/dashboard/quick-setup']);
                    }
                }
            }
        });
        if (this.currentLocation && this.currentLocation.locationId) {
            this.setupDelayInMinutes = this.locationService.getSetupDelay();
            if (this.setupDelayInMinutes > 7) {
                this.homeService.getGoogleRatingInfo(this.currentLocation.locationId).subscribe({
                    next: (res) => {
                        if (res.googleReviewInfoList.length) {
                            let beforeRating = res.googleReviewInfoList.filter(r => r.type === 'Before');
                            let afterRating = res.googleReviewInfoList.filter(r => r.type === 'After');
                            if (beforeRating.length) {
                                this.googleRatingInfoBefore = beforeRating[0];
                            }
                            if (afterRating.length) {
                                this.googleRatingInfoAfter = afterRating[0];
                            }
                        }
                    }, error: (error) => console.error(error.error)
                });

                if (this.homeService.orgSubscription && this.homeService.orgSubscription.whitelabelEnabled && this.whiteLabelPropertyService && this.whiteLabelPropertyService.whiteLabelProperty && this.whiteLabelPropertyService.whiteLabelProperty.whiteLabelEnabled) {
                    this.companyName = this.whiteLabelPropertyService.whiteLabelProperty.companyName;
                }
                this.homeService.getReviewInformation(this.currentLocation.locationId).subscribe({
                    next: (res) => {
                        this.reviewInfo = res;
                        const googleReview = res.reviewInfoDetailList.find(site => site.siteName === 'Google');
                        this.reviewInfo.reviewInfoDetailList = [
                            ...(googleReview ? [googleReview] : []),
                            ...res.reviewInfoDetailList.filter(site => !['Freshreview', 'Google'].includes(site.siteName))
                        ];
                    },
                    error: (error) => console.error(error.error)
                });
                ['NPS', 'CSAT'].forEach(category => {
                    this.homeService.getQuestionCount(this.currentLocation.locationId, category).subscribe({
                        next: (res) => {
                            if (category == 'NPS') {
                                this.npsSurveyCategory = res;
                            } else {
                                this.csatSurveyCategory = res;
                            }
                        },
                        error: (error) => console.error(error.error)
                    });
                })
            }
            this.homeService.getConsolidateReviews2(this.currentLocation.locationId).subscribe({
                next: (res) => {
                    this.consolidateReviews = res;
                    localStorage.setItem('customerEnrolled', res.totalInvites.toString());
                    this.checkCustomerEnroll();
                    if (res && res.inviteCount && !this.isMobileDevice) {
                        this.chart.updateOptions({
                            xaxis: {
                                categories: Object.keys(this.consolidateReviews.inviteCount).map(value => DateTime.fromISO(value).toFormat('dd MMM yyyy'))
                            },
                            series: [
                                {
                                    name: this.translocoService.translate('Invited'),
                                    data: Object.values(this.consolidateReviews.inviteCount)
                                },
                                {
                                    name: this.translocoService.translate('Scheduled'),
                                    data: Object.values(this.consolidateReviews.scheduledCount)
                                }
                            ]
                        });
                    }
                }, error: (error) => console.error(error.error)
            });
            this.homeService.getMySubscriptionInfo(this.currentLocation.locationId).subscribe({
                next: (res) => {
                    this.isAPICallComplete = true;
                    this.mySubscriptionInfo = res;
                    this.homeService.locationSubscription = res;
                    this.validSubscriptionForAnalytics = DateTime.fromISO(res.analyticsCutoffTime).diff(DateTime.now(), 'days').days > 0;
                },
                error: (error) => {
                    this.isAPICallComplete = true;
                    this.mySubscriptionInfo = null;
                    this.homeService.locationSubscription = null;
                }
            });
        }
        if (this.ltdService.providers) {
            this.smsConfigured = !!this.ltdService.providers.find(p => p.providerType == 'SMS');
            this.emailConfigured = !!this.ltdService.providers.find(p => p.providerType == 'EMAIL');
        }
        this.setSetupProgress();
        localStorage.removeItem('dateFilterFromDashboard');
        this.getPreferredSequence();
    }

    setSetupProgress() {
        if (this.currentLocation) {
            this.settingService.getAllProperties(this.currentLocation.locationId, PropertyGroup.GENERAL).subscribe(properties => {
                const locationSetup = properties.filter(property => property.propertyKey == 'locationSetup' && property.propertyValue == 'true');
                if (locationSetup.length > 0) {
                    this.setupProgressStatus = 1;
                }
                const sequenceSetup = properties.filter(property => property.propertyKey == 'sequenceSetup' && property.propertyValue == 'true');
                if (sequenceSetup.length > 0) {
                    this.setupProgressStatus = 2;
                }
                const customerEnrolled = properties.filter(property => property.propertyKey == 'customerEnrolled' && property.propertyValue == 'true');
                if (customerEnrolled.length > 0) {
                    this.setupProgressStatus = 3;
                }
                if (this.setupProgressStatus < 3) {
                    this.checkCustomerEnroll();
                }
                this.shortcutService.currentSetupProgressDetail = this.setupProgressStatus;
            });
        }
    }

    editLocation() {
        this.quickSetupService.reviewSiteMaster().subscribe({
            next: (res) => {
                localStorage.setItem('review_site_master', JSON.stringify(res))

            }, error: (error) => console.log(error)
        });
        this.quickSetupService.getReviewSiteByLocation(this.currentLocation.locationId).subscribe({
            next: (res) => {
                localStorage.setItem('review_site_location', JSON.stringify(res));
                this.router.navigate(['dashboard/modify-location']);
            }, error: (error) => console.log(error)
        });
    }

    checkCustomerEnroll() {
        if (this.consolidateReviews && this.consolidateReviews.totalInvites) {
            const propertiesData = {
                "groupName": PropertyGroup.GENERAL,
                "locPropertyDetailList": [{
                    "propertyKey": "customerEnrolled",
                    "propertyValue": true
                }],
                "locationId": this.currentLocation.locationId
            }
            this.settingService.createOrUpdateProperties(propertiesData).subscribe(res => {
                this.setupProgressStatus = 3;
                this.shortcutService.currentSetupProgressDetail = 3;
            });
        }
    }

    goToReviewAnalytics(dateFilter = 'all_time') {
        localStorage.setItem('dateFilterFromDashboard', dateFilter);
        this.router.navigateByUrl('/analytics/review');
    }

    getPreferredSequence() {
        if (this.currentLocation) {
            this.settingService.getAllProperties(this.currentLocation.locationId, PropertyGroup.GENERAL).subscribe(properties => {
                const preferredSequence = properties.find(property => property.propertyKey == 'preferredSequence');
                if (preferredSequence) {
                    localStorage.setItem('preferredSequence', preferredSequence.propertyValue);
                }
            });
        }
    }
}
