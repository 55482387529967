import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {MediaFile} from "../models/media-file";

@Injectable({
    providedIn: 'root'
})
export class MediaService {

    constructor(private http: HttpClient) {
    }

    uploadFileToS3(formData: FormData) {
        return this.http.post(`${environment.BASE_URL}aws/uploadFileToS3`, formData);
    }

    createNewMedia(data: any) {
        return this.http.post(`${environment.BASE_URL}media/v1`, data);
    }

    getAllMedia(locationId: string) {
        return this.http.get<MediaFile[]>(`${environment.BASE_URL}media/v1/getAllByLocationId/${locationId}`);
    }

    disableMedia(mediaId: string) {
        return this.http.post(`${environment.BASE_URL}media/v1/disable/${mediaId}`, {});
    }
}
