import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {Subject, takeUntil} from 'rxjs';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher';
import {FuseNavigationService, FuseVerticalNavigationComponent} from '@fuse/components/navigation';
import {Navigation} from 'app/core/navigation/navigation.types';
import {NavigationService} from 'app/core/navigation/navigation.service';
import {LocationService} from "../../../../shared/services/location.service";
import {AllLocationResponse} from "../../../../shared/models/location.types";
import {HomeService} from "../../../../modules/admin/dashboard/services/home.service";
import {MyOrgSubscription} from "../../../../modules/admin/dashboard/models/my-org-subscription";
import {DateTime, Interval} from "luxon";
import {MatDialog} from "@angular/material/dialog";
import {ShortcutsService} from "../../../common/shortcuts/shortcuts.service";
import {Device} from "@capacitor/device";
import {AuthService} from "../../../../core/auth/auth.service";
import {User} from "../../../../shared/models/user.types";
import {AuthUtils} from "../../../../core/auth/auth.utils";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslocoService} from "@ngneat/transloco";
import {WhiteLabelProperty} from "../../../../modules/admin/agency-mode/models/white-label-property";
import {WhiteLabelPropertyService} from "../../../../modules/admin/agency-mode/services/white-label-property.service";
import {FaviconService} from "../../../../shared/services/favicon.service";


@Component({
    selector: 'compact-layout',
    templateUrl: './compact.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CompactLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('subscriptionExpired') subscriptionExpired: TemplateRef<any>;
    @ViewChild('selectLocationDialog') selectLocationDialog: TemplateRef<any>;
    isScreenSmall: boolean;
    navigation: Navigation;
    private unsubscribeAll: Subject<any> = new Subject<any>();
    showSideBar = false;
    currentLocation: AllLocationResponse;
    allLocation: AllLocationResponse[];
    locationCredit = 0;
    orgSubscription: MyOrgSubscription;
    setupProgressStatus = 3;
    isMobileDevice = false;
    isAppleDevice = false;
    user: User;
    isEmailVerified = false;
    private channel: BroadcastChannel;
    message = '';
    emailVerifyRequestDate = '';
    whiteLabelProperty: WhiteLabelProperty;

    constructor(private router: Router,
                private navigationService: NavigationService,
                private fuseMediaWatcherService: FuseMediaWatcherService,
                private fuseNavigationService: FuseNavigationService,
                private locationService: LocationService,
                private homeService: HomeService,
                private matDialog: MatDialog,
                private shortcutService: ShortcutsService,
                private authService: AuthService,
                private snackBar: MatSnackBar,
                private translocoService: TranslocoService,
                private whiteLabelPropertyService: WhiteLabelPropertyService,
                private faviconService: FaviconService) {
        this.channel = new BroadcastChannel('shared_channel');
    }

    get currentYear(): number {
        return new Date().getFullYear();
    }

    ngOnInit(): void {
        this.emailVerifyRequestDate = localStorage.getItem("emailVerifyRequestDate") || '';
        this.user = this.authService.businessUser;
        this.homeService.currentNativeDeviceInfo.subscribe(isNative => {
            this.isMobileDevice = isNative;
            if (!isNative && this.homeService.isNativeDevice) {
                this.homeService.nativeDeviceInfo.next(this.homeService.isNativeDevice);
            }
        });
        this.homeService.currentOrgSubscriptionInfo.subscribe(orgSubscription => {
            if (orgSubscription) {
                this.orgSubscription = orgSubscription;
            } else {
                if (this.homeService.orgSubscription) {
                    this.homeService.orgSubscriptionInfo.next(this.homeService.orgSubscription);
                    this.orgSubscription = this.homeService.orgSubscription;
                }
            }
            this.locationCredit = this.orgSubscription && this.orgSubscription.active ? this.orgSubscription.locationCredit : 0;
        });
        // Subscribe to navigation data
        this.navigationService.navigation$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
                this.navigation.compact = navigation.compact.filter((item) => {
                    return (!item.roleType || (item.roleType.includes(this.user.roleType))) && (!item.orgKey || (this.orgSubscription && this.orgSubscription[item.orgKey]));
                }).map(item => ({
                    ...item,
                    children: item.children ? item.children.filter(subItem => (!subItem.roleType || (subItem.roleType.includes(this.user.roleType))) && (!subItem.orgKey || (this.orgSubscription && this.orgSubscription[subItem.orgKey]))) : []
                }));
            });
        this.fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(({matchingAliases}) => {
                this.isScreenSmall = !matchingAliases.includes('md');
            });
        this.locationService.currentLocation.subscribe(res => {
            this.showSideBar = false;
            this.currentLocation = null;
            if (res && res.locationId) {
                this.currentLocation = res;
            } else {
                if (this.locationService.currentLocationDetail && this.locationService.currentLocationDetail.locationId) {
                    this.locationService.locationDetail.next(this.locationService.currentLocationDetail);
                    this.currentLocation = this.locationService.currentLocationDetail;
                }
            }
            this.showSideBar = !!(this.currentLocation && this.currentLocation.locationId);
        });

        this.locationService.allLocations.subscribe(res => {
            if (res && res.length) {
                this.allLocation = res;
            } else {
                if (this.locationService.allLocation && this.locationService.allLocation.length) {
                    this.locationService.allLocationDetail.next(this.locationService.allLocation);
                    this.allLocation = this.locationService.allLocation;
                }
            }
        });
        const logDeviceInfo = async () => {
            const info = await Device.getInfo();
            this.isAppleDevice = info.platform == 'ios';
        };
        logDeviceInfo().then(info => {
            if (info != undefined) {
                this.isAppleDevice = info['platform'] == 'ios';
            }
        });
        this.isEmailVerified = AuthUtils.IsEmailVerified(this.authService.accessToken);
        this.channel.onmessage = (event) => {
            if (event.data.data == 'Token is refreshed.') {
                this.isEmailVerified = AuthUtils.IsEmailVerified(this.authService.idToken);
                this.authService.accessToken = this.authService.idToken;
            }
        };
        if (this.orgSubscription && this.orgSubscription.whitelabelEnabled) {
            this.whiteLabelPropertyService.currentWhiteLabelPropertyInfo.subscribe(property => {
                this.whiteLabelProperty = property;
                if (property) {
                    if (property.whiteLabelEnabled) {
                        this.faviconService.setFavicon(property.faviconUrl, property.titleText);
                    } else {
                        this.faviconService.setFavicon('favicon-16x16.png');
                    }
                } else {
                    const data = this.whiteLabelPropertyService.whiteLabelProperty;
                    if (data) {
                        this.whiteLabelPropertyService.whiteLabelProperty = this.whiteLabelPropertyService.whiteLabelProperty;
                    } else {
                        this.faviconService.setFavicon('favicon-16x16.png');
                    }
                }
            })
        } else {
            this.faviconService.setFavicon('favicon-16x16.png');
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }

    toggleNavigation(name: string): void {
        const navigation = this.fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);
        if (navigation) {
            navigation.toggle();
        }
    }

    ngAfterViewInit() {
        const redirectURL = this.router.url;
        if (!redirectURL.includes('agency-mode') && redirectURL != '/dashboard/select-location' && redirectURL != '/dashboard/quick-setup') {
            const orgSubscription = JSON.parse(localStorage.getItem('org_subscription'));
            if (!orgSubscription || !orgSubscription['active']) {
                this.matDialog.open(this.subscriptionExpired, {
                    hasBackdrop: true,
                    disableClose: true,
                    backdropClass: 'opacity-5',
                });
            }
        }
        setTimeout(() => {
            this.shortcutService.currentSetupProgress.subscribe(progress => {
                this.setupProgressStatus = progress;
            });
            this.setupProgressStatus = this.shortcutService.currentSetupProgressDetail;
        }, 100);
    }


    closeDialog() {
        this.matDialog.closeAll();
    }

    goToPlan() {
        this.matDialog.closeAll();
        this.router.navigateByUrl('/agency-mode/pricing');
    }

    verifyEmailAddress() {
        if (this.emailVerifyRequestDate && DateTime.now().diff(DateTime.fromISO(this.emailVerifyRequestDate), 'minutes').minutes < 1) {
            this.snackBar.open(this.translocoService.translate('Please try after sometime.'), '', {duration: 3000});
            return;
        }
        this.emailVerifyRequestDate = new Date().toISOString();
        localStorage.setItem('emailVerifyRequestDate', this.emailVerifyRequestDate);
        this.authService.sendVerificationMail().subscribe({
            next: (res: string) => {
                this.snackBar.open(this.translocoService.translate('Please Check Spam Folder'), '', {
                    duration: 3000,
                });
            },
            error: (error) => {
                this.snackBar.open(this.translocoService.translate('Please Contact Administrator'), '', {
                    duration: 3000,
                });
            }
        });
    }

    openChangeLocationDialog() {
        this.matDialog.open(this.selectLocationDialog, {
            hasBackdrop: true,
            backdropClass: ['bg-transparent'],
            panelClass: ['md:ml-[110px]', 'max-h-[calc(100vh-100px)]']
        });
    }

    setLocation(currentLocation: AllLocationResponse) {
        if (currentLocation.locationId != this.currentLocation.locationId) {
            this.locationService.currentLocationDetail = currentLocation;
            location.reload();
        }
        this.router.navigate(['dashboard']);
        this.matDialog.closeAll();
    }
}
