<div class="w-full" *transloco="let t">
    <div [class]="'flex items-center space-x-1 ' + extraClass">
        <div class="whitespace-nowrap">5 {{t('star')}}</div>
        <mat-progress-bar class="my-2 text-yellow-400"
                        [matTooltipPosition]="'above'"  [matTooltip]="(totalReviews ? (reviewsPerScore?.['5'] / totalReviews * 100) : 0 | number : '0.0-0') + '%'"
                          [value]="totalReviews ? ( reviewsPerScore?.['5']  / totalReviews * 100): 0"></mat-progress-bar>
        <div class="max-w-10 w-full text-right">{{ reviewsPerScore?.["5"]}}</div>
    </div>
    <div [class]="'flex items-center space-x-1 ' + extraClass">
        <div class="whitespace-nowrap">4 {{t('star')}}</div>
        <mat-progress-bar class="my-2 text-yellow-400"
                        [matTooltipPosition]="'above'"  [matTooltip]="(totalReviews ? (reviewsPerScore?.['4'] / totalReviews * 100) : 0 | number : '0.0-0') + '%'"
                          [value]="totalReviews ? ( reviewsPerScore?.['4']  / totalReviews * 100): 0"></mat-progress-bar>
        <div class="max-w-10 w-full text-right">{{ reviewsPerScore?.["4"]}}</div>
    </div>
    <div [class]="'flex items-center space-x-1 ' + extraClass">
        <div class="whitespace-nowrap">3 {{t('star')}}</div>
        <mat-progress-bar class="my-2 text-yellow-400"
                        [matTooltipPosition]="'above'"  [matTooltip]="(totalReviews ? (reviewsPerScore?.['3'] / totalReviews * 100) : 0 | number : '0.0-0') + '%'"
                          [value]="totalReviews ? ( reviewsPerScore?.['3']  / totalReviews * 100): 0"></mat-progress-bar>
        <div class="max-w-10 w-full text-right">{{ reviewsPerScore?.["3"]}}</div>
    </div>
    <div [class]="'flex items-center space-x-1 ' + extraClass">
        <div class="whitespace-nowrap">2 {{t('star')}}</div>
        <mat-progress-bar class="my-2 text-yellow-400"
                        [matTooltipPosition]="'above'"  [matTooltip]="(totalReviews ? (reviewsPerScore?.['2'] / totalReviews * 100) : 0 | number : '0.0-0') + '%'"
                          [value]="totalReviews ? ( reviewsPerScore?.['2']  / totalReviews * 100): 0"></mat-progress-bar>
        <div class="max-w-10 w-full text-right">{{ reviewsPerScore?.["2"]}}</div>
    </div>
    <div [class]="'flex items-center space-x-1 ' + extraClass">
        <div class="whitespace-nowrap">1 {{t('star')}}</div>
        <mat-progress-bar class="my-2 text-yellow-400"
                        [matTooltipPosition]="'above'"  [matTooltip]="(totalReviews ? (reviewsPerScore?.['1'] / totalReviews * 100) : 0 | number : '0.0-0') + '%'"
                          [value]="totalReviews ? ( reviewsPerScore?.['1']  / totalReviews * 100): 0"></mat-progress-bar>
        <div class="max-w-10 w-full text-right">{{ reviewsPerScore?.["1"]}}</div>
    </div>
</div>