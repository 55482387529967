<div class="p-2 rounded-2xl flex flex-col space-y-1 w-full"
     [style]="{'background-color': backgroundColor, 'font-family': font + ' !important'}"
     [ngClass]="{'justify-center items-center':question.isPrimary}">
    <div class="font-semibold text-xl" [ngClass]="{'text-center':question.isPrimary}">
        {{ question.isPrimary ? '' : (question.displayOrder > 0 ? question.displayOrder - 1 : question.displayOrder * -1) + '.' }} {{ question.questionText }}
    </div>
    <ng-container [ngSwitch]="question.questionType">
        <ng-container *ngSwitchCase="'TEXT'">
            <mat-form-field class="w-full">
                <input matInput>
            </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'RATING_1_10'">
            <div class="flex justify-evenly items-center space-x-2">
                <button *ngFor="let r of rating1_10" (click)="sendResponse(r-1)"
                        [ngClass]="{'bg-orange-500 text-white': isValueSelected && r<=(rating+1) && (redirectThreshold >=(rating+1) || !question.isPrimary),'bg-green-500 text-white': isValueSelected && r<=(rating+1) && redirectThreshold <(rating+1) && question.isPrimary}"
                        class="font-semibold border hover:bg-orange-500 px-1 w-5 h-5 min-w-5 text-sm md:text-md md:w-10 md:h-10 md:min-w-10">{{ r }}
                </button>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'CHECKBOX'">
            <div class="flex flex-col space-y-1">
                <mat-checkbox *ngFor="let option of question.options1" [color]="'primary'">
                    <span [style]="{'font-family': font + ' !important'}">{{ option.optionText }}</span>
                </mat-checkbox>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'RADIO'">
            <div class="flex flex-col space-y-1">
                <mat-radio-button *ngFor="let option of question.options1" [color]="'primary'">
                    <span [style]="{'font-family': font + ' !important'}">{{ option.optionText }}</span>
                </mat-radio-button>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'SMILEY_1_5'">
            <div class="flex items-center space-x-3">
                <mat-icon (click)="sendResponse(i)" *ngFor="let icon of iconList;let i=index;"
                          [ngClass]="{'text-orange-500':i<=rating && isValueSelected && (redirectThreshold >=rating || !question.isPrimary) , 'text-green-500': i<=rating &&  isValueSelected && redirectThreshold <=rating && question.isPrimary}"
                          class="icon-size-6 md:icon-size-8 hover:text-orange-500 hover:cursor-pointer"
                          [svgIcon]="icon"></mat-icon>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'STAR_1_5'">
            <div class="flex items-center space-x-3">
                <rating [rating]="isValueSelected ? rating+1 : question.isPrimary ? 0 : rating"
                        (sendResponseToQuestion)="sendResponse($event-1)"
                        [className]="'icon-size-5 md:icon-size-8 hover:cursor-pointer hover:text-orange-500'"></rating>
            </div>
        </ng-container>
    </ng-container>
</div>
<hr class="my-2 mx-0" *ngIf="!question.isPrimary">
