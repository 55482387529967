import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {MatButton} from '@angular/material/button';
import {NewFeatures} from "../../../shared/constants/new-features";
import {Router} from "@angular/router";
import {MyOrgSubscription} from "../../../modules/admin/dashboard/models/my-org-subscription";
import {HomeService} from "../../../modules/admin/dashboard/services/home.service";
import {Features} from "../../../shared/models/features";
import {User} from "../../../shared/models/user.types";
import {AuthService} from "../../../core/auth/auth.service";

@Component({
    selector: 'new-features',
    templateUrl: './new-features.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'new-features'
})
export class NewFeaturesComponent implements OnDestroy, OnInit {
    @ViewChild('newFeaturesOrigin') private newFeaturesOrigin: MatButton;
    @ViewChild('newFeaturesPanel') private newFeaturesPanel: TemplateRef<any>;
    @Input() setupProgressStatus = 0;
    newFeatures = NewFeatures;
    private _overlayRef: OverlayRef;
    orgSubscription: MyOrgSubscription;

    constructor(private _overlay: Overlay,
                private _viewContainerRef: ViewContainerRef,
                private router: Router,
                private homeService: HomeService,
                private authService: AuthService
    ) {
    }

    ngOnInit() {
        this.homeService.orgSubscriptionInfo.subscribe(subscription => this.orgSubscription = subscription);
        this.newFeatures = this.newFeatures.filter(feature => feature.roles.includes(this.authService.businessUser.roleType));
    }

    ngOnDestroy(): void {
        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the shortcuts panel
     */
    openPanel(): void {
        // Return if the shortcuts panel or its origin is not defined
        if (!this.newFeaturesPanel || !this.newFeaturesOrigin) {
            return;
        }
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this.newFeaturesPanel, this._viewContainerRef));
    }


    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this.newFeaturesOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    goToFeatures(feature: Features) {
        this.router.navigateByUrl(feature.link);
        this._overlayRef.detach();
    }
}
