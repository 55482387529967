import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EmailPreviewTemplateComponent} from './components/email-preview-template/email-preview-template.component';
import {TimeagoPipe} from './pipes/timeago.pipe';
import {ReviewPercentageComponent} from './components/review-percentage/review-percentage.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TranslocoModule} from "@ngneat/transloco";
import {DragDropDirective} from "./directives/drag-drop-directive";
import { ContactListComponent } from './components/contact-list/contact-list.component';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatInputModule} from "@angular/material/input";
import { UploadMediaFileComponent } from './components/upload-media-file/upload-media-file.component';
import {MatStepperModule} from "@angular/material/stepper";


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatProgressBarModule,
        MatTooltipModule,
        TranslocoModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatStepperModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TimeagoPipe,
        EmailPreviewTemplateComponent,
        ReviewPercentageComponent,
        DragDropDirective,
        DragDropDirective,
        UploadMediaFileComponent
    ],
    declarations: [
        EmailPreviewTemplateComponent,
        TimeagoPipe,
        ReviewPercentageComponent,
        DragDropDirective,
        ContactListComponent,
        UploadMediaFileComponent
    ]
})
export class SharedModule {
}
