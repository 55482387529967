<div class="flex flex-col flex-auto p-2 md:p-5 " *transloco="let t">
    <div class="flex flex-col md:flex-row items-center sm:justify-center md:justify-between py-3 w-full lg:w-3/4 mx-auto">
        <h1 class="text-3xl font-extrabold tracking-tight leading-none">
            {{ t('Modify Location Detail') }}</h1>
        <button class="fuse-mat-button-rounded mt-2 md:mt-0" mat-flat-button [color]="'primary'"
                [disabled]="!isAPICallComplete" *ngIf="locationForm.dirty || locationForm.controls['links'].dirty"
                (click)="editLocation()">
            {{ t('Save') }}
        </button>
    </div>
    <div class="relative w-full lg:w-11/12 mx-auto">
        <form [formGroup]="locationForm">
            <mat-tab-group [animationDuration]="'0'" class="self-center lg:self-start w-full">
                <mat-tab [label]="t('Location Detail')">
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-1 gap-x-3 bg-card shadow p-5 rounded-2xl">
                        <mat-icon [svgIcon]="'feather:trash-2'" (click)="deleteLocation()"
                                  [matTooltip]="t('Delete Location')" [matTooltipPosition]="'above'"
                                  matTooltipShowDelay="100" matTooltipHideDelay="100"
                                  class="icon-size-5 opacity-30 absolute top-8 right-8 md:right-6 lg:right-10 text-red-400 hover:opacity-100 hover:cursor-pointer"></mat-icon>
                        <div class="flex flex-col items-center">
                            <div class="flex flex-col md:flex-row items-start md:items-center w-full md:max-w-md space-x-0 md:space-x-3">
                                <span class="font-medium min-w-24 mb-0 md:mb-3">{{ t('Name') }}</span>
                                <mat-form-field class="w-full">
                                    <input type="text" [placeholder]="t('Name')" matInput [formControlName]="'name'"
                                           class="text-gray-400" readonly>
                                </mat-form-field>
                            </div>
                            <div class="flex flex-col md:flex-row items-start md:items-center w-full md:max-w-md space-x-0 md:space-x-3">
                                <span class="font-medium min-w-24 mb-0 md:mb-3">{{ t('Address') }}</span>
                                <mat-form-field class="w-full">
                                    <input type="text" [placeholder]="t('Address')" matInput
                                           [formControlName]="'address'">
                                    <mat-error *ngIf="locationForm.get('address').hasError('required')">
                                        {{ t('Name Required') }}.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="flex flex-col md:flex-row items-start md:items-center w-full md:max-w-md space-x-0 md:space-x-3">
                                <span class="font-medium min-w-24 mb-0 md:mb-3">{{ t('Phone') }}</span>
                                <mat-form-field class="w-full">
                                    <input type="text" [placeholder]="t('Phone')" matInput
                                           [formControlName]="'phoneNo'">
                                    <mat-error *ngIf="locationForm.get('phoneNo').hasError('invalidCountryPhone')">
                                        {{ t('Enter Valid Phone Number') }}.
                                    </mat-error>
                                    <mat-error *ngIf="locationForm.get('phoneNo').hasError('required')">
                                        {{ t('Phone Number is required.') }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="flex flex-col md:flex-row items-start md:items-center w-full md:max-w-md space-x-0 md:space-x-3">
                                <span class="font-medium min-w-24 mb-0 md:mb-3">{{ t('Website') }}</span>
                                <mat-form-field class="w-full">
                                    <input type="url" [placeholder]="t('Website')" matInput
                                           [formControlName]="'website'">
                                    <mat-error> {{ t('Website URL is invalid.') }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="flex flex-col md:flex-row items-start md:items-center w-full md:max-w-md space-x-0 md:space-x-3">
                                <span class="font-medium min-w-24 mb-0 md:mb-3">{{ t('Country') }}</span>
                                <mat-form-field class="w-full">
                                    <input type="text" [placeholder]="t('Country')" matInput
                                           class="text-gray-400" [formControlName]="'country'" readonly>
                                </mat-form-field>
                            </div>
                            <div class="flex flex-col md:flex-row items-center w-full md:max-w-md space-x-0 md:space-x-3 space-y-2 md:space-y-0">
                                <div class="flex justify-center md:justify-start min-w-30">
                                    <img class="h-16 w-16 object-cover rounded-full"
                                         [src]="locationForm.controls['logoImageUrl'].value" [alt]="t('Logo')"/>
                                </div>
                                <label class="block">
                                    <input type="file" accept="image/*" (change)="onFileSelect($event)" #logoFileInput
                                           class="hidden"/>
                                    <button mat-stroked-button type="button" (click)="logoFileInput.click()">
                                        {{ logoUrl ? t('Change') : t('Choose') }} {{ t('Logo') }}
                                    </button>
                                </label>
                            </div>
                        </div>
                        <div class="flex flex-col items-center">
                            <div class="flex flex-col md:flex-row items-start md:items-center w-full md:max-w-md space-x-0 md:space-x-3">
                                <span class="font-medium min-w-30 mb-0 md:mb-3">{{ t('From Name') }} *</span>
                                <mat-form-field class="w-full">
                                    <input type="text" readonly [placeholder]="t('From Name')" matInput
                                           class="text-gray-400" [formControlName]="'fromName'">
                                </mat-form-field>
                            </div>
                            <div class="flex flex-col md:flex-row items-start md:items-center w-full md:max-w-md space-x-0 md:space-x-3">
                                <span class="font-medium min-w-30 mb-0 md:mb-3">{{ t('From Email') }} *</span>
                                <mat-form-field class="w-full">
                                    <input type="text" readonly [placeholder]="t('From Email')" matInput
                                           class="text-gray-400" [formControlName]="'fromEmail'">
                                </mat-form-field>
                            </div>
                            <div class="flex flex-col md:flex-row items-start md:items-center w-full md:max-w-md space-x-0 md:space-x-3">
                                <span class="font-medium min-w-30 mb-0 md:mb-3">{{ t('ReplyTo Email') }} * </span>
                                <mat-form-field class="w-full">
                                    <input type="text" readonly [placeholder]="t('ReplyTo Email')" matInput
                                           class="text-gray-400" [formControlName]="'replyTo'">
                                </mat-form-field>
                            </div>
                            <div class="flex flex-col md:flex-row items-start md:items-center w-full md:max-w-md space-x-0 md:space-x-3">
                                <span class="font-medium min-w-30 mb-0 md:mb-3">{{ t('Feedback Email') }} *</span>
                                <mat-form-field class="w-full">
                                    <input type="text" readonly [placeholder]="t('Feedback Email')" matInput
                                           class="text-gray-400" [formControlName]="'feedbackEmail'">
                                </mat-form-field>
                            </div>
                            <div class="text-slate-400" *ngIf="user && user.roleType != 'SUPER_ADMIN'">
                                * {{ t('Only the Super Admin can modify these values.') }}
                            </div>
                            <a *ngIf="user && user.roleType == 'SUPER_ADMIN'"
                               [routerLink]="'/agency-mode/email-provider'" class="hover:underline text-blue-600">
                                * {{ t('Modify these values in the Email Provider Screen here.') }}
                            </a>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab [label]="t('Review Sites')">
                    <div class="flex flex-col md:flex-row bg-card shadow p-5 rounded-2xl space-x-0 md:space-x-3 space-y-0 md:space-y-2"
                         *ngIf="siteSearched">
                        <div class="w-full md:w-2/3 lg:w-3/4">
                            <div class="text-xl font-bold mb-1">{{ t('Choose Review Site') }}</div>
                            <div class="p-3 border rounded-2xl">
                                <div class="grid flex-col divide-y items-center h-auto md:h-72 overflow-auto p-1 gap-2"
                                     [ngClass]="innerWidth>1920 ? 'grid-cols-5': innerWidth>1430 && innerWidth<=1920 ? 'grid-cols-4' : innerWidth>600 && innerWidth<=1430 ? 'grid-cols-3':  innerWidth<=600 && innerWidth>=500 ?'grid-cols-2':'grid-cols-1'"
                                     formArrayName="links">
                                    <fuse-card (click)="openDialog()" [matTooltip]="t('Add Custom Review Site')"
                                               [matTooltipPosition]="'above'"
                                               class="flex items-center border p-2 filter-info h-12 min-h-12 cursor-pointer">
                                         <span class="mr-4 w-5 h-5 min-w-5 min-h-5 md:w-6 md:h-6 md:min-w-6 md:min-h-6 bg-blue-700 text-xl font-bold text-white flex justify-center items-center rounded-full"
                                         >C</span>
                                        <div class="font-semibold leading-tight"> {{ t('Custom') }}</div>
                                    </fuse-card>
                                    <ng-container *ngFor="let site of siteLinks.controls;let i=index;"
                                                  [formGroupName]="i">
                                        <fuse-card (click)="openDialog(site.value,i)" [matTooltip]="site.value.name"
                                                   [matTooltipPosition]="'above'" [id]="'site_{{i}}'"
                                                   *ngIf="!site.value.reviewLink"
                                                   class="flex items-center border p-2 filter-info w-full h-12 min-h-12 cursor-pointer">
                                            <img class="w-5 h-5 min-w-5 min-h-5 md:w-6 md:h-6 md:min-w-6 md:min-h-6 mr-4 rounded-full"
                                                 [src]="site.value.logoURL" [alt]="t('Logo')"
                                                 *ngIf="!site.value.isCustom">
                                            <div class="font-semibold leading-tight"> {{ site.value.name }}</div>
                                        </fuse-card>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="w-full md:w-1/3 lg:w-1/4">
                            <div class="text-xl font-bold mb-1">{{ t('My Review Site') }}</div>
                            <div class="p-3 border rounded-2xl">
                                <div class="flex flex-col space-y-2 divide-y items-center h-auto md:h-72 overflow-auto pb-1"
                                     cdkDropList (cdkDropListDropped)="drop($event)" formArrayName="links">
                                    <ng-container *ngFor="let site of siteLinks.controls;let i=index;"
                                                  [formGroupName]="i">
                                        <fuse-card
                                                class="items-center border p-2 filter-info  w-full flex h-12 min-h-12"
                                                [id]="'site_{{i}}'" cdkDrag *ngIf="site.value.reviewLink">
                                            <mat-icon
                                                    [ngClass]="site.value.reviewLink ? 'text-green-600' : 'text-red-600'"
                                                    [matTooltip]="t('Change Priority')"
                                                    [svgIcon]="'heroicons_solid:menu'"
                                                    class="icon-size-5 text-hint text-xl font-bold cursor-move m-1"
                                                    cdkDragHandle></mat-icon>
                                            <span class="mr-0 md:mr-2 w-5 h-5 min-w-5 min-h-5 md:w-6 md:h-6 md:min-w-6 md:min-h-6 bg-blue-700 uppercase text-xl font-bold text-white flex justify-center items-center rounded-full"
                                                  *ngIf="site.value.isCustom">{{ site.value.name[0] }}</span>
                                            <img class="w-5 h-5 min-w-5 min-h-5 md:w-6 md:h-6 md:min-w-6 md:min-h-6 mr-0 md:mr-2 rounded-full"
                                                 [src]="site.value.logoURL" [alt]="t('Logo')"
                                                 (click)="openDialog(site.value,i)" *ngIf="!site.value.isCustom">
                                            <div class="flex justify-between w-full items-center">
                                                <div class="font-semibold leading-tight mr-2 md:mr-0"
                                                     [matTooltip]="site.value.reviewLink?'':'Not Configured'"
                                                     [matTooltipPosition]="'above'"
                                                     (click)="openDialog(site.value,i)">{{ site.value.name }}
                                                </div>
                                                <div class="flex items-center space-x-1 mb-2">
                                                    <div class="font-bold w-4 md:w-5 h-4 md:h-5 bg-gray-400 text-white flex justify-center items-center rounded-full p-1 mt-2 md:mt-1"
                                                         matTooltip="Priority {{ site.value.priority }}"
                                                         [matTooltipPosition]="'above'">{{ site.value.priority }}
                                                    </div>
                                                    <button [matTooltip]="t('Edit Review Sites')"
                                                            [matTooltipPosition]="'above'" type="button"
                                                            class="font-bold icon-size-4 md:icon-size-5"
                                                            (click)="openDialog(site.value,i)"
                                                            *ngIf="!isMobileDevice">
                                                        <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                                                    </button>
                                                    <button matTooltip="{{t('Delete Review Site')}}"
                                                            [matTooltipPosition]="'above'" type="button"
                                                            class="font-bold icon-size-4 md:icon-size-5"
                                                            [disabled]="site.value.name == 'Google' || !site.value.reviewLink"
                                                            (click)="deleteSite(site.value,i)">
                                                        <mat-icon class="text-red-400"
                                                                  svgIcon="heroicons_outline:trash"></mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </fuse-card>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </form>
    </div>
</div>

<ng-template #customLinkDialog *transloco="let t">
    <div class="flex flex-col  max-h-screen -m-6">
        <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
            <div class="text-lg font-medium">{{ siteIndex == -1 ? t('Add Custom Review Site') : 'Edit ' + reviewSiteForm.controls['name'].value + ' Review Site' }}</div>
            <button mat-icon-button (click)="closeDialog()" [tabIndex]="-1">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>
        <form class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto w-full" [formGroup]="reviewSiteForm">
            <mat-form-field class="w-full">
                <mat-label>{{ t('Review Site Name') }}</mat-label>
                <input matInput [formControlName]="'name'" [placeholder]="t('Review Site Name')"
                       [readonly]="!canEditSiteName" [autofocus]="canEditSiteName">
                <mat-error>{{ t('Review site name is required.') }}</mat-error>
            </mat-form-field>
            <div class="w-full relative mb-1">
                <span>{{ t('Review Site Link') }}*</span>
                <a *ngIf="reviewSiteForm.controls['reviewLink'].valid" class="text-blue-600 absolute right-1 font-bold"
                   target="_blank" [href]="reviewSiteForm.controls['reviewLink'].value">{{ t('Test Link') }}</a>
            </div>
            <mat-form-field class="w-full">
                <input matInput [formControlName]="'reviewLink'" [placeholder]="t('Review Site Link')">
                <mat-error *ngIf="reviewSiteForm.controls['reviewLink'].hasError('required')">
                    {{ t('Review site link is required.') }}
                </mat-error>
                <mat-error *ngIf="reviewSiteForm.controls['reviewLink'].hasError('pattern')">
                    {{ t('Invalid Url') }}
                </mat-error>
            </mat-form-field>
            <div class="flex justify-between">
                <button mat-stroked-button type="button" (click)="closeDialog()">{{ t('Close') }}</button>
                <button mat-flat-button [color]="'primary'" (click)="addCustomSite()"
                        [disabled]="reviewSiteForm.invalid"> {{ t('Add') }}
                </button>
            </div>
        </form>
    </div>
</ng-template>
