import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, empty, Observable, switchMap, throwError} from 'rxjs';
import {AuthService} from 'app/core/auth/auth.service';
import {environment} from "../../../environments/environment";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {
    }

    intercept(req: HttpRequest<any>,
              next: HttpHandler): Observable<any> {

        req = this.setHeaders(req);
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                // Catch "401 Unauthorized" responses
                if (error instanceof HttpErrorResponse && error.status === 401 && this.authService.businessUser && req.url.includes(environment.BASE_URL)) {
                    return this.authService.getNewAccessToken().pipe(
                        switchMap((res) => {
                            this.authService.idToken = res.idToken;
                            return next.handle(this.setHeaders(req));
                        }),
                        catchError(() => {
                            // Sign out
                            if (!this.authService.alerted) {
                                this.authService.changeAlertStatus(true);
                                alert('You have been logged out.');
                            }
                            localStorage.clear();
                            console.clear();
                            // Set the authenticated flag to false
                            this.authService.authenticated = false;
                            sessionStorage.setItem('alerted ', 'yes');
                            // // Reload the app
                            location.reload();
                            return empty();
                        })
                    );
                }

                return throwError(error);
            })
        );
    }

    setHeaders(request: HttpRequest<any>) {
        request = request.clone({
            setHeaders: {
                'Content-Type': 'application/json',
                ...((this.authService.accessToken && request.url.includes(environment.BASE_URL)) && {
                    'x-org-id': this.authService.businessUser ? this.authService.businessUser.orgId : '',
                    'x-user-id': this.authService.businessUser ? this.authService.businessUser.userId : '',
                    'id-token': this.authService.idToken || this.authService.accessToken
                })
            }

        });
        if (request.body instanceof FormData) {
            request = request.clone({
                headers: request.headers.delete('Content-Type')
            });
        }
        return request;
    }
}
