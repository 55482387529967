import {Injectable} from '@angular/core';
import {Title} from "@angular/platform-browser";

@Injectable({
    providedIn: 'root'
})
export class FaviconService {
    constructor(private titleService:Title) {
    }
    setFavicon(faviconUrl: string, companyName:string='Freshreview - Reputation Management Software, Online Review Management Software') {
        const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
        if (favicon) {
            favicon.href = faviconUrl;
        } else {
            const link = document.createElement('link');
            link.rel = 'icon';
            link.href = faviconUrl;
            document.head.appendChild(link);
        }
        this.titleService.setTitle(companyName);
    }
}
