/* tslint:disable:max-line-length */
import {FuseNavigationItem} from '@fuse/components/navigation';
import {Roles} from "../../constants/roles";

export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'mat_outline:dashboard',
        link: '/dashboard',
        classes: {
            icon: 'icon-size-4'
        }
    },
    {
        id: 'reviews',
        title: 'Collect Reviews',
        type: 'aside',
        icon: 'mat_outline:send',
        classes: {
            icon: 'icon-size-4'
        },
        children: [
            {
                id: 'review-invites',
                title: 'Invites',
                subtitle: 'Send review requests',
                icon: 'mat_outline:reviews',
                type: 'basic',
                link: '/collect-review/invite-customer'
            },
            {
                id: 'navigation-features.level.0.1-2',
                title: 'Campaign',
                subtitle: 'Automate multi-step review campaigns',
                icon: 'heroicons_outline:lightning-bolt',
                type: 'basic',
                link: '/collect-review/sequence'
            },
            {
                id: 'navigation-features.level.0.1-2',
                title: 'Customer',
                subtitle: 'Manage your customers',
                icon: 'heroicons_outline:user',
                type: 'basic',
                link: '/collect-review/customer'
            },
            {
                id: 'navigation-features.level.0.1-2',
                title: 'Configurations',
                subtitle: 'Customise your review requests',
                icon: 'feather:aperture',
                type: 'basic',
                link: '/collect-review/configurations'
            },
            {
                id: 'navigation-features.level.0.1-2',
                title: 'QR Code',
                subtitle: 'Generate QR Code',
                icon: 'heroicons_outline:qrcode',
                type: 'basic',
                link: '/collect-review/qr-code'
            },
            {
                id: 'navigation-features.level.0.1-2',
                title: 'NPS, CSAT and Survey',
                subtitle: 'Collect reviews, surveys, and feedback',
                icon: 'mat_outline:contact_page',
                type: 'basic',
                link: '/collect-review/funnel-page',
                roleType: [Roles.SUPER_ADMIN]
            }
        ]
    },
    {
        id: 'analytics',
        title: 'Analytics',
        type: 'aside',
        icon: 'heroicons_outline:chart-bar',
        classes: {
            icon: 'icon-size-4'
        },
        children: [
            {
                id: 'review-analytics',
                title: 'Review',
                subtitle: 'Review Analytics',
                icon: 'mat_outline:stars',
                type: 'basic',
                link: '/analytics/review'
            },
            {
                id: 'campaign-analytics',
                title: 'Campaign',
                subtitle: 'Campaign Analytics',
                icon: 'mat_outline:campaign',
                type: 'basic',
                link: '/analytics/sequence'
            },
            {
                id: 'invite-review-ratio',
                title: 'Invite Review Ratio',
                subtitle: 'Invite Review Ratio Analytics',
                icon: 'mat_solid:insert_chart_outlined',
                type: 'basic',
                link: '/analytics/invite-review-ratio'
            },
            {
                id: 'channel-based',
                title: 'Channel Based Analytics',
                subtitle: 'Channel Based Analytics',
                icon: 'mat_solid:pie_chart',
                type: 'basic',
                link: '/analytics/channel-based'
            },
            {
                id: 'fresh-feedback',
                title: 'NPS, CSAT and Survey Analytics',
                subtitle: 'NPS, CSAT and Survey Analytics',
                icon: 'mat_solid:bar_chart',
                type: 'basic',
                link: '/analytics/fresh-feedback'
            }
        ]
    },
    {
        id: 'monitor',
        title: 'Monitor Reviews',
        type: 'aside',
        icon: 'heroicons_outline:annotation',
        classes: {
            icon: 'icon-size-4'
        },
        orgKey: 'monitorReviews',
        children: [
            {
                id: 'navigation-features.level.0.1-1',
                title: 'Google Reviews',
                subtitle: 'View & respond reviews',
                icon: 'heroicons_outline:star',
                type: 'basic',
                link: '/monitor-reviews/my-reviews'
            }
        ]
    },
    {
        id: 'display',
        title: 'Review Marketing',
        type: 'aside',
        icon: 'mat_outline:star_outline',
        classes: {
            icon: 'icon-size-4'
        },
        children: [
            {
                id: 'navigation-features.level.0.1-1',
                title: 'Google Widgets and Badges',
                subtitle: 'Embed & showcase reviews',
                icon: 'mat_outline:widgets',
                type: 'basic',
                link: '/review-marketing/review-widget',
                hideOnMobile: true
            },
            {
                id: 'navigation-features.level.0.1-3',
                title: 'Microsite',
                subtitle: 'Micro-site showcasing reviews',
                icon: 'mat_outline:phonelink',
                type: 'basic',
                link: '/review-marketing/micro-site'
            },
            {
                id: 'navigation-features.level.0.1-2',
                title: 'Configurations',
                subtitle: 'Engage Audiences with Posts & Videos',
                icon: 'feather:aperture',
                type: 'basic',
                link: '/review-marketing/configurations'
            }
        ]
    },
    {
        id: 'integration',
        title: 'Integrations',
        type: 'aside',
        icon: 'heroicons_outline:puzzle',
        classes: {
            icon: 'icon-size-4'
        },
        roleType: [Roles.SUPER_ADMIN],
        children: [
            {
                id: 'native',
                title: 'Native',
                subtitle: 'Native Integrations',
                icon: 'heroicons_outline:link',
                type: 'basic',
                link: '/integrations/native'
            },
            {
                id: 'zapier',
                title: 'Zapier',
                subtitle: 'Zapier Integrations',
                icon: 'heroicons_outline:link',
                type: 'basic',
                link: '/integrations/zapier'
            }

        ]
    },
    {
        id: 'settings',
        title: 'Advanced Settings',
        type: 'aside',
        icon: 'heroicons_solid:cog',
        classes: {
            icon: 'icon-size-4'
        },
        roleType: [Roles.SUPER_ADMIN, Roles.ADMIN],
        children: [
            {
                id: 'manage-location',
                title: 'Manage Locations',
                subtitle: 'Manage your locations here',
                icon: 'heroicons_solid:location-marker',
                type: 'basic',
                link: '/agency-mode/manage-location',
                roleType: [Roles.SUPER_ADMIN],
            }, {
                id: 'manage-user',
                title: 'Manage Users',
                subtitle: 'Manage Users',
                icon: 'heroicons_solid:users',
                type: 'basic',
                link: '/agency-mode/user-list',
                roleType: [Roles.SUPER_ADMIN, Roles.ADMIN],
            }, {
                id: 'sms-provider',
                title: 'Custom SMS Provider',
                subtitle: 'Setup SMS Provider',
                icon: 'mat_outline:sms',
                type: 'basic',
                link: '/agency-mode/sms-provider',
                roleType: [Roles.SUPER_ADMIN],
                orgKey: 'smsProviderEnabled'
            }, {
                id: 'custom-provider',
                title: 'Custom EMAIL Provider',
                subtitle: 'Setup EMAIL Provider',
                icon: 'mat_outline:mail',
                type: 'basic',
                link: '/agency-mode/email-provider',
                roleType: [Roles.SUPER_ADMIN]
            }, {
                id: 'openai-provider',
                title: 'Custom Open AI Provider',
                subtitle: 'Setup Open AI Provider',
                icon: 'heroicons_outline:sparkles',
                type: 'basic',
                link: '/agency-mode/open_ai-provider',
                roleType: [Roles.SUPER_ADMIN],
                orgKey: 'openAiProviderEnabled'
            },
            {
                id: 'google-review-provider',
                title: 'Custom Google Review Provider',
                subtitle: 'Setup Google Review Provider',
                icon: 'mat_solid:reviews',
                type: 'basic',
                link: '/agency-mode/google-review-provider',
                roleType: [Roles.SUPER_ADMIN],
            },
            {
                id: 'email-setup',
                title: 'Custom Domain',
                subtitle: 'Configure your custom domain',
                icon: 'mat_outline:language',
                type: 'basic',
                link: '/custom-domain/domain-list',
                orgKey: 'customDomainEnabled',
                roleType: [Roles.SUPER_ADMIN],
            },
            {
                id: 'white-label',
                title: 'White Label',
                subtitle: 'White Label',
                icon: 'mat_solid:bookmarks',
                type: 'basic',
                link: '/agency-mode/white-label',
                roleType: [Roles.SUPER_ADMIN],
                orgKey: 'whitelabelEnabled'
            },
            {
                id: 'subscription',
                title: 'Subscription',
                subtitle: 'Manage Subscription',
                icon: 'mat_outline:inventory',
                type: 'basic',
                link: '/agency-mode/pricing',
                roleType: [Roles.SUPER_ADMIN],
                hideOnMobile: true
            }
        ]
    }
];
